import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class CustomPeriodModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            from : '',
            till : ''
        }
    }
    
    setFrom = (e) => {
        this.setState({
            from : e.target.value
        })
    }

    setTill = (e) => {
        this.setState({
            till : e.target.value
        })
    }

    closeModal = () => {
        document.getElementById(this.props.modalID).classList.add('hidden');
    }

    callFunctionAndClose = () => {
        this.props.callbackFunction(this.state.from, this.state.till);
        this.closeModal();
    }
    
    render() {
        const { modalID, title, t } = this.props;

        return (
            <div id={modalID} className="modal fixed z-40 w-screen h-screen top-0 left-0 flex items-center justify-center hidden">
                <div className="modalContent modal-md bg-white rounded-md p-5 flex flex-col h-auto">
                    <div className="modal-header relative font-extralight text-lg flex justify-between px-3 mt-1">
                        <div className="title text-gray-600 text-xl">{title}</div>
                        <div className="button cursor-pointer" onClick={this.closeModal}><FontAwesomeIcon icon={["fal", "times"]} /></div>
                    </div>
                    <div className="modal-body flex flex-col justify-center px-3 mt-2">
                        <div className="flex flex-col">
                            <label htmlFor="from">{t("common.modals.customPeriod.from")}</label>
                            <input className="border-blue-300 text-blue-400" type="date" name="" id="from" onChange={this.setFrom}/>
                        </div>
                        <div className="mt-5 flex flex-col">
                            <label htmlFor="till">{t("common.modals.customPeriod.till")}</label>
                            <input className="border-blue-300 text-blue-400" type="date" name="" id="till" onChange={this.setTill}/>
                        </div>
                    </div>
                    <button className="buttonPrimary mx-auto" onClick={this.callFunctionAndClose}>Submit</button>
                </div>
            </div>
        );
    }
}

export default withTranslation()(CustomPeriodModal);