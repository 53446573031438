import { isEqual } from 'lodash';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Select from 'react-select';
import { cloneDeep } from 'lodash';
import ButtonSwitcher from '../../buttons/ButtonSwitcher';
import ModalLayoutWithDelete from '../common/ModalLayoutWithDelete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class CameraEditModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            camera: {},
            hasFieldsEmpty: false
        }
    }

    componentDidMount = () => {
        this.setState({
            camera: this.props.camera
        })
    }

    componentDidUpdate = (prevProps) => {
        if (!isEqual(prevProps.camera, this.props.camera) && !isEqual(this.props.camera, {})) {
            this.setState({
                camera: cloneDeep(this.props.camera)
            })
        }
    }
    /**
     * Change the value of "fieldName" property in camera object
     * @param {Event} field
     * @param {String} fieldName
     */
    handleSetCameraConfigField = (field, fieldName = '') => {
        let { camera } = this.state;
        fieldName = fieldName !== '' ? fieldName.split('_')[1] : field.target.name.split('_')[1];
        camera[fieldName] = field.value ? field.value : field.target.value;
        this.setState({
            camera,
        });
    }
    /**
     * Changes the focus of in a camera object
     */
    handleCameraConfigFocus = () => {
        let { camera } = this.state;
        if (camera.focus === 'screen') {
            camera.focus = 'audience';
        } else {
            camera.focus = 'screen';
        }
        this.setState({
            camera
        })
    }
    /**
     * Changes "sharable" property in camera object
     */
    handleSharability = () => {
        let { camera } = this.state;
        camera.sharable = !camera.sharable;
        this.setState({
            camera
        })
    }

    /**
     * Parse screens to fit in select object format
     * @returns {Array} parsed screens
     */
    getScreenOptions = () => {
        let { screens } = this.props;
        let screenOptions = screens.map(screen => { return { value: screen._id, label: screen.name } });
        return screenOptions;
    };

    /**
     * Returns a span that says the field is empty
     * @returns a span that contains a font awesome icon, and inside has the string completeFields.
     */
    showSpanEmptyFields = () => {
        return (
            <span className=' text-red-500'>
                <FontAwesomeIcon icon={["fas", "info-circle"]} className="text-red-500 text-sm mr-1" />
                {this.props.t('common.notification.completeFields')}
            </span>
        )
    }
    /**
     * Check that the fields are not empty before submitting the form
     */
    returnValueIfFieldsAreCompleted = () => {
        let { description, stream, screen } = this.state.camera;
        if (description === "" || stream === "" || screen === undefined) {
            this.setState({ hasFieldsEmpty: true })
        } else if (this.state.hasFieldsEmpty === true) {
            this.setState({ hasFieldsEmpty: false }, () => this.props.returnValue(this.state.camera, 1))
        } else {
            this.props.returnValue(this.state.camera, 1);
        }
    }


    render() {
        const { title, modalID, action } = this.props;
        let { camera } = this.state;
        const modalBody = () => {
            return (
                <div className={`p-2 relative bottom-0 right-0 left-0`}>
                    <div className='flex my-2 space-x-2'>
                        <div className='flex w-8/12 space-x-2'>
                            <div className={`w-1/2 flex flex-col`}>
                                <label className='text-gray-700 font-semibold text-sm'>{this.props.t('sections.management.section.cameras.modal.description')}</label>
                                <input name="camera_description" type="text" onChange={(e) => this.handleSetCameraConfigField(e)} className="block text-gray-700 w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:focus:ring" placeholder={this.props.t('sections.management.section.cameras.modal.description')} value={camera.description || ''} />
                                {(this.state.camera.description === "" && this.state.hasFieldsEmpty) && this.showSpanEmptyFields()}
                            </div>

                            <div className={`w-1/2 flex flex-col`}>
                                <label className='text-gray-700 font-semibold text-sm'>{this.props.t('sections.management.section.cameras.modal.screen')}</label>
                                <Select
                                    className="block text-gray-700 w-full bg-white hover:border-gray-500 rounded leading-tight focus:outline-none focus:focus:ring"
                                    name={'camera_screen'}
                                    onChange={(e) => this.handleSetCameraConfigField(e, 'camera_screen')}
                                    options={this.getScreenOptions()}
                                    value={this.getScreenOptions().find(screen => screen.value === camera.screen) || []}
                                />
                                {(this.state.camera.screen === undefined && this.state.hasFieldsEmpty) && this.showSpanEmptyFields()}

                            </div>

                        </div>
                        <div className='block w-4/12 flex flex-col items-start'>
                            <label className='text-gray-700 font-semibold text-sm'>{this.props.t('sections.management.section.cameras.modal.focus')}</label>
                            <ButtonSwitcher labelOff={this.props.t('sections.management.section.cameras.modal.screenFocus')} labelOn={this.props.t('sections.management.section.cameras.modal.audienceFocus')} handleVisibility={() => this.handleCameraConfigFocus()} revertLabel={true} status={camera.focus !== 'screen'} />
                        </div>
                    </div>
                    <div className='flex my-2 space-x-2'>
                        <div className='block w-8/12 flex flex-col'>
                            <label className='text-gray-700 font-semibold text-sm'>{this.props.t('sections.management.section.cameras.modal.url')}</label>
                            <input name="camera_stream" type="text" onChange={(e) => this.handleSetCameraConfigField(e)} className="block text-gray-700 w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:focus:ring" placeholder="Your Camera URL" value={camera.stream || ''} />
                            {(this.state.camera.stream === "" && this.state.hasFieldsEmpty) && this.showSpanEmptyFields()}
                        </div>
                        <div className='block w-4/12 flex flex-col items-start'>
                            <label className='text-gray-700 font-semibold text-sm'>{this.props.t('sections.management.section.cameras.share.title')}{this.props.itemNeedTooltip(<FontAwesomeIcon icon={["fas", "info-circle"]} className="text-black-500 text-sm" />, this.props.t('sections.management.section.cameras.share.text'), 1)}</label>
                            <ButtonSwitcher revertLabel labelOn={this.props.t('sections.management.section.cameras.share.labelOn')} labelOff={this.props.t('sections.management.section.cameras.share.labelOff')} status={camera.sharable} handleVisibility={() => this.handleSharability()} />
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <>
                <ModalLayoutWithDelete title={title} action={action} modalID={modalID} returnValue={() => this.returnValueIfFieldsAreCompleted()} deleteValue={() => this.props.returnValue(camera, 2)} closeModal={() => {
                    this.setState({hasFieldsEmpty: false});
                    this.props.closeModal(this.props.modalID);
                }}>
                    {modalBody()}
                </ModalLayoutWithDelete>
            </>
        )
    }
}
export default withTranslation()(CameraEditModal);
