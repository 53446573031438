import axios from 'axios';
import * as dotenv from 'dotenv';
dotenv.config();
const instance_axios = axios.create({ baseURL: window.Config.REACT_APP_BACKEND_URL });
let headers = new Headers();
headers.append('Content-Type', 'application/json');
headers.append('Accept', 'application/json');

export const getCardsByColumn = async (columnID) => {
    try {
        const r = await instance_axios.get('/OOH_Status/cards/' + columnID, {
            withCredentials: true,
           headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user')//the token is a variable which holds the token
            }
        });
        return r;
    } catch (err) {
        return err.request;
    }
}

export const getAllColumns = async () => {
    try {
        const r = await instance_axios.get('/OOH_Status/column', {
            withCredentials: true,
           headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user')//the token is a variable which holds the token
            }
        });
        return r;
    } catch (err) {
        return err.request;
    }
}
export const getColumn = async (columnID) => {
    try {
        const r = await instance_axios.get('/OOH_Status/column/' + columnID, {
            withCredentials: true,
           headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user')//the token is a variable which holds the token
            }
        });
        return r;
    } catch (err) {
        return err.request;
    }
}


export const addColumns = async (column) => {
    try {
        const r = await instance_axios.post('/OOH_Status/column', column, {
            withCredentials: true,
           headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user')//the token is a variable which holds the token
            }
        });
        return r;
    } catch (err) {
        return err.request;
    }
}
export const editColumns = async (data) => {
    try {
        const r = await instance_axios.put('/OOH_Status/column', data, {
            withCredentials: true,
           headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user')//the token is a variable which holds the token
            }
        });
        return r;
    } catch (err) {
        return err.request;
    }
}
export const editColumn = async (column) => {
    try {
        const r = await instance_axios.put('/OOH_Status/editColumn', column, {
            withCredentials: true,
           headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user')//the token is a variable which holds the token
            }
        });
        return r;
    } catch (err) {
        return err.request;
    }
}



export const deleteOOH_Status = async (id) => {
    try {
        const r = await instance_axios.delete('/generic/' + id, {
            withCredentials: true,
           headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user')//the token is a variable which holds the token
            }
        });
        return r;
    } catch (err) {
        return err.request;
    }
}

export const addCard = async (data) => {
    try {
        const r = await instance_axios.post('/OOH_Status/card', data, {
            withCredentials: true,
           headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user')//the token is a variable which holds the token
            }
        });
        return r;
    } catch (err) {
        return err.request;
    }
}

export const getCard = async (id) => {
    try {
        const r = await instance_axios.get('/OOH_Status/card/' + id, {
            withCredentials: true,
           headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user')//the token is a variable which holds the token
            }
        });
        return r;
    } catch (err) {
        return err.request;
    }
}

export const editCard = async (card) => {
    try {
        const r = await instance_axios.put('/OOH_Status/card', card, {
            withCredentials: true,
           headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user')//the token is a variable which holds the token
            }
        });
        return r;
    } catch (err) {
        return err.request;
    }
}

export const updateManyColumns = async (docs) => {
    try {
        const r = await instance_axios.put('/OOH_Status/column/updatemany', { docs }, {
            withCredentials: true,
           headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user')//the token is a variable which holds the token
            }
        });
        return r;
    } catch (err) {
        return err;
    }
}
export const getCards = async (page, limit, laneId, string) => {

    try {
        const cards = await instance_axios.get("/OOH_Status/bypage", {
            params: {
                page, limit, laneId, string
            },
            withCredentials: true,
           headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user')//the token is a variable which holds the token
            }
        })
        return cards;
    } catch (error) {
        return error.request;
    }
}
export const changeProperty = async (idAndProperties) => {
    try {
        const responseChangeProperty = await instance_axios.post("/content/changeproperties", idAndProperties, {
            withCredentials: true,
           headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user')//the token is a variable which holds the token
            }
        });
        return responseChangeProperty;
    } catch (error) {
        console.log(error);
        return error.request;
    }
}
