import React, { Component } from 'react';
import Mapv2 from '../map/Mapv2';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class MapModal extends Component {
    render() {
        const toggleModalMap = this.props.toggleModalMap;
        const handleLocation = this.props.handleLocation;
        const location = [];

        if (this.props.location.coordinates.lat !== '')
            location.push(this.props.location);

        return (
            <div>
                {/* <!--Modal--> */}
                <div className="modalMap opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex items-center justify-center">
                    <div className="modalMap-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
                
                    <div className="bg-white mx-auto rounded shadow-lg z-50 overflow-y-auto">
                        <div className="modal-header relative font-extralight text-lg flex justify-between px-3 mt-1">
                            <div className="title text-gray-600 text-2xl">{this.props.t('common.modals.newScreenLocation.modal-title')}</div>
                            <div className="button cursor-pointer" onClick={toggleModalMap}><FontAwesomeIcon icon={["fal", "times"]} /></div>
                        </div>
                        <div className="modalMap-content py-4 text-left px-6">
                        {/* <!--Body--> */}
                        <div style = {{height : '400px', width : '550px'}}>
                            <Mapv2 location={location}/>
                        </div>

                        {/* <!--Footer--> */}
                        <div className="clear-left flex justify-end pt-2">
                            <button className="modalMap-close buttonPrimary" onClick={handleLocation}>{this.props.t('common.buttons.accept')}</button>
                        </div>              
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(MapModal);