import React, { Component } from 'react'
import { register } from './UserFunctions'
import { Link } from 'react-router-dom';
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
// import { ReactComponent as LogoWhite } from '../../images/logo-white.svg';
// import { ReactComponent as LogoColor } from '../../images/logo-color.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {validateMail} from '../utils/auth'
import { withTranslation } from 'react-i18next';

class Register extends Component {
  constructor() {
    super()
    this.state = {
      first_name: '',
      last_name: '',
      password: '',
      company: '',
      email: '',
      country: '',
      phone_number: '',
      actual_cms: '',
      publisher_id: '',
      err_message: '',
      togglePassword:false
    }
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.id]: e.target.value });
  }
  onSubmit(e) {
    e.preventDefault();
    const user = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      password: this.state.password,
      company: this.state.company,
      email: this.state.email,
      country: this.state.country,
      phone_number: this.state.phone_number,
      actual_cms: this.state.actual_cms,
      publisher_id: this.state.publisher_id
    }


    if (user.first_name !== '' && user.last_name !== '' && user.password !== '' && user.company !== '' && user.email !== '' && user.country !== '' && user.phone_number !== '') {
      if(!validateMail(user.email)) {
        this.props.showNotification({
          text: this.props.t('sections.register.mailFormat'),
          type: "warning",
          duration: 3000
        });
        return;
      }

      register(user).then(res => {
        if (res.status === 201) {
          this.props.showNotification({
            text : this.props.t('sections.register.success'),
            type : "success",
            duration: 3000
          })
          setTimeout(() => {
            this.props.history.push('/login')
          }, 1500);
        }
        else if (res.status === 409){
          this.props.showNotification({
            text: this.props.t('sections.register.userAlreadyExists'),
            type: "warning",
            duration: 3000
          })
        }
        else {
          this.props.showNotification({
            text: this.props.t('common.notification.serverError'),
            type: "error",
            duration: 3000
          })
        }
      })
      .catch(err => {
        console.log('catch del register', err);
        this.props.showNotification({
          text: this.props.t('common.notification.serverError'),
          type: "error",
          duration: 3000
        })
      })
    } else {
        this.props.showNotification({
          text : this.props.t('sections.register.completeFields'),
          type : "warning",
          duration: 3000
        })
    }
  }

  validateMail = (mail) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(mail).toLowerCase());
  }

  render() {
    return (
      <div className="relative w-full h-screen flex justify-between">
        <div className={`relative hidden md:block w-2/6 h-full text-white p-4 bg-cover bg-center bg-opacity-25 bg-gradient-to-r ${ window.Config.REACT_APP_WL_NAME.toString() === "taggify" ? "from-green-400 to-blue-500 w-4/6" : "from-purple-400 to-pink-800 w-full"}`}>
          <div className="logo overflow-hidden">
            {/* <LogoWhite className="h-10 object-left-top" /> */}
            <img className={`object-left-top ${window.Config.REACT_APP_WL_NAME.toString() ===  "taggify" ? "h-10" : "h-20"}`}  alt="Taggify Simple Signage" />
          </div>
        </div>
        <div className="relative w-full md:w-4/6 h-full overflow-x-hidden text-black p-4 flex flex-col">
          <div className="logo block md:hidden">
            {/* <LogoColor /> */}
            <img src={require(`../../images/${window.Config.REACT_APP_WL_NAME}/logo-color.svg`)} alt="Taggify Simple Signage" />
          </div>
          <div className="text-gray-500 md:text-3xl font-extralight">{this.props.t('sections.register.mainTitle')}</div>
          <div className="mt-5 p-0 md:p-8 h-full font-light">
            <form noValidate onSubmit={this.onSubmit}>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                    {this.props.t('sections.register.name-label')}  *
                    </label>
                  <input className="appearance-none block w-full bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="first_name" type="text" placeholder={this.props.t('sections.register.name-placeholder')} value={this.state.first_name} onChange={this.onChange} />
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                    {this.props.t('sections.register.lastname-label')}  *
                    </label>
                  <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="last_name" type="text" placeholder={this.props.t('sections.register.lastname-placeholder')} value={this.state.last_name} onChange={this.onChange} />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-email">
                    {this.props.t('sections.register.email-label')}  *
                    </label>
                  <input className="appearance-none block w-full bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="email" type="text" placeholder={this.props.t('sections.register.email-label')} value={this.state.email} onChange={this.onChange} />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 relative">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                    {this.props.t('sections.register.password-label')}  *
                    </label>
                  <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="password"  type={
                                            this.state.togglePassword
                                            ? "text"
                                            : "password"
                                    }  placeholder={this.props.t('sections.register.password-placeholder')} value={this.state.password} onChange={this.onChange} />
                                    <button
                                    type="button"
                                        className="absolute top-9 right-7"
                                        onClick={() => {
                                            this.setState({
                                                togglePassword:
                                                    !this.state.togglePassword,
                                            });
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={
                                                this.state.togglePassword
                                                    ? faEye
                                                    : faEyeSlash
                                            }
                                        />
                                    </button>
                </div>
              <div className="w-full md:w-1/2 px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-company">
                    {this.props.t('sections.register.company-label')}  *
                    </label>
                  <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="company" type="text" placeholder={this.props.t('sections.register.company-placeholder')} value={this.state.company} onChange={this.onChange} />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-country">
                    {this.props.t('sections.register.country-label')}  *
                    </label>
                  <input className="appearance-none block w-full bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="country" type="text" placeholder={this.props.t('sections.register.country-placeholder')} value={this.state.country} onChange={this.onChange} />
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-phone-number">
                    {this.props.t('sections.register.phone-label')}  *
                    </label>
                  <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="phone_number" type="text" placeholder={this.props.t('sections.register.phone-placeholder')} value={this.state.phone_number} onChange={this.onChange} />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-actual-cms">
                    {this.props.t('sections.register.currentCMS-label')}
                  </label>
                  <input className="appearance-none block w-full bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="actual_cms" type="text" placeholder={this.props.t('sections.register.currentCMS-placeholder')} value={this.state.actual_cms} onChange={this.onChange} />
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-publsiher-id">
                    {this.props.t('sections.register.publisherID-label')}
                  </label>
                  <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="publisher_id" type="text" placeholder={this.props.t('sections.register.publisherID-placeholder')} value={this.state.publisher_id} onChange={this.onChange} />
                </div>
              </div>
              <div className="flex items-center justify-between">
                <button type="submit" className="buttonPrimary">
                  {this.props.t('common.buttons.signup')}
                </button>
              </div>
            </form>
            <p id='p_error' className="text-center hidden">
              {this.state.err_message}
            </p>
            <div className="fixed bg-white p-1 rounded-sm ring-1 ring-black ring-opacity-5 bottom-0 right-0 text-center text-xs mr-3 text-gray-600">{this.props.t('sections.register.alreadyHaveAccount')} <Link className="font-medium text-blue-600 hover:text-indigo-500" to='/login'> {this.props.t('common.buttons.signin')}</Link></div>
          </div>
        </div>
      </div>
    )
  }
}
export default withTranslation()(Register);
