import React, { Component, useState } from 'react';
import { withTranslation } from 'react-i18next';
import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GroupModal from './GroupModal';

class StepOne extends Component {

  continue = (e) => {
    e.preventDefault();
    const {showNotification, t, setCurrentStep, currentStep, stepOneIsCompleted} = this.props;
    const {inputValue, name, groups, group, pixel_size_width, pixel_size_height, position_x_canvas, position_y_canvas,rotations, rotation } = this.props.values;







    if (!stepOneIsCompleted()) {

      showNotification({
        type: 'warning',
        text:('You need to complete all the fields with the "*" simbol!'),

      });

      const firstInp = document.querySelector('.firstInp');
      const sndInp = document.querySelector('.sndInp');
      const trcInp = document.querySelector('.trcInp');
      const frdInp = document.querySelector('.frdInp');
      const fvdInp = document.querySelector('.fvdInp');

      if (name.length === 0 && !firstInp.classList.contains('text-red-500')) {
        firstInp.classList.add('text-red-500'); // Agregamos la clase roja
        firstInp.classList.remove('text-green-500'); // Eliminamos la clase verde si está presente
      } else if(name.length > 0){
        firstInp.classList.add('text-green-500'); // Agregamos la clase verde
        firstInp.classList.remove('text-red-500'); // Eliminamos la clase roja si está presente
      }

      if (pixel_size_width.length === 0 && !sndInp.classList.contains('text-red-500')) {
        sndInp.classList.add('text-red-500'); // Agregamos la clase roja
        sndInp.classList.remove('text-green-500'); // Eliminamos la clase verde si está presente
      } else if(pixel_size_width> 0){
        sndInp.classList.add('text-green-500'); // Agregamos la clase verde
        sndInp.classList.remove('text-red-500'); // Eliminamos la clase roja si está presente
      }
      if (pixel_size_height.length === 0 && !trcInp.classList.contains('text-red-500')) {
        trcInp.classList.add('text-red-500'); // Agregamos la clase roja
        trcInp.classList.remove('text-green-500'); // Eliminamos la clase verde si está presente
      } else if(pixel_size_height > 0){
        trcInp.classList.add('text-green-500'); // Agregamos la clase verde
        trcInp.classList.remove('text-red-500'); // Eliminamos la clase roja si está presente
      }
      if (position_x_canvas.length === 0 && !frdInp.classList.contains('text-red-500')) {
        frdInp.classList.add('text-red-500'); // Agregamos la clase roja
        frdInp.classList.remove('text-green-500'); // Eliminamos la clase verde si está presente
      } else if(position_x_canvas> 0){
        frdInp.classList.add('text-green-500'); // Agregamos la clase verde
        frdInp.classList.remove('text-red-500'); // Eliminamos la clase roja si está presente
      }
      if (position_y_canvas.length === 0 && !fvdInp.classList.contains('text-red-500')) {
        fvdInp.classList.add('text-red-500'); // Agregamos la clase roja
        fvdInp.classList.remove('text-green-500'); // Eliminamos la clase verde si está presente
      } else if(position_y_canvas > 0){
        fvdInp.classList.add('text-green-500'); // Agregamos la clase verde
        fvdInp.classList.remove('text-red-500'); // Eliminamos la clase roja si está presente
      }
      return;
    }

    setCurrentStep(currentStep + 1);
  }

  toggleModal() { //Modal
    const body = document.querySelector('body')
    const modal = document.querySelector('.modal')
    modal.classList.toggle('opacity-0')
    modal.classList.toggle('pointer-events-none')
    body.classList.toggle('modal-active')
  }

  eventAdd() { //Modal
    var openmodal = document.querySelectorAll('.modal-open')
    for (var i = 0; i < openmodal.length; i++) {
      openmodal[i].addEventListener('click', function (event) {
        event.preventDefault()
        this.toggleModal();
      })
    }

    const overlay = document.querySelector('.modal-overlay')
    overlay.addEventListener('click', this.toggleModal)

    var closemodal = document.querySelectorAll('.modal-close')
    for (let i = 0; i < closemodal.length; i++) {
      closemodal[i].addEventListener('click', this.toggleModal)
    }

    document.onkeydown = function (evt) {
      evt = evt || window.event
      var isEscape = false
      if ("key" in evt) {
        isEscape = (evt.key === "Escape" || evt.key === "Esc")
      } else {
        isEscape = (evt.keyCode === 27)
      }
      if (isEscape && document.body.classList.contains('modal-active')) {
        const body = document.querySelector('body')
        const modal = document.querySelector('.modal')
        modal.classList.toggle('opacity-0')
        modal.classList.toggle('pointer-events-none')
        body.classList.toggle('modal-active')
      }
    };
  }
  createGroup = (group) => {
    if (group._id === this.props.values.group) {
      return <option selected key={group._id} value={group._id}>{group.name}</option>
    } else {
      return <option key={group._id} value={group._id}>{group.name}</option>
    }
  }
  createRotationOptions = (rotation) =>{
    if (rotation === this.props.values.rotation) {
      return <option key={rotation} value={rotation}>{rotation} {this.props.t('sections.wizard.stepOne.rotations.degrees')}</option>
    } else {
      return <option key={rotation} value={rotation}>{rotation} {this.props.t('sections.wizard.stepOne.rotations.degrees')}</option>
    }
  }
  render() {
    const {handleInputChange, getGroups, setGroup, handleNumber, handleGroup, handleChange, handleSimpleSelect} = this.props;
    const {inputValue, name, groups, group, pixel_size_width, pixel_size_height, position_x_canvas, position_y_canvas,rotations, rotation } = this.props.values;


    return (
      <div className="w-full p-5">
        <h1 className="text-gray-800 text-xl font-bold">{this.props.t('sections.wizard.stepOne.stepTitle')} <span className="font-light text-sm text-gray-700"> - {this.props.t('sections.wizard.stepOne.stepTitleExtra')}</span></h1>
        <hr />
        <div className="my-2">
          <label className={`firstInp block text-gray-700  text-sm font-bold mb-2`} >{this.props.t('sections.wizard.stepOne.name-label')} * </label>
          <input type="text"
            tabIndex="1"
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
            onChange={(e) => handleChange('name', e)}
            value={name}
            placeholder={this.props.t('sections.wizard.stepOne.name-placeholder')}
            required
            />
        </div>

        <div className="my-2">
          <label className="block text-gray-700 text-sm font-bold mb-2">{this.props.t('sections.wizard.stepOne.group-label')}</label>
          <div className="flex">
            <div className="relative flex-grow mr-5">
              <select tabIndex="2" onChange={handleGroup} value={group} className="block appearance-none text-gray-700 w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:focus:ring">
                <option value="-1" selected>{this.props.t('sections.wizard.stepOne.group-default')}</option>
                {groups.map(group => this.createGroup(group))}
              </select>
              <div className="pointer-events-none absolute vertical-center right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
              </div>
            </div>
            <button className="bg-blue-500 hover:bg-blue-700 w-10 rounded" onClick={this.toggleModal}>
             <FontAwesomeIcon fixedWidth icon={["fal", "plus"]} size="1x" className="text-white" />
            </button>
          </div>
        </div>

        <GroupModal
          toggleModal={this.toggleModal}
          getGroups={getGroups}
          setGroup={setGroup}
          group={group}
          showNotification={this.props.showNotification}
        />

        <div className="my-2">
          <label className="sndInp block text-gray-700 text-sm font-bold mb-2">
            <FontAwesomeIcon fixedWidth icon={['fad', 'arrows-h']} className="mr-4" />
            {this.props.t('sections.wizard.stepOne.widthPixels-label')} * <span className="font-light text-gray-500">{this.props.t('sections.wizard.stepOne.inPixels-helper')}</span>
          </label>
          <input type="text"
            className="shadow appearance-none border rounded w-full mb-4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring"
            tabIndex="3"
            onChange={handleNumber('pixel_size_width')}
            value={pixel_size_width}
            placeholder={this.props.t('sections.wizard.stepOne.widthPixels-placeholder')}/>

          <label className="trcInp block text-gray-700 text-sm font-bold mb-2">
            <FontAwesomeIcon fixedWidth icon={['fad', 'arrows-v']} className="mr-4" />
            {this.props.t('sections.wizard.stepOne.heightPixels-label')} * <span className="font-light text-gray-500">{this.props.t('sections.wizard.stepOne.inPixels-helper')}</span>
          </label>
          <input type="text"
            className="shadow appearance-none border rounded w-full mb-4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring"
            tabIndex="4"
            onChange={handleNumber('pixel_size_height')}
            value={pixel_size_height}
            placeholder={this.props.t('sections.wizard.stepOne.heightPixels-placeholder')} />
        </div>

        <div className="my-2">
          <label className="frdInp block text-gray-700 text-sm font-bold mb-2">
            <FontAwesomeIcon fixedWidth icon={['fad', 'border-left']} className="mr-4" />
            {this.props.t('sections.wizard.stepOne.canvasX-label')} *
          </label>
          <input type="text"
            className="shadow appearance-none border rounded w-full mb-4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring"
            tabIndex="5"
            onChange={handleNumber('position_x_canvas')}
            value={position_x_canvas}
            placeholder={this.props.t('sections.wizard.stepOne.canvasX-placeholder')} />

          <label className="fvdInp block text-gray-700 text-sm font-bold mb-2">
            <FontAwesomeIcon fixedWidth icon={['fad', 'border-top']} className="mr-4" />
            {this.props.t('sections.wizard.stepOne.canvasY-label')} *
          </label>
          <input type="text"
            className="shadow appearance-none border rounded w-full mb-4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring"
            tabIndex="6"
            onChange={handleNumber('position_y_canvas')}
            value={position_y_canvas}
            placeholder={this.props.t('sections.wizard.stepOne.canvasY-placeholder')} />
            <label className="block text-gray-700 text-sm font-bold mb-2">
              <FontAwesomeIcon fixedWidth icon={['fad', 'sync-alt']} className="mr-4" />
              {this.props.t('sections.wizard.stepOne.rotations.label')}
          </label>
            <select tabIndex="2" onChange={handleSimpleSelect('rotation')} value={rotation} className="block appearance-none text-gray-700 w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:focus:ring">
              {rotations?.map(rotation => this.createRotationOptions(rotation))}
            </select>
        </div>
        <button
          type='submit'
         className="buttonPrimary"
          tabIndex="7"
          onClick={this.continue}>{this.props.t('common.buttons.accept')}</button>
      </div>
    );
  }
}
export default withTranslation()(StepOne);
