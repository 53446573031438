import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
class ReportsHeader extends Component {
  confirmDelete = () => {
    this.props.deleteContent()
    this.props.switchCheckedCheckbox();
    this.props.clearDeleteCheckboxes();
  }

  cancelDelete = () => {
    this.props.switchCheckedCheckbox();
    this.props.clearDeleteCheckboxes();
  }

  render() {
    return (
      <>
        <div className="relative border-b-2 border-gray-200 py-2 flex flex-row justify-between align-center">
          {/* titulo */}
          <div>
            <span className="font-extralight text-center align-middle">{this.props.t('sections.reports.mainTitle')}</span>
          </div>
          <div className="flex flex-row justify-between align-center transform scale-90">

          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(ReportsHeader);