import axios from 'axios';
import * as dotenv from 'dotenv';
dotenv.config();
const baseURL = window.Config.REACT_APP_BACKEND_URL;
const instance_axios = axios.create({ baseURL });

export const getStatistics = () => {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return instance_axios.get('/dashboard/statistics', {
            withCredentials: true,
           headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user')//the token is a variable which holds the token
            }
        })
        .then(r => r)
        .catch(err => err);
}
