import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';

const GridItemDetails =(props)=> {
        const { t } = useTranslation();
        const item = props.item;
        const videoExtensions = ['mp4', 'ogg', 'webm'];
        const htmlExtensions = ['zip','html'];
        const includeHTML = htmlExtensions.includes(item.ext);
        const includeVideo = videoExtensions.includes(item.ext);
        return(
          <>
            <div className="">
            {!includeHTML ?
                <span className="playlist-item-size font-extralight text-gray-600 mr-1 ml-1"><FontAwesomeIcon  icon={['fal','expand-wide' ]} className="text-blue-400 ml-0 mr-1.5 "/> {item.width}x{item.height}</span>
                :
                <span className="playlist-item-size font-extralight text-gray-600 ml-1">{t('sections.screens.screenPlaylist.htmlFile')}</span>
            }
            {
                includeVideo ?
                <span className="playlist-item-duration font-extralight text-gray-600 mr-3"><FontAwesomeIcon icon={["fal", "stopwatch"]} fixedWidth className="text-blue-600 mr-1 ml-0.5 text-sm" />{(item.duration)?.toFixed(2)}"</span>
                :
                <span className="playlist-item-duration font-extralight text-gray-600 mr-3"><FontAwesomeIcon icon={["fal", "stopwatch"]} fixedWidth className="text-blue-600 mr-1 ml-0.5 text-sm" />{item.duration ? item.duration : (props.screen.ad_duration)}"</span>
            }
            {item.schedule.endDate !== "" ?
                <div>
                    <FontAwesomeIcon icon={['fal','calendar-alt' ]} className="text-blue-400 ml-1.5 mr-1.5"  />
                    <span className="playlist-item-size font-extralight pr-1 text-gray-600">{item.schedule.startDate}</span>
                    <span className="playlist-item-size font-extralight pr-1 text-gray-600">{item.schedule.endDate?"~ "+item.schedule.endDate:""}</span>
                </div>
                :
                <div>
                    <FontAwesomeIcon icon={['fal','calendar-alt' ]} className="text-blue-400 ml-1.5 mr-1.5"  />
                    <span className="playlist-item-size font-extralight pr-1 text-gray-600">{item.schedule.startDate}</span>
                    <span className="playlist-item-size font-extralight pr-1 text-gray-600">{item.schedule.endDate?"~ "+item.schedule.endDate:""}</span>
                </div>
            }
            </div>
          </>
        )
}
export default GridItemDetails;
