import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExtraButtonsMenu from '../../utils/ExtraButtonsMenu'

const LaneMenu = (props) => {
  const getButtonList = () =>{
    let buttonList = [
      { title: props.traslate("sections.status.deleteColumn"), view: true, function: () => { props.openModal('deleteLane');props.setLaneId(props.laneId)}, icon: <FontAwesomeIcon icon={["fal", "trash"]} className="mr-3" />, class: "text-red-500" },
      { title: props.traslate("sections.status.editColumn"), view: true, function: () => { props.openModal("ModalAddColumn");props.setEditColumn(props.laneId)}, icon: <FontAwesomeIcon icon={["fal", "edit"]} className="mr-3" />, class: "" }
    ];
    return buttonList;
  }


  return (
  <div className='flex '>
    <div className='ml-10' onClick={()=>props.openModal("BoardCardModal")}>
      <FontAwesomeIcon icon={['far', 'plus']} className="cursor-pointer" />
      </div>
     <div className='p-2 pt-0 absolute right-0 pr-0 mr-0' >
        <ExtraButtonsMenu classIcon={"text-black"} id={"ExtraButtonsMenuLaneHeader"+ props.laneId} buttons={getButtonList()} ></ExtraButtonsMenu>
     </div>
    </div>
  )
}

export default LaneMenu;
