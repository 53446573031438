import React, { Component } from 'react'

export default class Searchbar extends Component {
    onChange = (e) => {
        this.props.callFunction(e.target.value)
    }
    render() {
        const { placeholder, classNames, onFocus, disabled } = this.props
        return (
            <div className={``}>
                <input className={`${classNames} focus:${onFocus}`} placeholder={placeholder} disabled={disabled} onChange={this.onChange}/>
            </div>
        )
    }
}
