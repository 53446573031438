import { withRouter } from "react-router-dom";
import React, { Component } from "react";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logOut } from "../Log_Reg/UserFunctions";
import { withTranslation } from "react-i18next";
import { sendForm, getLoggedUser } from "./UserConsole.Functions";
import ReactTooltip from "react-tooltip";
import Profile from "../profile/Profile.js";
import "./userConsole.css";
class UserConsole extends Component {
    constructor() {
        super();
        this.state = {
            isHelpOpen: false,
            isProfile: true,
        };
    }

    /**  A function that toggles the menu of user option. */
    toggleMenu = () => {
        this.setState({ isMenuOpen: !this.state.isMenuOpen });
    };

    /* A function that toggles the menu of profile. */
    toggleProfile = () => {
        if (this.props.isProfileOpen === true) {
            document.getElementById("ModalProfile").classList.remove("hidden");
            document.getElementById("blackScreen").classList.add("blackScreen");
        } else {
            document.getElementById("ModalProfile").classList.add("hidden");
            document
                .getElementById("blackScreen")
                .classList.remove("blackScreen");
            document.getElementById("blackScreen").classList.add("hidden");
        }
    };

    /**
     * It sends a form to an email address.
     */
    sendForm = () => {
        if (!this.state.issueSelected) {
            this.props.showNotification({
                type: "error",
                text: this.props.t(
                    "common.notification.userConsole.issueNotSelected"
                ),
            });
            return;
        } else if (!this.state.issueDescription) {
            this.props.showNotification({
                type: "error",
                text: this.props.t(
                    "common.notification.userConsole.issueNotDescribed"
                ),
            });
            return;
        }
        getLoggedUser()
            .then((r) => {
                let dataArray = [
                    {
                        address:
                            "toiluj23+jygjs7nddodbaptge6al@boards.trello.com",
                        subject: "New issue: " + this.state.issueSelected.label,
                        body: `
                          <h3>User: ${r.data.userName}</h3>
                          <p>Contact to: ${r.data.userMail}</p>
                          <p>Issue: ${this.state.issueDescription}</p>
                          `,
                    },
                    {
                        address: "support@taggify.net",
                        subject: "Support from DS-CMS",
                        body: `<h2>New issue from: ${r.data.userName}</h2>
                            <h2>${this.state.issueSelected.label}</h2>
                            <h3>${this.state.issueDescription}</h3>
                            <p>See <a href="https://trello.com/b/HnpkF3d0">Trello</a> for more info.</p>`,
                        replyTo: r.data.userMail,
                    },
                ];
                sendForm(dataArray)
                    .then((r) => {
                        this.setState(
                            {
                                issueDescription: "",
                                issueSent: true,
                            },
                            () => {
                                setTimeout(() => {
                                    this.setState(
                                        {
                                            issueSelected: null,
                                            isHelpOpen: false,
                                            issueSent: false,
                                        },
                                        () => {
                                            document.querySelector(
                                                "#issueDescription"
                                            ).value = "";
                                        }
                                    );
                                }, 3000);
                            }
                        );
                    })
                    .catch((err) => {
                        console.log(err);
                        this.props.showNotification({
                            type: "error",
                            text: this.props.t(
                                "common.notification.userConsole.issueError"
                            ),
                        });
                    });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    /**
     * If the logOut function returns a status of 200, then  log out.
     */
    logOutAndRedirect = () => {
        logOut()
            .then((r) => {
                if (r.status === 200) {
                    this.props.logOut();
                }
            })
            .catch((err) => console.log(err));
    };

    /**
     * When the user selects an issue, update the state of the component to reflect the issue selected.
     * @param e - the event object
     */
    handleSelectIssue = (e) => {
        this.setState({
            issueSelected: e,
        });
    };

    /**
     * The function takes an event as an argument, prevents the default action, and sets the state of the
     * issueDescription property to the value of the event target.
     * @param e - the event object
     */
    handleDescribeIssue = (e) => {
        e.preventDefault();
        this.setState({
            issueDescription: e.target.value,
        });
    };

    render() {
        const { isHelpOpen } = this.props;
        const { t } = this.props;
        return (
            <>
                <ReactTooltip id="help-tip">
                    {t("common.menu.help")} / {t("sections.support.pageTitle")}
                </ReactTooltip>

                {/* <div
                    id="btnHelp"
                    onClick={() => {
                        this.props.handleModalsOpen("help", this.toggleProfile);
                    }}
                    data-tip
                    data-for="help-tip"
                    data-class="shadow-lg"
                    data-background-color="white"
                    data-text-color="000000"
                    className="fixed bottom-2 right-2 z-40 w-14 h-14 flex items-center justify-center shadow-md rounded-full bg-green-300 bg-opacity-90 border-2 border-white cursor-help transform transition-transform duration-75 hover:scale-95"
                >
                    <FontAwesomeIcon
                        icon={["fas", "user-headset"]}
                        className="text-white text-2xl self-center"
                    />
                </div> */}
                <div
                    className={`${
                        isHelpOpen ? "block" : "hidden"
                    } absolute inset-y-0 right-0 z-50 bg-white shadow-xl w-10/12 md:w-2/5 p-4`}
                >
                    <div
                        onClick={this.props.toggleHelp}
                        className="cursor-pointer"
                    >
                        <FontAwesomeIcon
                            icon={["fal", "times"]}
                            fixedWidth
                            className="text-blue-400 mb-2"
                        />
                    </div>
                    <div className="block my-2 font-semibold">
                        {t("sections.support.pageTitle")}
                    </div>
                    <div className="block my-2 font-light">
                        {t("sections.support.pageText")}
                    </div>
                    {this.state.issueSent && (
                        <div className="block my-2 p-2 rounded-sm text-green-500">
                            {t("sections.support.formSent")}
                        </div>
                    )}
                    <div className="block my-2">
                        <Select
                            className="block appearance-none text-gray-700 w-full bg-white hover:border-gray-500 rounded shadow leading-tight focus:outline-none focus:focus:ring"
                            tabIndex={1}
                            placeholder={t("sections.support.options.option0")}
                            onChange={this.handleSelectIssue}
                            options={[
                                {
                                    value: "1",
                                    label: t(
                                        "sections.support.options.option1"
                                    ),
                                },
                                {
                                    value: "2",
                                    label: t(
                                        "sections.support.options.option2"
                                    ),
                                },
                                {
                                    value: "3",
                                    label: t(
                                        "sections.support.options.option3"
                                    ),
                                },
                                {
                                    value: "4",
                                    label: t(
                                        "sections.support.options.option4"
                                    ),
                                },
                            ]}
                            value={this.state.issueSelected}
                        />
                        <textarea
                            id="issueDescription"
                            tabIndex={2}
                            className="block appearance-none resize-none w-full bg-white border border-gray-400 hover:border-gray-500 my-2 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:focus:ring"
                            placeholder={t(
                                "sections.support.textarea-placeholder"
                            )}
                            rows="3"
                            onChange={this.handleDescribeIssue}
                        ></textarea>
                        <button
                            className="buttonPrimary"
                            tabIndex={3}
                            onClick={this.sendForm}
                        >
                            {this.props.t("common.buttons.submit")}
                        </button>
                    </div>
                </div>
                <Profile
                    handleModalsOpen={this.props.handleModalsOpen}
                    modalID={"ModalProfile"}
                    toggleProfile={this.toggleProfile}
                    showNotification={this.props.showNotification}
                ></Profile>
            </>
        );
    }
}
export default withTranslation()(withRouter(UserConsole));
