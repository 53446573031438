import React from 'react';
import { Link } from 'react-router-dom';

const WizardHeader = (props) => {

    return (
        <div className="relative border-b-2 border-gray-200 py-2">
          <span className="font-extralight">{props.title}</span>
          <div className='flex flex-row-reverse'>
            <Link to="/import">
                <button className='rounded-md buttonPrimary overflow-hidden'>
                    {props.bulkButton}
                </button>
            </Link>
          </div>
        </div>
    )
}
export {WizardHeader};