import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MapModal from './MapModal';
import ButtonSwitcher from '../buttons/ButtonSwitcher';
import ReactTooltip from 'react-tooltip';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import BrightnessControlModal from '../utils/Wizard/BrightnessControlModal';

class StepTwo extends Component {
  back = (e) => {
    e.preventDefault();
    const { setCurrentStep, currentStep} = this.props;
    setCurrentStep(currentStep - 1);
  }

  continue = (e) => {
    e.preventDefault();
    const {showNotification, t, setCurrentStep, currentStep, stepTwoIsCompleted} = this.props;
    const { total_spots, ad_duration, auto_launch, schedule, location, allDaysSwitchStatus, brightness } = this.props.values;

    

    if (!stepTwoIsCompleted()) {
      showNotification({
        type: "warning",
        text: t('common.notification.completeFields')
      });

      const firstInp = document.querySelector('.firstInp');
      const sndInp = document.querySelector('.sndInp');
      const schStr = document.querySelector('.schStr');
      const trdInp = document.querySelector('.trdInp');

      if (total_spots.length === 0 && !firstInp.classList.contains('text-red-500')) {
        firstInp.classList.add('text-red-500'); // Agregamos la clase roja
        firstInp.classList.remove('text-green-500'); // Eliminamos la clase verde si está presente
      } else if(total_spots.length > 0){
        firstInp.classList.add('text-green-500'); // Agregamos la clase verde
        firstInp.classList.remove('text-red-500');}

        if (ad_duration.length === 0 && !sndInp.classList.contains('text-red-500')) {
          sndInp.classList.add('text-red-500'); // Agregamos la clase roja
          sndInp.classList.remove('text-green-500'); // Eliminamos la clase verde si está presente
        } else if(ad_duration.length > 0){
          sndInp.classList.add('text-green-500'); // Agregamos la clase verde
          sndInp.classList.remove('text-red-500');} // Eliminamos la clase roja si está presente
          let allEmpty = true;
          for (let i = 0; i < schedule.length; i++) {
            if (schedule[i].start.length !== 0 && schedule[i].end.length !== 0) {
              allEmpty = false;
              break; // No es necesario seguir verificando si al menos uno no está vacío
            }
          }

          if (allEmpty && !schStr.classList.contains('text-red-500')) {
            schStr.classList.add('text-red-500'); // Agregamos la clase roja
            schStr.classList.remove('text-green-500'); // Eliminamos la clase verde si está presente
          } else if (!allEmpty && !schStr.classList.contains('border-green-500')) {
            schStr.classList.add('text-green-500'); // Agregamos la clase verde
            schStr.classList.remove('text-red-500'); // Eliminamos la clase roja si está presente
          }
          /*if (location.length !== '' && !trdInp.classList.contains('text-red-500')) {
            trdInp.classList.add('text-red-500'); // Agregamos la clase roja
            trdInp.classList.remove('text-green-500'); // Eliminamos la clase verde si está presente
          } else if(location.length > 0){
            trdInp.classList.add('text-green-500'); // Agregamos la clase verde
            trdInp.classList.remove('text-red-500');}
            console.log(location)*/

            for (const key in location) {
              if (location.hasOwnProperty(key) && (location[key] === null || location[key] === undefined || location[key] === '' && !trdInp.classList.contains('text-red-500'))) { 
                trdInp.classList.add('text-red-500'); // Agregamos la clase roja
                trdInp.classList.remove('text-green-500');// Al menos una propiedad no tiene datos completos
              }
              else{trdInp.classList.add('text-green-500'); // Agregamos la clase verde
                   trdInp.classList.remove('text-red-500');}
            }
      return;
    }


    // if (!startTimeLessEndTime()) {
    //   this.props.showNotification({
    //     type: "warning",
    //     text: t('common.notification.stepTwo.startTimeHigherEndTime')
    //   });
    //   return;
    // }

    setCurrentStep(currentStep + 1);
  }

  toggleModalMap() { //Modal
    const body = document.querySelector('body')
    const modal = document.querySelector('.modalMap')
    modal.classList.toggle('opacity-0')
    modal.classList.toggle('pointer-events-none')
    body.classList.toggle('modal-active')
  }

  eventAddMap() { //Modal
    var openmodal = document.querySelectorAll('.modalMap-open')
    for (var i = 0; i < openmodal.length; i++) {
      openmodal[i].addEventListener('click', function (event) {
        event.preventDefault()
        this.toggleModalMap();
      })
    }

    const overlay = document.querySelector('.modalMap-overlay')
    overlay.addEventListener('click', this.toggleModalMap)

    var closemodal = document.querySelectorAll('.modalMap-close')
    for (let i = 0; i < closemodal.length; i++) {
      closemodal[i].addEventListener('click', this.toggleModalMap)
    }

    document.onkeydown = function (evt) {
      evt = evt || window.event
      var isEscape = false
      if ("key" in evt) {
        isEscape = (evt.key === "Escape" || evt.key === "Esc")
      } else {
        isEscape = (evt.keyCode === 27)
      }
      if (isEscape && document.body.classList.contains('modalMap-active')) {
        const body = document.querySelector('body')
        const modal = document.querySelector('.modalMap')
        modal.classList.toggle('opacity-0')
        modal.classList.toggle('pointer-events-none')
        body.classList.toggle('modalMap-active')
      }
    };
  }

  handleAllDay = (e) => {
    const input = e.target;
    const schedule = this.props.values.schedule;

    if (input.getAttribute('data-timelapse') === 'start') {
      schedule.forEach(day => {
        day.start = input.value;
      });
    }
    else {
      schedule.forEach(day => {
        day.end = input.value;
      });
    }

    this.props.handleSchedule(schedule);
  }

  handleSingleDay = (e) => {
    let input;

    if (e.target)
      input = e.target;
    else
      input = e;

    const index = input.getAttribute("data-index");
    const schedule = this.props.values.schedule;

    if (input.name === 'start') {
      schedule[index].start = input.value;
    } else if (input.name === 'end') {
      schedule[index].end = input.value;
    }

    this.props.handleSchedule(schedule);
  }

  clearDay = (e) => {
    let dayIndex;

    if (e.target.getAttribute('data-dayindex'))
      dayIndex = e.target.getAttribute('data-dayindex');
    else
      dayIndex = e.target.parentNode.getAttribute('data-dayindex');

    const dayInputs = document.querySelectorAll('.dayInput');

    dayInputs.forEach(day => {
      if (day.getAttribute('data-index') === dayIndex) {
        day.value = '';
        this.handleSingleDay(day);
      }
    });
  }

  cloneDay = () => {
    const dayInputs = document.querySelectorAll('.dayInput');
    const mondayStart = dayInputs[0];
    const mondayEnd = dayInputs[1];

    for (let i = 2; i < dayInputs.length; i += 2) {
      const dayStart = dayInputs[i];
      const dayEnd = dayInputs[i + 1];
      dayStart.value = mondayStart.value;
      dayEnd.value = mondayEnd.value;
      this.handleSingleDay(dayStart);
      this.handleSingleDay(dayEnd);
    }
  }
  handleCheckbox = (e) => {
    const checkbox = e.target;
    this.props.setAutoLaunch(checkbox.checked);
  }
  handleBrightnessOption = (option) => {
    console.log(option)
    if(this.state?.selectedBrightnessOption){
        this.props.handleBrightnessOption(option, this.state.selectedBrightnessOption.index);
    }else{
        this.props.handleBrightnessOption(option);
    }
    this.setState({
      selectedBrightnessOption: {}
    })
  }
  editBrightnessOption = (option, index) => {
    this.setState({
      selectedBrightnessOption: {option, index}
    }, () => {
      this.openModal("BrightnessControlModal")
    })
  }
  deleteBrightnessOption = (index) => {
    this.props.deleteBrightnessOption(index);
  }

  openModal = (id) => {
    document.getElementById(id).classList.toggle("hidden");
  }

  handleBrightnessOptionFromCard = (value, index) => {
    let brightnessOptions = this.props.values.brightness;
    let changedOption = brightnessOptions[index];
    changedOption.opacity = value[0];
    this.props.handleBrightnessOption(changedOption, index);
  }

  handleChangeIfPositive = (field, e) => {
    let regEx = /^[+]?([0-9]+(?:[0-9]*)?|\.[0-9]+)$/;
    if(e.target.value.match(regEx) && e.target.value > 0){
        this.props.handleChange(field, e);
    }else{
        this.props.showNotification({
            text: this.props.t('sections.content.inputOnlyAcceptNumbers'),
            type: "error",
            duration:3000
        });
    }
  }

  render() {
    const handleLocation = this.props.handleLocation;
    const handleNumber = this.props.handleNumber;
    const { total_spots, ad_duration, auto_launch, schedule, location, allDaysSwitchStatus, brightness } = this.props.values;
    const filterCSS = (opacity) => {return {filter: `brightness(${opacity/100})`}}

    return (
      <div className="w-full p-5">
        <h1 className="text-gray-800 text-xl font-bold">{this.props.t('sections.wizard.stepTwo.stepTitle')}  <span className="font-light text-sm text-gray-700"> - {this.props.t('sections.wizard.stepTwo.stepTitleExtra')}</span></h1>
        <hr />
        <div className="my-2 w-3/12">
          <label className="firstInp block text-gray-700 text-sm font-bold mb-2">
            <FontAwesomeIcon fixedWidth icon={['fad', 'th-list']} className="mr-4" />
            {this.props.t('sections.wizard.stepTwo.spotsInLoop-label')}*
          </label>
          <input type="text"
            className="shadow appearance-none border rounded w-full mb-4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring"
            tabIndex="1"
            onChange={handleNumber('total_spots')}
            value={total_spots}
            placeholder={this.props.t('sections.wizard.stepTwo.spotsInLoop-placeholder')} />
        </div>

        <div className="my-2 w-3/12">
          <label className="sndInp block text-gray-700 text-sm font-bold mb-2">
            <FontAwesomeIcon fixedWidth icon={['fad', 'clock']} className="mr-4" />
            {this.props.t('sections.wizard.stepTwo.spotsDuration-label')} * <span className="font-light text-gray-500">{this.props.t('sections.wizard.stepTwo.inSeconds-helper')}</span>
          </label>
          <input type="number"
            className="shadow appearance-none border rounded w-full mb-4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring"
            tabIndex="2"
            onChange={(e) => this.handleChangeIfPositive('ad_duration', e)}
            value={ad_duration}
            placeholder={this.props.t('sections.wizard.stepTwo.spotsDuration-placeholder')} />
        </div>
        
        <div className="my-2 w-auto">
          <label>
              {this.props.t("sections.wizard.stepTwo.autoLaunch.title")}
              <ReactTooltip effect="solid" className="w-48 p-2" id="autoLaunchInfo" aria-haspopup='true'>
                  <div className="mb-5">
                      {this.props.t('sections.wizard.stepTwo.autoLaunch.tooltip')}<br/>
                  </div>
              </ReactTooltip>
              <FontAwesomeIcon data-tip data-for='autoLaunchInfo' icon={["fas", "info-circle"]} className="ml-2 text-sm outline-none" />
          </label>  
          <div className="flex-nowrap flex flex-start">
          <ButtonSwitcher handleVisibility={this.props.setAutoLaunch} status={auto_launch} labelOn={this.props.t('sections.wizard.stepTwo.autoLaunch.on')} labelOff={this.props.t('sections.wizard.stepTwo.autoLaunch.off')}  revertLabel={true} />         
          </div>
        </div>

        <div className="my-2">
          <div className="relative flex justift-normal">
            <div className="schStr block text-gray-700 text-sm font-bold mb-2">{this.props.t('sections.wizard.stepTwo.availableHours')} * </div>
            <div><ButtonSwitcher handleVisibility={this.props.handleDaysSwitch} status={allDaysSwitchStatus} labelOn={this.props.t('sections.wizard.stepTwo.allDays')} labelOff={this.props.t('sections.wizard.stepTwo.dayByDay')} /></div>
          </div>
          {allDaysSwitchStatus
            ?
            <div className="text-gray-700">
              <label>{this.props.t('common.buttons.from')}</label>
              <input tabIndex="3" className="inline-block outline-none mx-2" value={schedule[0].start} data-timelapse="start" type="time" onChange={this.handleAllDay} />
              <label>{this.props.t('common.buttons.to')}</label>
              <input tabIndex="4" className="inline-block outline-none ml-2" value={schedule[0].end} data-timelapse="end" type="time" onChange={this.handleAllDay} />
            </div>
            :
            (schedule.map((schedule, index) => (
              <div key={index} className="flex my-2">
                <div className="flex text-gray-700 w-20">
                  <span>{schedule.day}</span>
                </div>
                <div className="mr-3">
                  <input tabIndex="3" className="dayInput inline-block outline-none mr-2" value={schedule.start} data-day={schedule.day} data-index={index} name="start" size="5" type="time" placeholder="00:00" onChange={this.handleSingleDay} />
                  <input tabIndex="4" className="dayInput inline-block outline-none" value={schedule.end} data-index={index} name="end" size="5" type="time" placeholder="00:00" onChange={this.handleSingleDay} />
                </div>
                {(schedule.start !== '' || schedule.end !== '') &&
                  <FontAwesomeIcon onClick={this.clearDay} data-dayindex={index} className="block my-auto mr-3" color="gray" icon="times" size="1x" />
                }
                {(index === 0 && schedule.start !== '' && schedule.end !== '') &&
                  <button onClick={this.cloneDay} type="button" className="flex px-2 border border-blue-500 text-blue-500 rounded-md transition duration-500 ease select-none hover:text-white hover:bg-blue-600 focus:outline-none focus:focus:ring">
                    <span className="mr-2">{this.props.t('common.buttons.cloneDay')}</span>
                    <FontAwesomeIcon className="block my-auto" color="gray" icon="arrow-down" size="1x" />
                  </button>
                }
              </div>
            )))
          }
        </div>
        <label className='text-gray-700 text-sm font-bold'>{this.props.t('sections.wizard.stepFive.brightnessModal.title')}</label>
        <div className='flex'>
            {brightness?.map((option, index) => {
                return (
                    <div className='my-1 mr-1 w-1/4 bg-white p-2 rounded-lg text-gray-900 font-semibold text-sm shadow flex'>
                        <div className='w-3/4'>
                            <div className='flex justify-between mb-1'>
                                <div className='flex flex-col w-3/4 pr-1'>
                                  <label className='font-bold'>{this.props.t('sections.wizard.stepFive.brightnessRange')}:</label>
                                  <div className='flex justify-between'>
                                    <label className='ml-1'><span className='font-bold'>{this.props.t('sections.wizard.stepFive.brightnessStart')}</span>: {option.start}hs</label>
                                    <label className='ml-2'><span className='font-bold'>{this.props.t('sections.wizard.stepFive.brightnessEnd')}</span>: {option.end}hs</label>
                                  </div>
                                </div>
                                <div className='w-1/4 flex justify-end'>
                                    <button onClick={() => this.editBrightnessOption(option, index)}><FontAwesomeIcon icon={['fas', 'pen']} className="text-blue-400 mr-2" /></button>
                                    <button onClick={() => this.deleteBrightnessOption(index)}><FontAwesomeIcon icon={['fas', 'trash']} className="text-red-400" /></button>
                                </div>
                            </div>
                            <div className='flex flex-col mt-2'>
                                <label><span className='font-bold'>{this.props.t('sections.wizard.stepFive.brightness')}</span>:</label>
                                <div className='flex items-center mt-1'>
                                  <Slider
                                      range
                                      value={option.opacity}
                                      max={100}
                                      onChange={(e) => this.handleBrightnessOptionFromCard(e, index)}
                                  />
                                  <span className='pl-2 font-bold'>{option.opacity}%</span>
                                </div>
                            </div>
                        </div>
                        <div className='w-24 h-24 ml-3 flex items-center rounded-full bg-black'>{/* ${window.Config.REACT_APP_WL_NAME} */}
                            <img className='p-0 w-3/4 m-auto' src={require(`../../images/${window.Config.REACT_APP_WL_NAME}/iso-blue.svg`)} style={filterCSS(option?.opacity)} alt={window.Config.REACT_APP_WL_NAME} />
                        </div>
                    </div>
                )
            })}
            <div className="my-2 w-auto">
                <button className='buttonPrimary' onClick={() => {this.openModal('BrightnessControlModal')}}>+</button>
            </div>
        </div>
        <div className="my-2 ">
          <label className="trdInp block text-gray-700 text-sm font-bold mb-2">{this.props.t('sections.wizard.stepTwo.location')} * </label>
          <button className="buttonPrimary"
            tabIndex="5" onClick={() => { this.toggleModalMap(); this.eventAddMap(); }}>
            <span className="mr-2">{this.props.t('sections.wizard.stepTwo.buttonMap')}</span>
            <FontAwesomeIcon icon={["fal", "map-marker-alt"]} size="1x" />
          </button>
        </div>
        <MapModal
          toggleModalMap={this.toggleModalMap}
          handleLocation={handleLocation}
          location={location}
        />
        <div>
          <button className="buttonSecondary"
            tabIndex="7"
            onClick={this.back}>{this.props.t('common.buttons.back')}</button>
          <button id="stepTwoContinue" className="buttonPrimary"
            tabIndex="6"
            onClick={this.continue}>{this.props.t('common.buttons.next')}</button>
        </div>
        <BrightnessControlModal modalID="BrightnessControlModal" title={this.props.t('sections.wizard.stepFive.brightnessModal.title')} buttonText={this.props.t('common.buttons.add')} callbackFunction={this.handleBrightnessOption} selectedOption={this.state?.selectedBrightnessOption}/>
      </div>
    );
  }
}

export default withTranslation()(StepTwo);