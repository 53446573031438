import React, { Component } from 'react'
import Select, { components } from 'react-select'
import { withTranslation } from 'react-i18next'
import cloneDeep from 'lodash.clonedeep';
import * as dotenv from 'dotenv';
import isEqual from 'lodash.isequal';
import ButtonSwitcher from '../../buttons/ButtonSwitcher';
import GroupedSelect from '../../utils/GroupedSelect/GroupedSelect';
import CustomContentOption from '../../utils/GroupedSelect/CustomComponents/CustomContentOption';
import CustomScreenOption from '../../utils/GroupedSelect/CustomComponents/CustomScreenOption';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';

dotenv.config();

class Step2 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            event: {},
            selectedScreen: [],
            selectedCustomer: [],
            selectedBrand: [],
            selectedContent: [],
            contentToShow: []
        }
    }
    componentDidMount() {
        const { event, screens, screenGroups, customers } = this.props
        let groupedScreens = [
            {
                label: this.props.t('sections.wizard.stepOne.group-label'),
                value: 1,
                options: screenGroups
            },
            {
                label: this.props.t('sections.content.screen'),
                value: 2,
                options: screens
            }
        ]
        this.setState({
            event: event,
            screensData: groupedScreens,
            defaultScreensData: groupedScreens,
            customersData: customers,
        }, () => {
            let selectedScreen = [];
            if (event && event.screen) {
                let eventScreens = this.selectScreenOptions().filter(s => s.options.find(o => event.screen.includes(o.value) || event.screen.find(screen => screen.value === o.value)))
                eventScreens.forEach(element => {
                    element.options.forEach(option => {
                        if(event.screen.includes(option.value) || event.screen.find(screen => screen.value === option.value)){
                            selectedScreen.push(option)
                        }
                    })
                });
            }
            this.setState({
                selectedScreen: selectedScreen,
                selectedCustomer: this.selectCustomerOptions().filter(s => event.customer?.includes(s.value)),
                selectedBrand: this.selectBrandOptions().filter(s => event.brand?.includes(s.value)),
                selectedContent: typeof event.content === typeof "" ? event.content : this.selectContentOptions().filter(s => event.content?.includes(s.value)),
            })
        })
    }
    componentDidUpdate(prevProps, prevState) {
        if (!isEqual(prevProps.event, this.props.event) || !isEqual(prevState.event, this.state.event)) {
            let event = cloneDeep(this.props.event)
            let screen = [], customer = [], brand = [], content = [];
            screen = event.screen;
            content = this.selectContentOptions().filter(s => event.content?.includes(s.value))
            if (event.customer)
                customer = this.selectCustomerOptions().filter(s => event.customer.includes(s.value))
            if (event.brand)
                brand = this.selectBrandOptions().filter(s => event.brand.includes(s.value))
            this.setState({
                event: event,
                selectedScreen: screen,
                selectedCustomer: customer,
                selectedBrand: brand,
                selectedContent: content,
            })
        }
    }
    /**
     * Function that returns a parsed object for the react-select component
     * @returns Object composed of label, value and options
     */
    selectScreenOptions = () => this.state.screensData?.map(c => {
        return {
            label: c.label,
            value: c.value,
            options: this.parseGroupedOptions(c.options)
        }
    })
    /**
     * Function that parse given array of options to satisfy the react-select format
     * @param {Array} options
     * @returns array of parsed options. Composed of label and value
     */
    parseGroupedOptions = (options) => {
        return options.map(c => {
            return {
                label: c.name,
                value: c._id,
            }
        })
    }
    /**
     * Function that parse an array to satisfy the react-select format
     * @returns array of parsed options. Composed of label and value
     */
    selectCustomerOptions = () => this.state.customersData?.map(c => {
        return {
            label: c.contact_name,
            value: c._id
        }
    })
    /**
     * Function that parse an array to satisfy the react-select format
     * @returns array of parsed options. Composed of label and value
     */
    selectBrandOptions = () => this.props.brands?.map(c => {
        return {
            label: c.name,
            value: c._id
        }
    })
    /**
     * Function that parse an array to satisfy the react-select format
     * @returns array of parsed options. Composed of label and value
     */
    selectContentOptions = () => {
        let contents = this.props.contents;
        let { event } = this.state;
        if(event.programmaticExclusivity){
            contents = contents.filter(content => !content.isCreativeCarrousel);
        }
        return contents?.map(c => {
            return {
                label: c.name,
                value: c._id
            }
        })
    }
    /**
     * Function that handles the changes of the selected screens, filtering the options on whether a group or a screen is selected
     * Also sets the screens from event to the selected ones
     * @param {Array} selected
     */
    handleSelectScreen = (selected) => {
        let prevScreensData;
        if (selected === null) {
            selected = []
            prevScreensData = this.state.defaultScreensData;
        }else{
            selected.forEach(sel => {
                let filteredOptions;
                if(this.props.screenGroups.find(g => g._id === sel.value)){
                    let screens = this.props.screens;
                    let selectedScreens = screens.filter(s => s.group === sel.value);
                    filteredOptions = this.state.screensData.map(data => {
                        return {
                            label: data.label,
                            value: data.value,
                            options: data.options.filter(o =>
                                        !selectedScreens.find(s => s._id === o._id)
                                    )
                        }
                    })
                }else if(this.props.screens.find(s => s._id === sel.value)){
                    let groups = this.props.screenGroups;
                    let screensByGroup = groups.map(g => {
                        return {
                            group: g._id,
                            screens: this.props.screens.filter(s => {
                                return s.group === g._id;
                            })
                        }
                    });
                    let selectedScreenOfGroup = screensByGroup.filter(s => {
                        return s.screens.find(screen => screen._id === sel.value)
                    })[0];
                    let selectedScreens = this.props.screens.map(screen => selected.find(s => s.value === screen._id)).filter(screen => screen !== undefined)
                    if(selectedScreenOfGroup && selectedScreenOfGroup.screens.every(s => selectedScreens.find(screen => screen.value === s._id))){
                        filteredOptions = this.state.screensData.map(data => {
                            return {
                                label: data.label,
                                value: data.value,
                                options: data.options.filter(o =>
                                            selectedScreenOfGroup.group !== o._id
                                        )
                            }
                        });
                    }else{
                        filteredOptions = this.state.defaultScreensData.map(data => {
                            return {
                                label: data.label,
                                value: data.value,
                                options: data.options.filter(o =>
                                            !selectedScreens.find(s => s._id === o._id)
                                        )
                            }
                        })
                    }
                }else{
                    if(this.state.prevScreensData)
                        filteredOptions = this.state.prevScreensData;
                    else
                        filteredOptions = this.state.screensData;
                }
                this.setState({
                    prevScreensData: this.state.screensData,
                    screensData: filteredOptions,
                })
            })
        }
        this.setState({
            selectedScreen: selected,
            eventScreens: this.parseSelected(selected)
        }, () => {
            if(prevScreensData)
                this.setState({
                    prevScreensData: null,
                    screensData: prevScreensData
                })
        })
    }
    /**
     * Parse the array of selected screens to fit with the event screens format
     * @param {Array} selected
     * @returns array of screen ids
     */
    parseSelected = (selected) => {
        let parsedSelected = [];
        selected.forEach(sel => {
            if(this.props.screenGroups.find(g => g._id === sel.value)){
                let screens = this.props.screens;
                let selectedScreens = screens.filter(s => s.group === sel.value);
                selectedScreens.forEach(screen => parsedSelected.push(screen._id));
            }else{
                parsedSelected.push(sel.value)
            }
        });
        return parsedSelected;
    }
    /**
     * Handles the change in brand select and saves it in state
     * @param {Array} selected
     */
    handleSelectBrand = (selected) => {
        if (selected === null) {
            selected = []
        }
        this.setState({
            selectedBrand: selected
        })
    }
    /**
     * Handles the change in customer select and saves it in state
     * @param {Array} selected
     */
    handleSelectCustomer = (selected) => {
        if (selected === null) {
            selected = []
        }
        this.setState({
            selectedCustomer: selected
        }/* , () => {
            if(this.state.selectedCustomer.value === '-1') {
                this.setState({
                    selectContent: [],
                    programmaticExclusivity: true,
                })
            } else {
                this.setState({
                    selectContent: [],
                    programmaticExclusivity: false,
                })
            }
        } */)
    }
    /**
     * Function that handles the change in case selected content needs to be a programmatic URL
     * @param {*} e
     */
    handleProgramaticEventContent = (e) => {
        let selected = e.target.value
        if (selected === null) {
            selected = []
        }
        this.setState({
            selectedContent: selected
        })
    }
    /**
     * Handles the changes of selected content and saves it in state
     * @param {Array} selected
     */
    handleSelectContent = (selected) => {
        let { event } = this.state;
        if (selected === null) {
            selected = []
        }else if(event.programmaticExclusivity && selected.length > 1){
            selected = this.state.selectedContent;
            this.props.showNotification({
                type: 'error',
                text: 'Programmatic exclusive events can only contain 1 or less backfill content'
            })
        }
        this.setState({
            selectedContent: selected
        })
    }
    /**
     * Function thar set the confirmed event property in true
     */
    functionToConfirm = () => {
        let event = cloneDeep(this.state.event);
        event.confirmed = true;
        this.setState({
            event: event
        }, () => {
            this.props.callFunctionAndClose(event);
        });
    }
    /**
     * !Not used today
     * Function that swaps programmaticExclusivity in state
     */
    handleInputForExternalContent = () => {
        let {event} = this.state;
        event.programmaticExclusivity = !event.programmaticExclusivity;
        this.setState({
            event
         })
    }
    /**
     * Function that check if mandatory event properties are fullfiled and trigger a callback function
     * @returns in case of a trouble if there is a missing propertry of event
     */
    nextStep = () => {
        if(isEqual(this.state.selectedScreen, []) || this.state.selectedScreen === undefined) {
            this.props.showNotification({
                type: "warning",
                text: this.props.t('common.notification.calendar.emptyScreen')
            });
            return;
        }
        if((isEqual(this.state.selectedContent, []) || this.state.selectedContent === undefined) && !this.state.event.programmaticExclusivity ) {
            this.props.showNotification({
                type: "warning",
                text: this.props.t('common.notification.calendar.emptyContent')
            });
            return;
        }
        let eventObj = cloneDeep(this.state.event);
        let content = this.state.selectedContent;
        eventObj.screen = this.state.eventScreens || this.state.selectedScreen;
        eventObj.content = !this.state.programmaticExclusivity ? content.map(s => s.value) : content;
        eventObj.brand = this.state.selectedBrand.value || [];
        eventObj.customer = this.state.selectedCustomer.value || [];
        this.props.nextStep(eventObj);
    }
    render() {
        let {event} = this.state;
        /**
         * Const function that render react-select options in certain way
         * In this case shows a non selectable label to divide options
         * @param {*} props
         * @returns custom option
         */
        const customScreenOption = props => {
            return (
                <components.Option {...props}>
                    <CustomScreenOption props={props} />
                </components.Option>
            )
        }
        /**
         * Const function that render react-select options in certain way
         * In this case modify the options to show a snapshot of the content in left side
         * @param {*} props
         * @returns custom option
         */
        const CustomOption = props => {
            let filteredOption = this.props.contents.filter(c => c._id === props.value);
            return (
                <components.Option {...props}>
                    <CustomContentOption content={filteredOption} props={props}/>
                </components.Option>
            );
        };
        return (
            <div className="stepOne">
                <label for="selected" className="flex flex-col uppercase tracking-wide text-gray-600 text-xs font-bold mb-2 mt-3">
                    {this.props.t('sections.calendar.wizard.step2.screens')}:
                    <GroupedSelect
                        getGroupedOptions={this.selectScreenOptions}
                        selected={this.state.selectedScreen}
                        customComponents={{Option: customScreenOption}}
                        changeCallback={this.handleSelectScreen}
                        extraProps={{isMulti: "true", closeMenuOnSelect: "false", maxMenuHeight: 200}}
                    ></GroupedSelect>
                </label>
                <div className='flex w-full'>
                    <label for="selected" className="flex flex-col w-1/2 p-1 uppercase tracking-wide text-gray-600 text-xs font-bold mb-2 mt-3" >
                        {this.props.t('sections.calendar.wizard.step2.customer')}:
                        <Select
                            className="w-auto mt-1"
                            name="new-event-customer"
                            onChange={this.handleSelectCustomer}
                            options={this.selectCustomerOptions() || []}
                            maxMenuHeight={200}
                            value={this.state.selectedCustomer}
                        />
                    </label>
                    <label for="selected" className="flex flex-col w-1/2 p-1 uppercase tracking-wide text-gray-600 text-xs font-bold mb-2 mt-3">
                        {this.props.t('sections.calendar.wizard.step2.brand')}:
                        <Select
                            className="w-auto mt-1"
                            name="new-event-brand"
                            onChange={this.handleSelectBrand}
                            options={this.selectBrandOptions() || []}
                            maxMenuHeight={200}
                            value={this.state.selectedBrand}
                        />
                    </label>
                </div>
                <div className='flex w-full'>
                    <label htmlFor="external-content" className="flex flex-col w-2/4 p-1 justify-start uppercase tracking-wide text-gray-600 text-xs font-bold mb-2 mt-5">
                        {this.props.t('sections.calendar.wizard.step2.programmaticExclusivity')}
                        <ButtonSwitcher className="my-2" handleVisibility={this.handleInputForExternalContent} status={event.programmaticExclusivity} labelOn={this.props.t('common.buttons.yes')} labelOff={this.props.t('common.buttons.no')}></ButtonSwitcher>
                    </label>
                    <div for="selected" className={`flex flex-col w-full p-1 uppercase tracking-wide text-gray-600 text-xs font-bold mb-2 mt-3`}>
                        {event.programmaticExclusivity ?
                            <>
                                <ReactTooltip effect="solid" className="p-2 text-center font-light normal-case" id={'programmaticExclusivity'} aria-haspopup='true'>
                                    {this.props.t('sections.calendar.wizard.step2.backfillContentExplanation')}
                                </ReactTooltip>
                                <span>
                                    {this.props.t('sections.calendar.wizard.step2.backfillContent')} {event.programmaticExclusivity && <FontAwesomeIcon icon={['fas', 'question-circle']} className="text-gray-400" data-tip data-for="programmaticExclusivity"/>}:
                                </span>
                            </>
                            :
                            <span>
                                {this.props.t('sections.calendar.wizard.step2.content')}:
                            </span>
                        }
                        <>
                            <GroupedSelect
                                getGroupedOptions={this.selectContentOptions}
                                selected={this.state.selectedContent}
                                customComponents={{Option: CustomOption}}
                                changeCallback={this.handleSelectContent}
                                extraProps={{isMulti: "true", closeMenuOnSelect: "false", maxMenuHeight: 200}}
                            ></GroupedSelect>
                        </>
                    </div>
                </div>
                <div className="flex justify-between mt-3">
                    <button onClick={this.props.prevStep} className="buttonSecondary">{this.props.t('common.buttons.back')}</button>
                    <button onClick={this.nextStep} className="buttonPrimary">{this.props.t('common.buttons.next')}</button>
                </div>
            </div>
        )
    }
}
export default withTranslation()(Step2);
