import React, { Component } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';

class GroupedSelect extends Component {
    constructor(){
        super();
        this.state = {
            options: [],
            selected: []
        }
    }

    componentDidMount = () => {
        let selected = [], options = [];
        if(!isEqual(this.props.selected, [])){
            selected = this.props.selected;
        }
        if(!isEqual(this.props.getGroupedOptions(), [])){
            options = this.props.getGroupedOptions();
        }
        this.setState({
            selected,
            options
        })
    }

    componentDidUpdate = (prevProps) => {
        if(!isEqual(prevProps, this.props)){
            let options = this.props.getGroupedOptions();
            this.setState({
                options,
                selected: this.props.selected
            })
        }
        if(this.props.getGroupedOptions()!== undefined && !isEqual(this.state.options,this.props.getGroupedOptions())){
            this.setState({options:this.props.getGroupedOptions()})
        }
    }

    /**
     * Function that handles the way the component gets the options for the Select
     * @returns Array of obj composed by label, value and options
     */
    handleGetGroupedOptions = () => {
        let options = cloneDeep(this.state.options);
        return options;
    }

    /**
     * Function that returns the default or selected value for the Select
     * @returns Array of selected values
     */
    handleGetValue = () => {
        let selected = cloneDeep(this.state.selected);
        return selected;
    }

    /**
     * Function that handles the changes in Select and triggers a function given by props
     * @param {Array} selected
     */
    handleChangeSelectedValue = (selected) => {
        this.props.changeCallback(selected);
    }

    // { Option: customOption, MenuList: customMenu }

    getCustomComponents = () => {
        let customComponents = this.props.customComponents || {};
        return customComponents;
    }

    render() {
        return (
            <Select
                className={`w-auto`}
                options={this.handleGetGroupedOptions()}
                value={this.handleGetValue()}
                components={this.getCustomComponents()}
                onChange={this.handleChangeSelectedValue}
                {...this.props.extraProps}
            />
        );
    }
}
GroupedSelect.propTypes = {
    getGroupedOptions: PropTypes.func,
    selected: PropTypes.array,
    customComponent: PropTypes.func,
    changeCallback: PropTypes.func,
    extraProps: PropTypes.object
};
export default GroupedSelect;
