import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Loading() {
    const styles = {
        background: 'rgb(0, 0, 0, 0.15)'
    }

    return (
        <div className="absolute z-50 h-full w-full top-0 left-0 flex justify-center items-center" style={styles}>
            <FontAwesomeIcon icon={["fal", "circle-notch"]} size="6x" fixedWidth className={`p-2 mx-1 animate-spin text-blue-400`} />
        </div>
    )
}

export default Loading;
