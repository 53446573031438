import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


const CardOOH = (p) => {
    
    const imageExtensions = ['png', 'jpg', 'jpeg'];
    const { props } = p;
    let { title,  onClick, issueID } = props;
    let { final_height, final_width, material, visible_width, visible_height, path, schedule } = props.content;

    return (

        <div className='' onClick={onClick}>
            <div className='flex flex-col'>
                <div className="w-full flex justify-between">
                    <span className="text-xs">
                        <span>OOH </span> - {issueID}
                    </span>
                    {/* <span className="text-xs">{" "+parseDateIntoReadableString(new Date(date), "-")}</span> */}
                    <span className="text-xs">
                        <span>{schedule.startDate}</span>
                    </span>
                </div>
                <div className="w-auto">
                    <p className=" w-auto font-bold tracking-tight normal-case  overflow-hidden text-ellipsis">{title}</p>
                </div>
                {/* {showDeleteButton && <div className='cursor-pointer' onClick={onDelete} >x</div>} */}
            </div>
            <div>
                {path &&
                    <div >
                        {imageExtensions.includes(path.split(".")[1]) &&
                            <> <img data-tip className="rounded" src={`${window.Config.REACT_APP_FILESERVER_URL}/${path}`} alt={path}/></>
                        }
                    </div>
                }
            </div>
            <div className="flex flex-col  mt-2 mb-2 ">
                <div className='flex justify-between w-full'>
                    <div >
                        <FontAwesomeIcon icon={['fal', 'expand-wide']} className="mr-2 text-blue-400   " />
                        <span className="text-sm font-semibold text-opacity-5 antialiased ">Medida Visible:</span>
                    </div> 
                    <div >
                        <span className="text-sm font-semibold antialiased ">{ visible_width }m   x   {visible_height }m</span>
                    </div>
                </div>
                <div className='flex justify-between w-full' data-tip data-for={'final-sizes'} effect="solid" aria-haspopup='true'>
                    <div>
                        <FontAwesomeIcon icon={['fal', 'ruler-combined']} className=" mr-2 text-blue-400" />
                        <span className="text-sm font-semibold">Medida Final:</span>
                    </div>
                    <div >
                        {/* <span className="text-sm font-semibold">{" " + final_width + "m" + " x " + final_height + "m"}</span> */}
                        <span className="text-sm font-semibold  ">{ final_width }m   x   {final_height }m</span>
                    </div>
                </div>
                <div className='flex w-full justify-between'>
                    <div>
                        <FontAwesomeIcon icon={['fas', 'brush']} className="mr-3 mt-1 text-blue-400 " />
                        <span className="text-sm not-italic font-semibold">Material:</span>
                    </div>
                    <div>
                        <span className="text-sm capitalize font-semibold">{material}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CardOOH;