import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function FilePreviewItem({ files, setFiles }) {
    const { t } = useTranslation();

    const [extraData, setExtraData] = useState([]);
    const [previews, setPreviews] = useState([]);

    useEffect(() => {
        handleFiles();
    }, [files]);

    const handleFiles = () => {
        const img_ext = /(jpg|jpeg|png|gif)$/i;
        const video_ext = /(mp4|ogg|webm)$/i;
        const zip_ext = /(x-zip-compressed)$/i;
        const arrayOfZipExt = ["rar", "zip"];
        const arrayExtToConvert = ["mpg", "mpg-2", "mpg-3", "mpg-4"];

        const loadingPreview = (
            <div key="loading">{t("common.buttons.loading")}...</div>
        );

        setPreviews([loadingPreview]);

        const newPreviews = [];
        const newExtraData = [];

        files.forEach((file, index) => {
            const typeArray = file.type.split("/");

            if (img_ext.test(typeArray)) {
                handleImage(file, newPreviews, newExtraData);
            } else if (
                zip_ext.test(typeArray) ||
                arrayOfZipExt.includes(file.name.split(".")[1])
            ) {
                handleZip(file, newPreviews);
            } else if (arrayExtToConvert.includes(file.name.split(".")[1])) {
                handleMpg(file, newPreviews);
            } else if (video_ext.test(typeArray)) {
                handleVideo(file, newPreviews, newExtraData);
            } else {
                handleError(file, newPreviews, newExtraData);
            }
        });

        setPreviews(newPreviews);
        setExtraData(newExtraData);
    };

    const handleRemove = (index) => {
        const newFiles = files.filter((_, i) => i !== index);
        setFiles(newFiles);
    };

    const handleImage = (file, newPreviews, newExtraData) => {
        const imgSrc = URL.createObjectURL(file);
        const imgPreview = (
            <img
                key={file.name}
                alt="Image Preview"
                width={200}
                height={200}
                className="rounded object-fill m-1"
                src={imgSrc}
            />
        );

        newPreviews.push(imgPreview);

        const newImg = new Image();
        newImg.src = imgSrc;
        newImg.onload = () => {
            const imgData = {
                name: file.name.toString(),
                width: newImg.width,
                height: newImg.height,
            };
            newExtraData.push(imgData);
            setExtraData([...newExtraData]);
        };
    };

    const handleZip = (file, newPreviews) => {
        const imgPreview = (
            <img
                key={file.name}
                alt="HTML Preview"
                width={200}
                height={200}
                className="rounded object-fill m-1"
                src={require(`../../images/common/html.png`)}
            />
        );
        newPreviews.push(imgPreview);
    };

    const handleMpg = (file, newPreviews) => {
        const imgPreview = (
            <img
                key={file.name}
                alt="MPG Preview"
                width={200}
                height={200}
                className="rounded object-fill m-1"
                src={require(`../../images/common/mpg-file.png`)}
            />
        );
        newPreviews.push(imgPreview);
    };

    const handleVideo = (file, newPreviews, newExtraData) => {
        const videoSrc = URL.createObjectURL(file);
        const videoPreview = (
            <video
                key={file.name}
                alt="Video Preview"
                width={400}
                height={400}
                className="rounded object-fill m-1"
                muted
                src={videoSrc}
            />
        );

        newPreviews.push(videoPreview);

        const newVideo = document.createElement("video");
        newVideo.src = videoSrc;
        newVideo.onloadedmetadata = () => {
            const vidData = {
                name: file.name,
                width: newVideo.videoWidth,
                height: newVideo.videoHeight,
            };
            newExtraData.push(vidData);
            setExtraData([...newExtraData]);
        };
    };

    const handleError = (file, newPreviews, newExtraData) => {
        const fileNotAllowedPreview = (
            <img
                key={file.name}
                width={200}
                height={200}
                className="rounded object-fill m-1"
                src={require(`../../images/common/file-not-allowed.png`)}
            />
        );

        newPreviews.push(fileNotAllowedPreview);

        const errData = {
            name: file.name,
            error: true,
        };

        newExtraData.push(errData);
        setExtraData([...newExtraData]);
    };

    return (
        <div
            id="div_img_preview"
            className="grid-flow-* grid grid-cols-3 grid-rows-auto gap-2 overflow-y-scroll m-4 p-2"
        >
            {previews.map((item, index) => {
                return (
                    <div key={index} className="relative">
                        {item}
                        <button
                            onClick={() => handleRemove(index)}
                            className="absolute top-2 right-0 bg-gray-500 text-white font-bold rounded px-1 py-0 border border-solid border-black text-center"
                        >
                            x
                        </button>
                    </div>
                );
            })}
        </div>
    );
}
