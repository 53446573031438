import React, { Component } from 'react';
import { updateManyContent } from './contentAJAXFunctions';
import DecisionModal from '../utils/DecisionModal';
import ScreensModal from '../utils/ScreensModal';
import CarrouselCreativeModal from '../utils/CarrouselCreativeModal';
import ReactTooltip from 'react-tooltip';
import { checkLimitExceeded } from './contentAJAXFunctions';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select, { createFilter } from 'react-select';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import "./style.css"
import { isEqual, cloneDeep } from 'lodash';

class ContentList extends Component {
    constructor() {
        super();
        this.state = {
            loading: true,
            data: [],
            columns: [],
            dataScreen: [],
            contentIDsForDelete: [],
            screenSelected: [],
            toggleClearRows: false,
            screensModalOfRow: [],
            carrouselCreative: [],
            isBeingEdited: false,
            contentBeingEdited: {},
            items: []
        }
    }
    /**
     * It returns a p with a the type of the content
     *
     * @param content content where the type is comming from.
     * @return a p tag with the content type.
     */
    getTypeOfContentInP = (content) => {
        if (content.isCreativeCarrousel) {
            if (content.daily_limit) {
                return <p>{this.props.t('sections.content.carousel') + ` (${content.impressions_in_day}/${content.daily_limit})`}</p>;
            } else {
                return <p>{this.props.t('sections.content.carousel')}</p>;
            }
        } else {
            if (content.daily_limit) {
                return <p>{content.path.split('.')[1] + ` (${content.impressions_in_day}/${content.daily_limit})`}</p>;
            } else {
                return <p>{content.path.split('.')[1]}</p>;
            }
        }
    }
   /**
    * return an array of columns for the datatable
    * @returns col[]
    */
    getColumns=()=>{
        const imageExtensions = ['png', 'jpg', 'jpeg', 'gif'];
        const videoExtensions = ['mp4', 'ogg', 'webm'];
        let col = [
            {
                name: this.props.t('sections.content.name'),
                selector: row => row.name,
                sortable: true,
                width: "25%"

            },
            {
                name: this.props.t('sections.content.preview'),
                selector: row => row?.id,
                cell: (row) => <div className="items-center w-20 h-14 rounded-lg bg-black overflow-hidden flex justify-center cursor-pointer">
                    {Array.isArray(row.path) && row.path.length > 2 ?
                        <>
                            {(row.path[0].split(".") && imageExtensions.includes(row.path[0].split(".")[1])) &&
                                <><img onClick={() => this.props.toggleDetail(this.getContentByID(row.id))} alt={row.path[0]} src={window.Config.REACT_APP_FILESERVER_URL + "/" + row.path[0]}></img></>
                            }
                            {(row.path[0].split(".") && videoExtensions.includes(row.path[0].split(".")[1])) &&
                                <><video onClick={() => this.props.toggleDetail(this.getContentByID(row.id))} src={`${window.Config.REACT_APP_FILESERVER_URL}/${row.path[0]}`} alt={row.name} ></video></>
                            }
                            {(row.path[0].split(".") && (row.path[0].split(".")[1] === 'html' || row.path[0].split(".")[1] === 'zip')) ?
                                <><img onClick={() => this.props.toggleDetail(this.getContentByID(row.id))} alt={row.path[0]} src={require("../../images/common/html.png")}></img></>
                                : <></>
                            }
                        </>
                        :
                        <>
                        {typeof row.path === "string" &&
                        <>
                            {imageExtensions.includes(row.path?.split(".")[1]) &&
                                <><img onClick={() => this.props.toggleDetail(this.getContentByID(row.id))} alt={row.path} src={window.Config.REACT_APP_FILESERVER_URL + "/" + row.path}></img></>
                            }
                            {videoExtensions.includes(row.path?.split(".")[1]) &&
                                <><video onClick={() => this.props.toggleDetail(this.getContentByID(row.id))} src={`${window.Config.REACT_APP_FILESERVER_URL}/${row.path}`} alt={row.name} ></video></>
                            }
                            {row.path?.split(".")[1] === 'html' || row.path?.split(".")[1] === 'zip' ?
                                <><img onClick={() => this.props.toggleDetail(this.getContentByID(row.id))} alt={row.path} src={require("../../images/common/html.png")}></img></>
                                : <></>
                            }
                        </>
                        }
                        </>
                    }
                </div>,
                width: "10%"
            },
            {
                name: this.props.t('sections.content.type'),
                selector: row => row.path,
                sortable: true,
                width: "15%",
                hide: 'sm',
                cell: (row) =>
                    <>
                        <div>{this.getTypeOfContentInP(row)}</div>
                    </>
            },
            {
                name: this.props.t('sections.content.size'),
                selector: row => row.width,
                sortable: true,
                width: "10%",
                cell: (row) =>
                    row.width && <div className='flex align-center'><p>{row.width}</p>x<p>{row.height}</p></div>,
                hide: 'sm'
            },
            {
                name: this.props.t('sections.content.screens'),
                selector: row => row.screensLenght,
                sortable: true,
                width: "15%",
                cell: (row) =>
                    <>
                        {row.screens.length === 0 && this.badgeWithTooltip(this.props.t('sections.content.notAsigned'), this.props.t('sections.content.notAsignedTooltip'), row._id, 'gray')}
                        <div className=" h-8 flex truncate ml-1  w-3/5 ">{
                            row.screens.map((screen, i) => (
                                i < 2 &&
                                <label key={i} className="tagLabel text-gray-700  max-w-sm text-center truncate font-semibold ">{screen}</label>
                            ))
                        }
                        </div>
                        {row.screens.length > 2 && <span className="link ml-4" onClick={() => this.openModalScreens(row.screens)}>{this.props.t('sections.content.seeMore')}</span>}
                    </>
            },
            {
                name: this.props.t('sections.content.expirationDate'),
                selector: row => row.schedule.endDate,
                sortable: true,
                cell: (row) => {
                    if (row.schedule.endDate && row.schedule.startDate) {
                        let days = this.getDaysBetween(row)
                        return <div className="w-1/3 flex justify-around ">
                            <ReactTooltip effect="solid" className="p-2" id={"iconOutOfTime" + row.id} aria-haspopup='true'>
                                <p className="text-center max-w-sm">
                                    {days.toFixed(0) === 0 ? this.props.t('sections.content.expiresToday') : days.toFixed(0) + " " + this.props.t('sections.content.daysToExpire')}
                                </p>
                            </ReactTooltip>
                            <ReactTooltip effect="solid" className="p-2" id={"iconExpired" + row.id} aria-haspopup='true'>
                                <p className="text-center max-w-sm">
                                    {this.props.t('sections.content.expiredContent')}
                                </p>
                            </ReactTooltip>
                            {days <= 7 && days > 0 ? <span data-tip data-for={"iconOutOfTime" + row.id} className=" left-1 absolute"><FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="text-yellow-500 text-sm " /></span> : ""}
                            {days <= 0 ? <div data-tip data-for={"iconExpired" + row.id} className="left-1 absolute"><FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="  text-red-500 text-sm" /></div> : ""}
                            {new Date(row.schedule.endDate.replaceAll("-", "/")).toLocaleDateString(undefined)}
                        </div>
                    }
                }
            },
        ]
        return col
    }

    componentDidUpdate = (prevProps) => {
        if (!isEqual(prevProps.screens, this.props.screens) || (!isEqual(prevProps.items, this.props.items))) {
            this.setState({ dataScreen: this.props.screens }, () => {
                this.setState({ items: this.props.items }, () => {
                    let filterData = this.filterResultsTable()
                    let columns = this.getColumns()
                    this.setState({ columns: columns })
                    this.setState({ data: filterData })
                    this.setState({ loading: false })
                })
            })
        }
    }

    componentDidMount = () => {
        if (this.props.items) {
            this.setState({ items: this.props.items }, () => {
                let filterData = this.filterResultsTable();
                let columns = this.getColumns();
                this.setState({ columns: columns });
                this.setState({ data: filterData });
                this.setState({ loading: false });
            })
        }

    }

    /**
     *  A function that takes an id as a parameter and returns the item with the matching id.
     * @param {*} id the id of content
     * @return {obj} item
     * */

    getContentByID = (id) => {
        if (this.state.items.length !== 0) {
            return this.state.items.find(item => item._id === id);
        }
        return null
    }
    /**
     * returns the difference between two dates in a object
     * @param obj
     * @returns number
     */
    getDaysBetween = (row) => {
        let end = new Date(row.schedule.endDate).getTime();
        let today = new Date().getTime();
        let daysBetween = (end - today) / (1000 * 60 * 60 * 24);
        return Number(daysBetween);
    }
    /**
     * if some checkbox is checked, show the select section and save the row id for future handling
     * @param {*} e
     */
    handleChangeSelect = (e) => {
        let contentIDsForDelete = e.selectedRows.map((item) => {
            return item.id
        })
        this.setState({ contentIDsForDelete: contentIDsForDelete })
    };

    /**
     * Setting the state of the screensModalOfRow to the screens parameter and then opening the modal.
     * @param {*}screens [String]
     * */
    openModalScreens = (screens) => {
        this.setState({ screensModalOfRow: screens })
        this.props.openModal('showModalScreens')
    }

    /**  Mapping through the items array and pushing the data thad need into a new array called dataFilter.
     *   this data is will used in the row datatable
     *   @return array of row table
     */
    filterResultsTable = () => {
        let dataFilter = []
        let itemsCopy = cloneDeep(this.state.items);
        itemsCopy.forEach((item) => {
            dataFilter.push({
                id: item._id,
                name: item.name,
                path: item.path,
                width: (item.width && item.height) ? item.width : "",
                height: (item.height) ? item.height : "",
                ext: item.ext,
                daily_limit: item.daily_limit,
                impressions_in_day: item.impressions_in_day,
                screens: this.getFilterScreens(item.screen_id),
                screensLenght: this.getRealQuantityOfScreens(item.screen_id),
                schedule: item.schedule,
                isCreativeCarrousel: (item.isCreativeCarrousel) ? item.isCreativeCarrousel : false,
            })
        })
        return dataFilter
    }

    /**
     * It takes an array of screen ids and returns the number of screens that exist in the database.
     * @param [screens_id] - an array of screen ids
     * @returns The number of screens that are in the props.screens array.
     */
    getRealQuantityOfScreens = (screens_id = []) => {
        let realQuantityOfScreens = 0;
        screens_id.forEach(screen_id => {
            if (this.props.screens.some(propScreen => propScreen._id === screen_id)) {
                realQuantityOfScreens++
            }
        })
        return realQuantityOfScreens
    }
    /**
     *  Taking an array of screenIds and comparing them to the elements of the dataScreen array,
     *  if element exists in both arrays, push to a new array and return again
     *  @param {*} screenIds [array]
     *  @return {*} screenNames [String]
     * */
    getFilterScreens = (screenIds = []) => {
        let screenNames = []
        this.state.dataScreen.forEach(screen => {
            if (screenIds.includes(screen._id)) {
                screenNames.push(screen.name)
            }
        })
        return screenNames
    }

    /**
     *  Deleting the content selected and clear the selects of datatable.
     **/
    deleteContentSelected = (e) => {
        this.props.deleteContent(this.state.contentIDsForDelete)
        this.clearValue()
    }
    /**
     *  Create an array of objects with the @name and @_id from the screen and return it to the select
     * @param {*} screens [array]
     * @returns options[ {label: screen01, value: 111111}]
     */
    getOptionScreens = (screens = []) => {
        let options = []
        screens.forEach(e => {
            let obj = {
                label: e.name,
                value: e._id
            }
            options.push(obj)
        })
        return options
    }

    /**
     * Return a  component tooltip whit a span that have the style and content that pass for parameter
     * @param {*} badge
     * @param {*} tooltip
     * @param {*} id
     * @param {*} color
     * @returns span
     */
    badgeWithTooltip = (badge, tooltip, id, color) => {
        return (
            <>
                <ReactTooltip effect="solid" className="p-2" id={`badgeContent${color}${id}`} aria-haspopup='true'>
                    <p className="text-center  max-w-sm">
                        {tooltip}
                    </p>
                </ReactTooltip>
                <span data-tip data-for={`badgeContent${color}${id}`} className={`ml-2 px-2 py-1 rounded bg-${color}-500 text-white font-semibold  text-center text-xs`}>{badge}</span>
            </>
        );
    }

    handleCarrousel = () => {
        let itemsChecked = this.state.contentIDsForDelete
        let items = this.props.items
        let content = []
        itemsChecked.forEach(itemChecked => {
            content.push(items.find(item => item._id === itemChecked))
        })
        if (content.length !== 0) {
            let contentWithoutCreativeCarousel = content.filter((doc) => doc.contents_id === undefined)
            this.setState({ carrouselCreative: contentWithoutCreativeCarousel })
            this.props.openModal('CarrouselCreativeModal')
        }
        // if(this.state.isBeingEdited){
        //     this.props.toggleisBeingEdited(true)
        // }
    }

    /**
     * assign all the screens selected for the rows checked
     *
     */
    assignContent = () => {
        let itemsChecked = this.state.contentIDsForDelete
        let screensSelected = cloneDeep(this.state.screenSelected)?.map(screen => screen.value)
        let items = cloneDeep(this.state.items)
        let contentToUpdate = []
        if (screensSelected.length !== 0) {
            itemsChecked.forEach(itemChecked => {
                let content = items.find(item => item._id === itemChecked)
                if (content) {
                    screensSelected.forEach(screenId => {
                        if (content.disabled.includes(screenId)) {
                            let index = content.disabled.indexOf(screenId);
                            content.disabled.splice(index, 1);
                        }
                        if (!content.screen_id.includes(screenId)) {
                            content.screen_id.push(screenId)
                            contentToUpdate.push(content)
                        }
                    })
                }
            })
        } else {
            this.props.showNotification({
                text: this.props.t('common.notification.content.assignSomething'),
                type: "warning",
                duration: 3000
            })
            return
        }
        updateManyContent(contentToUpdate)
            .then((res) => {
                if (res.status === 200) {
                    checkLimitExceeded(screensSelected)
                        .then(r => {
                            if (r.data.length > 0) {
                                this.setState({ screensExceeded: r.data }, () => {
                                    let quantityContentMore = 0
                                    r.data.forEach(screenCnt => {
                                        if (screenCnt.quantityContent > screenCnt.quantitySpots) {
                                            quantityContentMore += screenCnt.quantityContent - screenCnt.quantitySpots
                                        }
                                    })
                                    this.props.showNotification({
                                        type: 'warning',
                                        title: this.props.t('common.notification.fileDropper.exceedTitle').replace('_number_', quantityContentMore),
                                        text: this.props.t('common.notification.fileDropper.exceedBody').replace('_number_', r.data.length),
                                    })
                                });
                            } else {
                                this.props.showNotification({
                                    type: 'success',
                                    text: this.props.t('common.notification.screens.contentAssigned'),
                                })
                            }
                        })
                        .catch(err => {
                            this.props.showNotification({
                                type: "error",
                                text: this.props.t('common.notification.serverError')
                            });
                        });
                    this.props.setContent(contentToUpdate)
                    res.data.forEach(doc => {
                        contentToUpdate.forEach(cnt => {
                            if (cnt._id === doc.id) {
                                cnt._rev = doc.rev
                            }
                        })
                    })
                    this.props.updateContents(contentToUpdate)
                    this.clearValue()
                } else {
                    this.props.showNotification({
                        text: this.props.t('common.notification.serverError'),
                        type: "error",
                        duration: 3000
                    })
                }
            }).catch(err => {
                console.log(err)
                this.props.showNotification({
                    text: this.props.t('common.notification.serverError'),
                    type: "error",
                    duration: 3000
                })
            })
    }

    mergeArrays = (arr1, arr2) => {
        return [...new Set([...arr1, ...arr2])]
    }
    /**
     *  a function that is called when a user selects a screen. if the content of select is null the state is reset to []
     * **/
    handleSelect = (e) => {
        if (e) {
            this.setState({ screenSelected: e })
        } else {
            this.setState({ screenSelected: [] })
        }

    }

    /**
     * deselect the entire contents of the reactDataTable
     */
    clearValue = () => {
        if (this.selectRef) {
            this.selectRef.select.clearValue();
        }
        this.setState({ toggleClearRows: true }, () => {
            this.setState({ toggleClearRows: false })
            this.setState({ screenSelected: [] })
            this.setState({ isBeingEdited: false })
            this.setState({ carrouselCreative: [] })
            this.setState({ contentIDsForDelete: [] })
            // this.setState({contentBeingEdited:null})
        })
    };
    /**
     * if selected some content of a reacDataTable it will apear option for the content selected
     */
    showModalAssing = () => {
        let selectedItems = this.state.screenSelected
        if (selectedItems !== '') {
            this.props.openModal('assingContentModalContext')
        } else {
            this.props.showNotification({
                text: this.props.t('common.notification.content.assignSomething'),
                type: "warning",
                duration: 3000
            })
        }
    }
    /**
     * If the length of the array is 0, show a notification. Otherwise, show the modal.
     */
    handleAsingContentModal = () => {
        if (this.state.screenSelected.length === 0) {
            this.props.showNotification({
                text: this.props.t('common.notification.content.assignSomething'),
                type: "warning",
                duration: 3000
            })
        } else {
            this.showModalAssing()
        }
    }
    handleClickInCreativeCarrouselRow = (e) => {
        let content = cloneDeep(this.props.items).find(content => content._id === e.id)
        if (content) {
            this.props.contentBeingEdited(content)
        }
    }
    /**
     * Return the object screens that contain the ids pass per parameter
     * @param {*} ids
     * @returns
     */
    getScreensByID = (ids = []) => {
        return cloneDeep(this.state.dataScreen).filter(screen => ids.includes(screen._id));

    }
    getOptionsPerBreakpoint = (options) => {
        if (window.innerHeight >= 1240) {
            options.shift();
        }
        return options;
    }

    /**
     * Mapping the content array to a new array with the label and value properties.
     * */
    contentOptions = () => {
        let array = [];
        array = this.state.items.map(c => {
            return {
                label: c.name,
                value: c._id
            }
        })
        return array;
    }

    handleSelectContent = (e) => {
        e = e || [] // if(!e){e=[]}
        let contents = cloneDeep(this.state.items)
        let idsSelected = e.map(opt => opt.value)
        let contentSelected = contents.filter(content => idsSelected.includes(content._id))
        this.setState({ carrouselCreative: contentSelected })
    }

    render() {

        const customFilter = createFilter({
            ignoreCase: true,
            ignoreAccents: true,
            trim: true,
            matchFrom: 'start',
        });
        const customStyles = {
            header: {
                style: {
                },
            },
            rows: {
                style: {
                    height: '15%',
                },
            },
            headRow: {
                style: {
                    borderTopStyle: 'solid',
                    borderTopWidth: '1px',
                    Color: '#8f8f8f',
                    display: 'flex',
                    justifyContent: 'start  '
                },
            },
            headCells: {
                style: {
                },
            },
            cells: {
                style: {
                    '&:not(:last-of-type)': {
                        borderRightStyle: 'solid',
                        height: '70px'
                    },
                },
            },
        };

        return (
            <>
                <div
                    onContextMenu={this.rightClick}
                    className={
                        "relative content-list h-full overflow-y-hidden transition-all duration-300 w-full"
                    }
                >
                    <div className="content-header">
                        {this.props.t("sections.content.contentList.listTitle")}
                        <p className="text-sm mb-2">
                            <span className="text-gray-500 font-light">
                                {this.props.t(
                                    "common.modals.fileDropper.dropFiles"
                                )}
                            </span>{" "}
                            {this.props.t("common.modals.fileDropper.dropHere")}
                            . <br />
                            <span className="text-gray-500 font-light">
                                {this.props.t(
                                    "common.modals.fileDropper.modal-description"
                                )}
                            </span>
                        </p>
                        <input
                            type="checkbox"
                            id="allContentCheck"
                            onChange={this.handleCheckbox}
                            all-checkbox="true"
                            className={
                                "my-auto " +
                                (this.props.isCheckboxVisible
                                    ? "visible"
                                    : "invisible")
                            }
                        ></input>
                    </div>
                    {this.state.contentIDsForDelete.length > 0 && (
                        <div
                            id="checkOption"
                            className="w-full flex checkOption z-10"
                        >
                            <div className="w-full flex relative justify-end mr-2.5 align-bottom">
                                <button
                                    className="buttonPrimary mb-0"
                                    onClick={() => this.handleCarrousel()}
                                >
                                    {this.props.t(
                                        "sections.content.createCarouselCreative"
                                    )}
                                </button>
                                <button
                                    className="buttonDanger mb-0"
                                    onClick={() =>
                                        this.props.openModal(
                                            "deleteContentModalContext"
                                        )
                                    }
                                >
                                    {this.props.t("sections.content.delete")}
                                </button>
                                <Select
                                    ref={(ref) => {
                                        this.selectRef = ref;
                                    }}
                                    id="select"
                                    isClearable={true}
                                    filterOptions={customFilter}
                                    onChange={this.handleSelect}
                                    className="w-2/6  pt-2  max-h-9 mr-2 z-10"
                                    maxMenuHeight="1"
                                    options={this.getOptionScreens(
                                        this.state.dataScreen
                                    )}
                                    isMulti
                                />
                                <button
                                    className="buttonPrimary text-gray-200"
                                    onClick={() =>
                                        this.handleAsingContentModal()
                                    }
                                >
                                    {this.props.t("sections.content.assign")}
                                </button>
                            </div>
                        </div>
                    )}
                    <div
                        className="main rounded mb-1 h-auto w-full"
                        id="contentListTable"
                    >
                        <DataTableExtensions
                            data={this.state.data}
                            columns={this.state.columns}
                            filterPlaceholder={this.props.t(
                                "sections.content.contentList.search"
                            )}
                            print={false}
                            export={false}
                        >
                            <DataTable
                                striped={true}
                                selectableRows
                                noHeader
                                fixedHeader
                                paginationPerPage={50}
                                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                defaultSortField="id"
                                pagination
                                progressPending={this.state.loading}
                                onRowClicked={(e) => {
                                    this.handleClickInCreativeCarrouselRow(e);
                                    this.props.toggleDetail(
                                        this.getContentByID(e.id)
                                    );
                                }}
                                customStyles={customStyles}
                                data-tag="allowRowEvents"
                                highlightOnHover
                                onSelectedRowsChange={this.handleChangeSelect}
                                clearSelectedRows={this.state.toggleClearRows}
                                noDataComponent={this.props.t(
                                    "table.no-records"
                                )}
                            />
                        </DataTableExtensions>
                    </div>
                    <DecisionModal
                        modalID="assingContentModalContext"
                        title={this.props.t("common.modals.asingContent")}
                        text={this.props.t("sections.content.assignModal")}
                        callbackFunction={this.assignContent}
                    ></DecisionModal>
                    <DecisionModal
                        modalID="deleteContentModalContext"
                        title={this.props.t("common.modals.deleteContent")}
                        text={this.props.t(
                            "sections.modal.deleteModal.warning"
                        )}
                        callbackFunction={this.deleteContentSelected}
                    ></DecisionModal>
                    <ScreensModal
                        modalID="showModalScreens"
                        text={this.props.t("sections.content.screens")}
                        screens={this.state.screensModalOfRow}
                    />
                </div>
                <CarrouselCreativeModal
                    step={1}
                    getScreensByID={this.getScreensByID}
                    getOptionScreens={this.getOptionScreens}
                    contentBeingEdited={this.state.contentBeingEdited}
                    isBeingEdited={this.state.isBeingEdited}
                    clearValue={this.clearValue}
                    addContent={this.props.addContent}
                    modalID={`CarrouselCreativeModal`}
                    showNotification={this.props.showNotification}
                    optionScreens={this.getOptionScreens(this.state.dataScreen)}
                    contents={this.state.carrouselCreative}
                    selectedContent={this.getOptionScreens(
                        this.state.carrouselCreative
                    )}
                    allContent={this.state.data}
                    getItems={this.contentOptions}
                    handleSelectContent={this.handleSelectContent}
                    checkboxes={this.state.contentIDsForDelete}
                />
                {/* <DecisionModal modalID='deleteContentModalContext' text={this.props.t('sections.modal.deleteModal.warning')} callbackFunction={this.deleteContent}></DecisionModal>
                    <OneInputModal modalID="createFolderContextMenuModal" placeHolderText={this.props.t('sections.modal.createFolder.placeholder')} buttonText={this.props.t('sections.modal.createFolder.buttonText')} title={this.props.t('sections.modal.createFolder.title')} callbackFunction={this.createFolder} />
                    <OneInputModal modalID="renameFolderModal" value={this.state.nameRightClicked} callbackChangeValue={this.changeFolderName} placeHolderText={this.props.t('sections.modal.renameFolder.placeholder')} buttonText={this.props.t('sections.modal.renameFolder.buttonText')} title={this.props.t('sections.modal.renameFolder.title')} callbackFunction={this.renameContent}></OneInputModal> */}
            </>
        );
    }
}

export default withTranslation()(ContentList);
