import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class NavBar extends Component {
    constructor(props){
        super(props);
        this.state = {
            activeSection: 'finance'
        }
    }

    componentDidMount = () => {
        this.setState({
            activeSection: this.props.activeSection
        });
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(prevProps.activeSection !== this.props.activeSection){
            this.setState({
                activeSection: this.props.activeSection
            });
        };
    }

    render() {
        let { activeSection } = this.state;
        return (
            <div className="flex flex-col md:flex-row space-y-2 justify-between">
                <div>
                    <nav className="flex flex-nowrap overflow-x-auto items-center">
                        <button id="sectionFinance"  onClick={() => this.props.handleSections("finance")}  className={`${activeSection === "finance" ? 'tabActive font-semibold' : ''} tabGeneral text-xs`}>
                            {this.props.t('sections.management.section.finance.tab')}
                        </button>
                        <button id="sectionCommercial" onClick={() => this.props.handleSections("commercial")}  className={`${activeSection === "commercial" ? 'tabActive font-semibold' : ''} tabGeneral text-xs`}>
                            {this.props.t('sections.management.section.commercial.tab')}
                        </button>
                        {/* <button id="sectionUsers" onClick={() => this.props.handleSections("users")}  className={`${activeSection === "users" ? 'tabActive font-semibold' : ''} tabGeneral text-xs`}>
                            {this.props.t('sections.management.section.users.tab')}
                        </button> */}
                        <button id="sectionProgrammatic"  onClick={() => this.props.handleSections("programmatic")}  className={`${activeSection === "programmatic" ? 'tabActive font-semibold' : ''} tabGeneral text-xs`}>
                            {this.props.t('sections.management.section.programmatic.tab')}
                            {/* PubID + CPMs + enable screen */}
                        </button>
                        <button id="sectionPlayers"  onClick={() => this.props.handleSections("players")}  className={`${activeSection === "players" ? 'tabActive font-semibold' : ''} tabGeneral text-xs`}>
                            {this.props.t("sections.management.section.players.tab")}
                        </button>
                        <button id="sectionMailing"  onClick={() => this.props.handleSections("mailing")}  className={`${activeSection === "mailing" ? 'tabActive font-semibold' : ''} tabGeneral text-xs`}>
                            {this.props.t("sections.management.section.mailing.tab")}
                        </button>
                        <button id="sectionCertification" style={{ display: "none" }} onClick={() => this.props.handleSections("certifications")}  className={`${activeSection === "certifications" ? 'tabActive font-semibold' : ''} tabGeneral text-xs`}>
                            {this.props.t("sections.management.section.cameras.tab")}
                        </button>
                    </nav>
                </div>
            </div>
        )
    }
}
export default withTranslation()(NavBar);
