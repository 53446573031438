import React, { Component } from 'react'

export default class CustomMenu extends Component {
    render() {
        const {props} = this.props;
        return (
            <div className="max-h-full">
                {props.children}
            </div>
        )
    }
}
