import React, { Component } from "react";
import { ScreensHeader } from "./ScreensHeader";
import Mapv2 from "../map/Mapv2";
import ScreenItem from "./ScreenItem";
import ModalScreenSettings from "./modals/ModalScreenSettings";
import DecisionModal from "../utils/DecisionModal";
import { withTranslation } from "react-i18next";
import {
    unpairScreen,
    syncScreen,
    getEvents,
    getScreensByPage,
    deleteScreensById,
    getNameOfScreen,
} from "./Screen.functions";
import { getStatistics } from "../dashboard/DashboardFunctions";
import { KPIs } from "../dashboard/KPIs";
import { getContent } from "../content/contentAJAXFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../loading/Loading";
import cloneDeep from "lodash.clonedeep";
import ScreenPagination from "./ScreenPagination";
import Searchbar from "../utils/Searchbar";
import CameraViewModal from "../utils/Cameras/CameraViewModal";
import OneInputModal from "../utils/OneInputModal";
import { data } from "autoprefixer";
class Screens extends Component {
    constructor() {
        super();
        this.state = {
            screens: [],
            selectedIndex: 1,
            screen_selected: {},
            statistics: {},
            allContent: [],
            isResized: false,
            events: [],
            isLoading: false,
            screensQuantity: [],
            inputScreens: "",
            paginationLimit: 10,
            paginationPage: 1,
            screenIdsToDelete: [],
            openOptionAction: false,
            allScreens: [],
            isSearching: false,
        };
        this.timer = null;
        this.changeSelected = this.changeSelected.bind(this);
        this.changeScreenPair = this.changeScreenPair.bind(this);
        this.containerHeightPlaylist = React.createRef();
    }
    async componentDidMount() {
        this.setState({ isLoading: true }, async () => {
            getScreensByPage()
                .then((res) => {
                    if (res.status === 200) {
                        let screens = res.data.screens;
                        let screensQuantity = res.data.screensQuantity;
                        let orderedScreens = this.sortByAsciiCode(screens);
                        this.setState({ screens: orderedScreens });
                        this.setState({ screensQuantity: screensQuantity });
                        this.setState({ allScreens: screens });
                    }
                })
                .catch((err) => console.log(err));
            let allContent = await this.getAllContent();
            this.setState({ allContent });

            this.getEvents();
            document.addEventListener("click", this.checkClickOutside);
        });
    }
    /*componentDidUpdate = (prevProps, prevState) => {
        if (prevState.inputScreens !== this.state.inputScreens) {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                getScreensByPage(1, 10, this.state.inputScreens)
                    .then(res => {
                        if (res.status === 200) {
                            console.log('se-ejecuto-getScreen-by-component')
                            let screens = res
                            console.log(screens)
                            let screensQuantity = res.data.screensQuantity
                            let orderedScreens = this.sortByAsciiCode(screens);
                            this.setState({ screens: screens });
                            this.setState({ screensQuantity: screensQuantity });
                        }
                    })
                    .catch(err => console.log(err));
            }, 500);
        }
    };*/
    componentWillUnmount = () => {
        document.removeEventListener("click", this.checkClickOutside, false);
    };
    isLastOne = (index) => {
        if (
            (this.state.screens.length - 1 === index ||
                this.state.screens.length - 2 === index ||
                this.state.screens.length - 3 === index) &&
            this.state.screens.length > 9
        ) {
            return true;
        } else {
            return false;
        }
    };
    /**
     * It sorts an array of objects by the name property of each object.
     *
     * @param screens Array of objects
     */
    sortByAsciiCode = (screens = []) => {
        return cloneDeep(
            screens.sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            })
        );
    };

    /**
     * Function that fetch all content !(folder, content) from DB
     * @returns Promise of the fetch call
     */
    getAllContent = () => {
        return new Promise((resolve, reject) => {
            getContent()
                .then((r) => {
                    if (r.status === 200) {
                        let pantallas = [];
                        this.setStatistics();
                        this.setState(
                            {
                                allContent: r.data.filter(
                                    (content) => content.type === "content"
                                ),
                            },
                            () => {
                                resolve(this.state.allContent);
                            }
                        );
                        pantallas.push(r.data);
                        resolve();
                    }
                })
                .catch((err) => console.log(err));
        });
    };
    /**
     * GetScreensByPage is a function that takes in a page and a limit and returns a promise that
     * resolves to a response object with a status of 200 and a data object with a screens array and a
     * screensQuantity number.
     *
     * @param page the page number
     * @param limit the number of items to be displayed per page
     */
    getScreensByPage = (page, limit) => {
        getScreensByPage(page, limit, this.state.inputScreens)
            .then((res) => {
                if (res.status === 200) {
                    console.log("se-ejecuto-get-by-page");
                    let screens = res.data.screens;
                    let screensQuantity = res.data.screensQuantity;
                    let orderedScreens = this.sortByAsciiCode(screens);
                    this.setState({
                        screens: orderedScreens,
                        screensQuantity: screensQuantity,
                        isLoading: false,
                        screenIdsToDelete: [],
                    });
                }
            })
            .catch((err) => console.log(err));
    };

    /**
     * Function that fetch the statistics of a screen and sets it in state
     */
    setStatistics = () => {
        getStatistics()
            .then((r) => {
                if (r.status === 200) {
                    this.setState({ statistics: r.data }, () => {
                        this.setState({ isLoading: false });
                    });
                } else {
                    this.setState({ isLoading: false });
                }
            })
            .catch((err) => {
                console.log(err);
                this.setState({ isLoading: false });
            });
    };

    /**
     * When the user clicks on a tab, the selectedIndex state is updated to the index of the tab that
     * was clicked.
     * @param index - the index of the item that was clicked
     */
    changeSelected(index) {
        this.setState({ selectedIndex: index });
    }
    /**
     * When the user clicks on a screen, the screen_selected state is set to the screen that was clicked
     * on.
     * @param screen - the screen you want to change to
     */
    changeScreenPair(screen) {
        this.setState({ screen_selected: screen });
    }
    /**
     * If the updatedScreen._id is found in the screens array, then update the screen at that index
     * with the updatedScreen.
     * @param updatedScreen - The updated screen object
     */
    updateScreen = (updatedScreen) => {
        const screens = [...this.state.screens];
        const index = screens.findIndex(
            (screen) => screen._id === updatedScreen._id
        );

        if (index !== -1) {
            screens[index] = updatedScreen;
            this.setState({ screens });
        }
    };
    /**
     * It removes a screen from the state.screens array, and then calls the setStatistics function.
     * @param id - the id of the screen to be removed
     */
    removeScreen = (id) => {
        const screens = this.state.screens;
        const newScreens = screens.filter((screen) => screen._id !== id);
        this.setState({ screens: newScreens });
        this.setStatistics();
    };
    /**
     * Get the events from the database and set the state of the events to the response
     * data.
     */
    getEvents = async () => {
        try {
            let response = await getEvents();
            if (response.status === 200) {
                this.setState({ events: response.data });
            }
        } catch (error) {
            console.log(error);
        }
    };
    /**
     * Unpair() is a function that unpairs a screen from a device.
     */
    unpair = async () => {
        let screen = { ...this.state.screen_selected };
        const response = await unpairScreen(screen._id);

        if (response.status === 200) {
            this.props.showNotification({
                type: "success",
                text: this.props.t("common.notification.modalUnpair.synchro"),
            });
            screen.electron_id = "";
            screen.active = false;
            screen._rev = response.data.rev;
            this.updateScreen(screen);
        } else {
            this.props.showNotification({
                type: "Error",
                text: this.props.t("common.notification.serverError"),
            });
        }
    };
    /**
     * It takes a code as a parameter, and if the code is not empty, it will call the syncScreen
     * function, which will return a response. If the response is 200, it will show a success
     * notification, and if it's not, it will show an error notification.
     * @param code - the code that the user enters in the input field
     */
    pair = async (code) => {
        let screen = { ...this.state.screen_selected };

        if (code !== "") {
            const response = await syncScreen(code, screen._id);
            if (response.status === 200) {
                this.props.showNotification({
                    type: "success",
                    text: this.props.t("common.notification.modalPair.synchro"),
                });
                this.updateScreen(response.data);
            } else {
                this.props.showNotification({
                    type: "error",
                    text: this.props.t(
                        "common.notification.modalPair.wrongCode"
                    ),
                });
            }
        }
    };

    /**
     * It takes an id, finds all the content that has that id in its screen_id array, and returns that
     * content.
     * @param id - the id of the screen
     * @returns An array of objects.
     */
    returnScreenContent = (id) => {
        let allContent = [...this.state.allContent];
        let contentOfScreen = [];
        allContent.forEach((content) => {
            if (content.screen_id.includes(id)) {
                contentOfScreen.push(content);
            }
        });
        return contentOfScreen;
    };
    /**
     * It takes an id, an array of disabled values, a revision number, and an index, and then updates
     * the state of the component with the new values.
     * @param id - the id of the content that needs to be updated
     * @param arrDisabled -
     * @param rev - the revision number of the document
     * @param index - the index of the content in the array
     * @returns A promise that resolves to true.
     */
    updateContent = (id, arrDisabled, rev, index) => {
        return new Promise((resolve, reject) => {
            let contentCopy = cloneDeep(this.state.allContent);
            contentCopy.forEach((content, i) => {
                if (content._id === id) {
                    content.disabled = arrDisabled;
                    content._rev = rev;
                }
            });
            this.setState({ allContent: contentCopy }, () => {
                this.setStatistics();
                resolve(true);
            });
        });
    };
    /**
     * It takes an object as an argument, clones the state, updates the cloned state, and then sets the
     * state to the updated cloned state.
     *
     * @param contentToUpdate {
     * @return A promise that resolves to true.
     */
    updateContentCloned = (contentToUpdate = {}) => {
        return new Promise((resolve, reject) => {
            let contentCopy = cloneDeep(this.state.allContent);
            contentCopy.forEach((content, i) => {
                if (content._id === contentToUpdate._id) {
                    content._rev = contentToUpdate._rev;
                    content.hideCloned = contentToUpdate.hideCloned;
                }
            });

            this.setState({ allContent: contentCopy }, () => {
                this.setStatistics();
                resolve(true);
            });
        });
    };
    /**
     * Update the order of the content in the state by comparing the content in the state with the
     * updated content.
     * @param [updatedContent] - an array of objects that have been updated
     * @returns A promise that resolves when the state has been updated.
     */
    updateOrder = (updatedContent = []) => {
        return new Promise((resolve) => {
            let contentCopy = [...this.state.allContent];
            contentCopy.forEach((content) => {
                updatedContent.forEach((upContent) => {
                    if (content._id === upContent._id) {
                        content = upContent;
                    }
                });
            });
            this.setState({ allContent: contentCopy }, () => resolve());
        });
    };

    /**
     * It removes a content from the state and then updates the statistics.
     * @param id - the id of the content to be deleted
     */
    removeContent = (id) => {
        const contents = [...this.state.allContent];
        const filteredContent = contents.filter(
            (content) => content._id !== id
        );
        this.setState({ allContent: filteredContent }, () => {
            this.props.showNotification({
                type: "success",
                text: this.props.t("sections.screens.contentDeleted"),
            });
        });
        this.setStatistics();
    };
    /**
     * It takes an id as an argument and returns an array of objects that have the same id as the
     * argument.
     *
     * @param id the id of the screen
     * @return An array of objects.
     */
    getContentByScreenId = (id) => {
        const contents = [...this.state.allContent];
        const filteredContent = contents.filter((content) =>
            content.screen_id.includes(id)
        );
        return filteredContent;
    };
    /**
     * When the user changes the pagination limit, update the state and then get the screens by page.
     *
     * @param event the event that triggered the function
     */
    handlePaginationOnchangeSelect = (event) => {
        let value = event.target.value;
        this.setState({ isLoading: true }, () => {
            this.setState(
                { paginationLimit: value || 10, paginationPage: 1 },
                () => {
                    this.getScreensByPage(
                        this.state.paginationPage,
                        this.state.paginationLimit
                    );
                }
            );
        });
    };
    /**
     * When the page changes, set the state to the new page, then call the getScreensByPage function
     * with the new page and the limit.
     *
     * @param e the event object
     * @param value the page number
     */
    handlePaginationOnChangePage = (e, value = null) => {
        let page = e.selected + 1;
        this.setState({ paginationPage: page }, () => {
            this.getScreensByPage(
                this.state.paginationPage,
                this.state.paginationLimit
            );
        });
    };

    /**
     * When the toggleElement function is called, the state of the isResized property is set to the
     * opposite of what it currently is.
     */
    toggleElement = () => {
        this.setState({ isResized: !this.state.isResized });
    };
    /**
     * Add or remove the id of screen to the list to delete screens
     * @param {*} id
     */
    handleAddIdOfScreen = (id) => {
        let screensIds = cloneDeep(this.state.screenIdsToDelete);
        if (screensIds.includes(id)) {
            screensIds = screensIds.filter((e) => e !== id);
        } else {
            screensIds.push(id);
        }
        this.setState({ screenIdsToDelete: screensIds });
    };
    /**
     * Delete all the screens selected
     */
    handleDeleteScreensSelected = () => {
        if (this.state.screenIdsToDelete.length > 0) {
            deleteScreensById(this.state.screenIdsToDelete).then((r) => {
                if (r.status === 200) {
                    this.setState({ screenIdsToDelete: [] });
                    this.getScreensByPage(
                        this.state.paginationPage,
                        this.state.paginationLimit
                    );
                    this.props.showNotification({
                        type: "success",
                        text: this.props.t(
                            "common.notification.screens.screensRemovedSuccessfully"
                        ),
                    });
                } else {
                    this.props.showNotification({
                        type: "error",
                        text: this.props.t(
                            "common.notification.screens.screensRemovedError"
                        ),
                    });
                }
            });
        }
    };
    /**
     * Select all  the checkboxes,if they are selected then deselect them
     * @param {*} e
     */
    handleSelectAllCheckboxsOfSceens = (e) => {
        if (e.target.checked) {
            let screenIdsToDelete = this.state.screens.map(
                (screen) => screen._id
            );
            this.setState({ screenIdsToDelete });
        } else {
            this.setState({ screenIdsToDelete: [] });
        }
    };
    /**
     * change the state of the openOptionAction
     */
    handleOpenMenuOption = () => {
        this.setState({ openOptionAction: !this.state.openOptionAction });
    };

    /**
     * If some checkbox is selected open the modal to delete the screens
     */
    handleOpenModalDeleteScreenSelected = () => {
        if (this.state.screenIdsToDelete.length > 0) {
            this.props.openModal("removeScreensSelected");
        } else {
            this.setState({ openOptionAction: false });
            this.props.showNotification({
                type: "warning",
                text: this.props.t(
                    "common.notification.screens.selectAtLeastOne"
                ),
            });
        }
    };
    /**
     * Check if you click on the buttons option or not
     * @param {*} e
     */
    checkClickOutside = (e) => {
        let buttonAction = "";
        if (
            e.target?.toString().includes("HTMLDocument") ||
            e.target?.getAttribute("data-info") === null
        ) {
            if (
                !e.target.parentNode ||
                e.target.parentNode?.toString().includes("HTMLDocument") ||
                e.target.parentNode?.getAttribute("data-info") === null
            ) {
                buttonAction = null;
            } else {
                buttonAction = e.target.parentNode.getAttribute("data-info");
            }
        } else {
            buttonAction = e.target.getAttribute("data-info");
        }
        if (
            buttonAction === "modalActions" ||
            e.target.parentNode?.getAttribute("data-info") === "modalActions"
        ) {
            this.setState({ openOptionAction: true });
        } else {
            this.setState({ openOptionAction: false });
        }
    };
    /* This function sets the `scrollTop` property of an element with a ref called `containerHeightPlaylist` to the `scrollHeight`
    property of the same element. This will cause the element to scroll to the bottom. */
    scrollToEnd = () => {
        let scrollingElement = this.containerHeightPlaylist.current;
        scrollingElement.scrollTop = scrollingElement.scrollHeight;
    };
    /**
     * Gets the cameras with param screen_id, the name of that screen and then open a modal
     * @param {String} screen_id
     */
    viewCamerasOfScreen = async (screen_id, cameras) => {
        try {
            let screenName = await getNameOfScreen(screen_id);
            this.setState(
                {
                    liveCameras: {
                        cameras,
                        screenName: screenName.data,
                    },
                },
                () => this.props.openModal("viewCameras")
            );
        } catch (error) {
            this.props.showNotification({
                type: "error",
                text: this.props.t("common.notification.serverError"),
            });
            console.log(error);
        }
    };
    /**
     * Handles close of camera view modal
     */
    onCloseViewModal = () => {
        this.setState({
            liveCameras: {},
        });
    };

    // Debounce for search function and avoid iteration
    debounce = (callback, wait) => {
        let timerId;
        return (...rest) => {
            clearTimeout(timerId);
            timerId = setTimeout(() => {
                callback(...rest);
            }, wait);
        };
    };

    // Search function for specific screen filter by string name, with debounce on it
    searchScreens = this.debounce((value) => {
        this.setState({ isSearching: true });
        if (value !== "") {
            this.setState({ inputScreens: value });
            const filteredScreens = this.state.allScreens.filter((screen) =>
                screen.name.toLowerCase().includes(value.toLowerCase())
            );
            this.setState({ screens: filteredScreens, isSearching: false });
        } else {
            this.setState({
                screens: this.state.allScreens,
                isSearching: false,
            });
        }
    }, 200);

    render() {
        const { t } = this.props;
        const { isResized } = this.state;
        return (
            <div className="relative w-full h-full">
                <ScreensHeader />
                {/* Cargo Modals de Screens: screens/modals/ */}
                <OneInputModal
                    callbackFunction={this.pair}
                    modalID="pairModal"
                    title={this.props.t("sections.modal.pairModal.title")}
                    placeHolderText={this.props.t(
                        "sections.modal.pairModal.placeholder"
                    )}
                    buttonText={this.props.t(
                        "sections.modal.pairModal.buttonText"
                    )}
                />
                {/* <ModalPair showNotification={this.props.showNotification} changeScreenPair={this.changeScreenPair} screen_selected={this.state.screen_selected} /> */}
                <DecisionModal
                    modalID="unpairModal"
                    text={t("sections.modal.unpairModal.text")}
                    callbackFunction={this.unpair}
                />
                <ModalScreenSettings />
                <div className="relative w-full h-full overflow-y-auto flex flex-col md:flex-row md:divide-x">
                    <div
                        className={`map ${
                            isResized ? "md:w-1/12" : "md:w-4/12"
                        } transition-all duration-150 relative hidden md:block h-full pb-6`}
                    >
                        <Mapv2
                            selected={this.changeSelected}
                            info="Screens"
                        ></Mapv2>
                        <div
                            onClick={this.toggleElement}
                            className="absolute inset-y-0 top-0 -right-2 h-5/6 w-3 my-auto bg-white opacity-70 hover:opacity-100 hover:shadow-lg rounded-full cursor-pointer flex flex-wrap content-center"
                        >
                            <FontAwesomeIcon
                                className="text-gray-500 text-xs ml-0.5"
                                icon={[
                                    "fal",
                                    isResized
                                        ? "chevron-right"
                                        : "chevron-left",
                                ]}
                            />
                        </div>
                    </div>

                    <div
                        className={`screens ${
                            isResized ? "md:w-11/12" : "md:w-8/12"
                        } transition-all duration-150 p-4 `}
                    >
                        {this.state.screens?.length > 0 &&
                            Object.entries(this.state.statistics).length >
                                0 && (
                                <KPIs statistics={this.state.statistics} />
                            )}
                        <div className="flex justify-between">
                            <p>
                                {t("sections.screens.mainTitle")}
                                <div
                                    className={`absolute z-10 ml-24 mt-14 p-2 px-6 text-xs bg-white rounded-md shadow divide-y divide-blue-200 ${
                                        this.state.openOptionAction
                                            ? ""
                                            : "hidden"
                                    }`}
                                >
                                    <div
                                        onClick={() =>
                                            this.handleOpenModalDeleteScreenSelected()
                                        }
                                        className="cursor-pointer select-none text-left py-2 text-red-600 hover:text-black"
                                    >
                                        {this.props.t("common.buttons.delete")}
                                    </div>
                                </div>
                            </p>
                            <div className="flex align-middle">
                                <Searchbar
                                    placeholder={this.props.t(
                                        "sections.calendar.sidebar.searchbar"
                                    )}
                                    disabled={false}
                                    callFunction={(value) => {
                                        this.searchScreens(value);
                                    }}
                                    classNames="shadow appearance-none w-auto border rounded mb-4 mt-2 py-2 mx-2 px-3 ml-0 text-gray-700 leading-tight focus:outline-none focus:focus:ring"
                                />
                            </div>
                        </div>

                        {this.state.isLoading
                            ?
                            <Loading />
                            :
                           ( <>
                                {this.state.screens?.length === 0 ? (
                                    <div className="text-gray-500">
                                        {t("sections.screens.noScreensYet")}
                                    </div>
                                ) : (
                                    <>
                                        <div className="flex items-center">
                                            <input
                                                className="rounded mr-1 ml-1 "
                                                type="checkbox"
                                                checked={
                                                    this.state.screenIdsToDelete
                                                        .length ===
                                                    this.state.screens.length
                                                }
                                            ></input>
                                            <div
                                                data-info="modalActions"
                                                className=" "
                                            >
                                                <p
                                                    onClick={
                                                        this
                                                            .handleOpenMenuOption
                                                    }
                                                    className="font-bold cursor-pointer ml-1"
                                                >
                                                    {this.props.t(
                                                        "sections.screens.actions"
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            ref={this.containerHeightPlaylist}
                                            id="containerHeightPlaylist"
                                            className="h-3/5 overflow-x-hidden overflow-auto "
                                        >
                                            {this.state.screens &&
                                                this.state.screens.map(
                                                    (screen, index) => (
                                                        <>
                                                            <div>
                                                                <ScreenItem
                                                                    scrollToEnd={
                                                                        this
                                                                            .scrollToEnd
                                                                    }
                                                                    isLastOne={this.isLastOne(
                                                                        index
                                                                    )}
                                                                    handleAddIdOfScreen={
                                                                        this
                                                                            .handleAddIdOfScreen
                                                                    }
                                                                    screensToDelete={
                                                                        this
                                                                            .state
                                                                            .screenIdsToDelete
                                                                    }
                                                                    removeContent={
                                                                        this
                                                                            .removeContent
                                                                    }
                                                                    getAllContent={
                                                                        this
                                                                            .getAllContent
                                                                    }
                                                                    updateOrder={
                                                                        this
                                                                            .updateOrder
                                                                    }
                                                                    updateContent={
                                                                        this
                                                                            .updateContent
                                                                    }
                                                                    events={this.state.events.filter(
                                                                        (
                                                                            event
                                                                        ) =>
                                                                            event.screen.includes(
                                                                                screen._id
                                                                            )
                                                                    )}
                                                                    isResized={
                                                                        this
                                                                            .state
                                                                            .isResized
                                                                    }
                                                                    updateContentCloned={
                                                                        this
                                                                            .updateContentCloned
                                                                    }
                                                                    openModal={
                                                                        this
                                                                            .props
                                                                            .openModal
                                                                    }
                                                                    getContentByScreenId={
                                                                        this
                                                                            .getContentByScreenId
                                                                    }
                                                                    content={this.getContentByScreenId(
                                                                        screen._id
                                                                    )}
                                                                    updateScreen={
                                                                        this
                                                                            .updateScreen
                                                                    }
                                                                    removeScreen={
                                                                        this
                                                                            .removeScreen
                                                                    }
                                                                    showNotification={
                                                                        this
                                                                            .props
                                                                            .showNotification
                                                                    }
                                                                    key={index}
                                                                    selected={
                                                                        this
                                                                            .state
                                                                            .selectedIndex
                                                                    }
                                                                    screen={
                                                                        screen
                                                                    }
                                                                    changeScreenPair={
                                                                        this
                                                                            .changeScreenPair
                                                                    }
                                                                    screens={
                                                                        this
                                                                            .state
                                                                            .screens
                                                                    }
                                                                    viewCamerasOfScreen={
                                                                        this
                                                                            .viewCamerasOfScreen
                                                                    }
                                                                />
                                                            </div>
                                                        </>
                                                    )
                                                )}
                                        </div>
                                        <ScreenPagination
                                            handleOnChange={
                                                this
                                                    .handlePaginationOnchangeSelect
                                            }
                                            handlePaginationOnChange={
                                                this
                                                    .handlePaginationOnChangePage
                                            }
                                            limit={this.state.paginationLimit}
                                            itemsLength={
                                                this.state.screensQuantity
                                            }
                                            page={this.state.paginationPage}
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <CameraViewModal
                    showNotification={this.props.showNotification}
                    t={this.props.t}
                    cameras={this.state.liveCameras?.cameras}
                    screenName={this.state.liveCameras?.screenName}
                    modalID={"viewCameras"}
                    onCloseViewModal={this.onCloseViewModal}
                />
                <DecisionModal
                    modalID={`removeScreensSelected`}
                    text={this.props.t("sections.modal.deleteModal.warning")}
                    callbackFunction={this.handleDeleteScreensSelected}
                />
            </div>
        );
    }
}
export default withTranslation()(Screens);
