import React from 'react';
import { withTranslation } from 'react-i18next';

const GeneralLayout = ({ children }) => {
    return (
            <div className={`bg-white bg-opacity-90 rounded-b-md shadow-lg p-4`}>
                {children}
            </div>
        )
    }
    
export default withTranslation()(GeneralLayout);