import cloneDeep from 'lodash.clonedeep';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import isEqual from 'lodash.isequal';
import ButtonSwitcher from '../../buttons/ButtonSwitcher';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

class Step1 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            repeat: [
                {
                    label: this.props.t('sections.calendar.wizard.step1.repeats.daily'),
                    value: 0
                },
                {
                    label: this.props.t('sections.calendar.wizard.step1.repeats.weekly'),
                    value: 1
                }
            ],
            selectedRepeat: 0
        }

        this.timeInputStartRef = React.createRef();
        this.timeInputEndRef = React.createRef();
    }
    componentDidMount() {
        const { event } = this.props;
        if(!event.hourstart){
            event.hourstart = '00:00';
        }
        if(!event.hourend){
            event.hourend = '00:00';
        }
        let selectedRepeat = event.daysOfWeek ? event.daysOfWeek.length > 0 ? 1 : 0 : 0;
        if(event.allDay){
            this.timeInputStartRef.current.disabled = true;
            this.timeInputEndRef.current.disabled = true;
            if(!this.timeInputEndRef.current.classList.contains('bg-gray-300')){
                this.timeInputStartRef.current.classList.add('bg-gray-300');
                this.timeInputEndRef.current.classList.add('bg-gray-300');
            }
        }else{
            this.timeInputStartRef.current.disabled = false;
            this.timeInputEndRef.current.disabled = false;
            if(this.timeInputEndRef.current.classList.contains('bg-gray-300')){
                this.timeInputStartRef.current.classList.remove('bg-gray-300');
                this.timeInputEndRef.current.classList.remove('bg-gray-300');
            }
        }
        this.setState({
            event: cloneDeep(event),
            selectedRepeat
        })
    }
    componentDidUpdate(prevProps, prevState) {
        if (isEqual(this.props.event, {}) && isEqual(this.state.event, {})) {
            if (this.props.isEventClicked !== undefined && !isEqual(this.props.datePicked, prevProps.datePicked)) {
                let today = new Date().getTime();
                if (new Date(this.props.datePicked+"T23:59:59").getTime() < today) {
                    this.props.showNotification({
                        type: "warning",
                        text: this.props.t("common.notification.calendar.pastDate")
                    });
                }
            }
        } else if (!isEqual(prevProps.event, this.props.event)) {
            let event = cloneDeep(this.props.event);
            if(!event.hourstart){
                event.hourstart = '00:00';
            }
            if(!event.hourend){
                event.hourend = '00:00';
            }
            let selectedRepeat = event.daysOfWeek ? event.daysOfWeek.length > 0 ? 1 : 0 : 0;
            if(event.allDay){
                this.timeInputStartRef.current.disabled = true;
                this.timeInputEndRef.current.disabled = true;
                if(!this.timeInputEndRef.current.classList.contains('bg-gray-300')){
                    this.timeInputStartRef.current.classList.add('bg-gray-300');
                    this.timeInputEndRef.current.classList.add('bg-gray-300');
                }
            }else{
                this.timeInputStartRef.current.disabled = false;
                this.timeInputEndRef.current.disabled = false;
                if(this.timeInputEndRef.current.classList.contains('bg-gray-300')){
                    this.timeInputStartRef.current.classList.remove('bg-gray-300');
                    this.timeInputEndRef.current.classList.remove('bg-gray-300');
                }
            }
            this.setState({
                event: event,
                selectedRepeat
            })
        }
    }

    handleInputChange = (event, onlyNeedNumbers = false) => {
        let ids = event.target.id.split("-");
        let field = ids[ids.length - 1].toLowerCase();
        this.props.handleInputChange(field, event.target.value, onlyNeedNumbers);
    }
    /**
     * Function that checks if event start and end are ok and sets it in state.event
     * @param {*} event
     */
    handleDateChange = (date, field) => {
        let eventObj = cloneDeep(this.state.event);
        let year = date.getFullYear();
        if(year.toString().length > 4){
            return;
        }
        let month = (date.getMonth()+1).toString().length === 1 ? `0${date.getMonth()+1}`: date.getMonth()+1;
        if(month.toString().length > 2){
            return;
        }
        let day = (date.getDate()).toString().length === 1 ? (date.getDate()+1).toString().length === 1 ? `0${date.getDate()}`: date.getDate() : date.getDate();
        if(day.toString().length > 2){
            return;
        }
        eventObj[field] = `${year}-${month}-${day}T${eventObj[`hour${field}`] ? eventObj[`hour${field}`] : '00:00'}`;
        this.props.handleInputChange(field, eventObj[field]);
    }

    /**
     * Function that sets selected hour in the appropiate property of event
     * @param {*} e
     */
    handleHourChange = (e) => {
        let ids = e.target.id.split("-");
        let hourField = (ids[1]+ids[ids.length-1]).toLowerCase();
        let field = ids[ids.length - 1].toLowerCase();
        let eventObj = cloneDeep(this.state.event);
        eventObj[field] = this.setDate(eventObj[field]).toISOString().slice(0, 15);
        if(e.target.value){
            eventObj[hourField] = e.target.value;
            eventObj[field] = eventObj[field] ? eventObj[field].split("T")[0] + "T" + e.target.value : this.props.datePicked + "T" + e.target.value;
        }
        this.props.handleInputChange(hourField, eventObj[hourField]);
        this.setState({
            event: eventObj
        }, () => this.handleDateChange(new Date(eventObj[field]), field));
    }
    /**
     * Function that swap the allDay property inside event object
     * If an event has allDay property in true, sets the hours of the event to satisfy all day and disable the hour pickers
     */
    handleVisibility = () => {
        let eventObj = cloneDeep(this.state.event);
        eventObj.allDay = !eventObj.allDay;
        if(eventObj.allDay) {
            if(eventObj.start)
                eventObj.start = eventObj.start.split("T")[0] + "T00:00";
            if(eventObj.end)
                eventObj.end = eventObj.end.split("T")[0] + "T23:59";
            eventObj.hourstart = "00:00";
            eventObj.hourend = "23:59";
        }
        this.handleDateChange(new Date(eventObj.start), 'start');
        this.handleDateChange(new Date(eventObj.end), 'end');
        this.props.handleInputChange("hourstart", eventObj.hourstart);
        this.props.handleInputChange("hourstart", eventObj.hourend);
        this.props.handleInputChange("allDay", eventObj.allDay);
    }
    /**
     * Function that check if mandatory event properties are fullfiled and trigger a callback function
     * @returns in case of a trouble if there is a missing propertry of event
     */
    nextStep = () => {
        let event = cloneDeep(this.state.event);
        if(!event?.title) {
            this.props.showNotification({
                type: "warning",
                text: this.props.t("common.notification.calendar.emptyTitle")
            });
            return;
        }
        if (!event.start) {
            event.start = this.props.datePicked;
        }
        if (!event.end) {
            event.end = this.props.datePicked;
        }
        if(new Date(event.start.split("T")[0].replace('-', '/')).getTime() > new Date(event.end.split("T")[0].replace('-', '/')).getTime()){
            this.props.showNotification({
                type: "warning",
                text: this.props.t("common.notification.calendar.dateError")
            });
            return;
        }
        if(!event.hourstart && !event.hourend) {
            event.start.concat(`T00:00`);
            event.end.concat(`T23:59`);
            event.hourstart = "00:00";
            event.hourend = "23:59";
            event.allDay = true;
        }
        if(event.daysOfWeek){
            if(event.daysOfWeek.length === 0){
                delete event.daysOfWeek;
            }else{
                event.startRecur = event.start;
                event.endRecur = event.end;
            }
        }
        this.props.nextStep(event);
    }

    /**
     * Function that returns name of days of week
     * @returns Array of week day names
     */
    getSchedule = () =>{
        return [
            this.props.t('common.days.sunday'),
            this.props.t('common.days.monday'),
            this.props.t('common.days.tuesday'),
            this.props.t('common.days.wednesday'),
            this.props.t('common.days.thursday'),
            this.props.t('common.days.friday'),
            this.props.t('common.days.saturday'),
        ]
    }
    closeModal = () => {
        this.setState({
            event: {}
        }, () => {
            this.props.closeModal();
        })
    }
    handleRepetitiveDayChange = (weekdayNumber) => {
        let event = this.state.event;
        this.props.handleRepetitiveDayChange(weekdayNumber, event)
    }
    handleRepeatEvent = (selected) => {
        this.setState({
            selectedRepeat: selected.value
        }, () => {
            let event = cloneDeep(this.state.event);
            delete event.daysOfWeek;
            this.setState({
                event: event
            })
        })
    }
    /**
     * Parse a string date into a Date
     * @param {Date} date
     * @returns Date
     */
    setDate = (date) => {
        if(date){
            let splitedDate = date.split('T');
            let yymmdd = splitedDate[0].split('-');
            yymmdd = `${yymmdd[0]}-${yymmdd[1].length === 1 ? "0"+yymmdd[1] : yymmdd[1]}-${yymmdd[2].length === 1 ? "0"+yymmdd[2] : yymmdd[2]}`;
            splitedDate[0] = yymmdd;
            date = splitedDate.join('T');
        }

        const newDate = new Date();
        const dateFromDateParam = new Date(date);
        dateFromDateParam.setTime(dateFromDateParam.getTime() + dateFromDateParam.getTimezoneOffset() * 60000);

        return date ? dateFromDateParam : newDate;
    };


    render() {
        return (
            <div className="stepOne">
                <label
                    htmlFor="Title"
                    className="block uppercase flex-row-reverse tracking-wide text-gray-600 text-xs font-bold mb-2 mx-1 mt-5"
                >
                    <span className="flex justify-between">
                        {this.props.t(
                            "sections.calendar.wizard.step1.eventTitle"
                        )}
                        :
                        <small id="helpId" className="form-text text-gray-400">
                            {this.props.t(
                                "sections.calendar.wizard.step1.eventTitleDesc"
                            )}
                        </small>
                    </span>
                    <input
                        type="text"
                        className="appearance-none block w-full text-gray-600 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        name="newEventTitle"
                        id="new-title"
                        onChange={(e) => this.handleInputChange(e)}
                        value={this.state.event?.title || ""}
                    />
                </label>
                <label
                    htmlFor="date"
                    className="flex w-full dateBox  uppercase tracking-wide text-gray-600 text-xs font-bold mb-2  mt-5"
                >
                    <label htmlFor="Start" className="flex flex-col w-1/2 p-1">
                        {this.props.t(
                            "sections.calendar.wizard.step1.startDate"
                        )}
                        :
                        <DatePicker
                            className="font-normal uppercase text-gray-600 border border-gray-200 py-2 px-4 rounded mb-3 leading-tight focus:outline-none focus:bg-white"
                            onChange={(date) =>
                                this.handleDateChange(date, "start")
                            }
                            selected={
                                typeof this.state.event === "object" &&
                                Object.keys(this.state.event).includes("start")
                                    ? this.setDate(this.state.event.start)
                                    : this.setDate(this.props.datePicked)
                            }
                            dateFormat="dd/MM/yyyy"
                        />
                    </label>
                    <label htmlFor="End" className="flex flex-col w-1/2 p-1">
                        {this.props.t("sections.calendar.wizard.step1.endDate")}
                        :
                        <DatePicker
                            className="uppercase text-gray-600 border border-gray-200 py-2 px-4 rounded mb-3 leading-tight focus:outline-none focus:bg-white"
                            onChange={(date) =>
                                this.handleDateChange(date, "end")
                            }
                            selected={
                                typeof this.state.event === "object" &&
                                Object.keys(this.state.event).includes("end")
                                    ? this.setDate(this.state.event.end)
                                    : this.setDate(this.props.datePicked)
                            }
                            dateFormat="dd/MM/yyyy"
                        />
                    </label>
                </label>

                <label
                    htmlFor="timerange"
                    className="flex w-auto uppercase tracking-wide text-gray-600 text-xs font-bold"
                >
                    <label
                        className="flex flex-col flex-1 p-1"
                        htmlFor="allDayEvent"
                    >
                        {this.props.t(
                            "sections.calendar.wizard.step1.allDayEvent"
                        )}
                        ?
                        <ButtonSwitcher
                            className="my-2 capitalize"
                            handleVisibility={() => this.handleVisibility()}
                            status={this.state.event?.allDay ? true : false}
                            labelOn={this.props.t("common.buttons.yes")}
                            labelOff={this.props.t("common.buttons.no")}
                            revertLabel={true}
                        ></ButtonSwitcher>
                    </label>
                    <label
                        htmlFor="start-hour"
                        className="flex flex-col flex-1 p-1"
                    >
                        {this.props.t(
                            "sections.calendar.wizard.step1.startTime"
                        )}
                        :
                        <input
                            type="time"
                            ref={this.timeInputStartRef}
                            className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring"
                            name="newEventTill"
                            id="new-hour-start"
                            onChange={this.handleHourChange}
                            value={
                                this.state.event
                                    ? this.state.event.hourstart
                                    : "00:00"
                            }
                        />
                    </label>
                    <label
                        htmlFor="end-hour"
                        className="flex flex-col flex-1 p-1"
                    >
                        {this.props.t("sections.calendar.wizard.step1.endTime")}
                        :
                        <input
                            type="time"
                            ref={this.timeInputEndRef}
                            className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring"
                            name="newEventFrom"
                            id="new-hour-end"
                            onChange={this.handleHourChange}
                            value={
                                this.state.event
                                    ? this.state.event.hourend
                                    : "00:00"
                            }
                        />
                    </label>
                </label>

                <label
                    htmlFor="schedule"
                    className="block flex-row-reverse uppercase text-xs font-bold tracking-wide text-gray-600 mb-2 mx-1 mt-5"
                >
                    <ReactTooltip
                        effect="solid"
                        className="p-2 text-center font-light normal-case"
                        id={"1rstStepRepeatDay"}
                        aria-haspopup="true"
                    >
                        {this.props.t(
                            "sections.calendar.wizard.step1.repeatInfo"
                        )}
                    </ReactTooltip>
                    <span className="flex">
                        {this.props.t(
                            "sections.calendar.wizard.step1.repetitiveEvent"
                        )}
                        <FontAwesomeIcon
                            icon={["fas", "question-circle"]}
                            className="my-auto pl-1 text-gray-400"
                            data-tip
                            data-for="1rstStepRepeatDay"
                        />
                    </span>
                    <Select
                        className="font-semibold"
                        onChange={this.handleRepeatEvent}
                        options={this.state.repeat}
                        value={this.state.repeat[this.state.selectedRepeat]}
                    />
                    {this.state.selectedRepeat === 1 && (
                        <div className={"flex justify-around my-auto"}>
                            {this.getSchedule().map((day, index) => {
                                return (
                                    <button
                                        className={`my-2 p-1.5 border font-semibold rounded ${
                                            this.state.event?.daysOfWeek?.includes(
                                                index
                                            )
                                                ? "bg-blue-500 text-white"
                                                : ""
                                        }`}
                                        data-day={index}
                                        onClick={() =>
                                            this.handleRepetitiveDayChange(
                                                index
                                            )
                                        }
                                    >
                                        {day}
                                    </button>
                                );
                            })}
                        </div>
                    )}
                </label>
                <div className="flex justify-between mt-3">
                    {!this.props.isEventClicked && (
                        <>
                            <button
                                onClick={this.closeModal}
                                className="buttonSecondary"
                            >
                                {this.props.t("common.buttons.close")}
                            </button>
                        </>
                    )}
                    <button
                        onClick={this.nextStep}
                        className="m-auto  buttonPrimary "
                    >
                        {this.props.t("common.buttons.next")}
                    </button>
                </div>
            </div>
        );
    }
}
export default withTranslation()(Step1);
