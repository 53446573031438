import React, { useEffect, useState }  from 'react';

function ButtonSwitcher(params) {
    const buttonStatus = params.status;
    const labelOn = params.labelOn;
    const labelOff = params.labelOff;
    const revertLabel = params.revertLabel;
    const [isOn, setIsOn] = useState(buttonStatus);
    useEffect(()=>{
        setIsOn(params.status)
    }, [params.status])
    const handleClick = () => {
        if(params.handleVisibility){
            setIsOn(!isOn);
            params.handleVisibility();
        }
    }
    return (
        <>
            <div
                className={`flex items-center justify-center gap-2 ${
                    revertLabel === true ? "" : "flex-row"
                }`}
            >
                <div
                    onClick={() => handleClick()}
                    className="w-8 relative my-1 cursor-pointer"
                >
                    <div
                        className={`${
                            isOn ? "bg-blue-300" : "bg-gray-300"
                        } h-5 w-8 rounded-full px-1`}
                    >
                        <div
                            className={`${
                                isOn ? "ml-3" : "mr-4"
                            } mt-1 w-3 h-3 absolute transition-all transform ease-linear duration-75 flex items-center justify-center rounded-full bg-white shadow-toggle border-gray-300 top-0`}
                        ></div>
                    </div>
                </div>
                <div
                    onClick={() => handleClick()}
                    className={`${labelOff ? "block" : "hidden"}`}
                >
                    {isOn ? labelOn : labelOff}
                </div>
            </div>
        </>
    );
}

export default ButtonSwitcher;
