import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../utils/modals.css';
class ScreenModal extends Component {
    returnValue = (e) => {
        const value = e.target.previousSibling.value;
        this.props.callbackFunction(value);
        
        if (value === '')
            return;
            
        this.closeModal();
        e.target.previousSibling.value = "";
    }

    closeModal = () => {
        document.getElementById(this.props.modalID).classList.add('hidden');
    }

    render() {
        const screens=this.props.screens
        return (
            <div id={this.props.modalID} className="modal fixed z-40 w-screen h-screen top-0 left-0 flex items-center justify-center hidden">
                <div className="modalContent bg-white w-1/5 overflow-x-auto rounded-md p-5 flex flex-col h-auto align-middle ">
                    <div className="modal-header relative font-extralight text-lg flex justify-between border-b-2 mb-2 mt-1"> 
                        <h1  className="block uppercase  tracking-wide text-gray-600 text-xs font-bold mt-2 ">{this.props.text}</h1>
                        <div className="button cursor-pointer" onClick={this.closeModal}><FontAwesomeIcon icon={["fal", "times"]} /></div>
                    </div>
                    <div className="w-full">
                        <ul>
                        {screens.map(e=>(<li className="text-sm text-gray-700 font-semibold rounded">{e}</li>))}
                           
                        
                         
                        </ul>
                    </div>
                    
                </div>
            </div>
        );
    }
}

export default withTranslation()(ScreenModal);