import React from "react";
import { withTranslation } from "react-i18next";
import Board from "react-trello/dist";
import BoardCard from "./boardComponent/BoardCard";
import newLaneSection from "./boardComponent/newLaneSection";
import AddCardLink from "./boardComponent/AddCardLink";
import cloneDeep from 'lodash.clonedeep';
import BoardCardModal from "../utils/BoardCardModal";
import BoardCardModalEdit from "../utils/BoardCardModalEdit";
import ModalAddColumn from './boardComponent/ModalAddColumn'
import DecisionModal from '../utils/DecisionModal'
import {
    getAllColumns,
    deleteOOH_Status,
    editColumns,
    updateManyColumns,
    getCards,
    getCard,
    changeProperty,
} from "./boardComponent/BoardFunctions";
import LaneHearder from "./boardComponent/LaneHeader";

class Trello extends React.Component {
    constructor() {
        super();
        this.state = {
            data: {
                lanes: [],
            },
            actualLane: "",
            cardBeingEdited: undefined,
            lanesLength: 0,
            handleDragStart: true,
            loading: false,
            laneId: undefined,
            limitOfCards: 5,
            columnToEdit: null
        };
        this.timer = null;
    }

    componentDidMount = async () => {
        let data = {};
        data.lanes = await this.getColumns();
        this.loadCardsInEachColumn(data);
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.searchBarInputValue !== this.props.searchBarInputValue) {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.loadCardsInEachColumn(cloneDeep(this.state.data), this.props.searchBarInputValue);
            }, 500);
        }
    }

    /**
     * Get the columns of Status
     * @returns an array of columns
     */
    getColumns = async () => {
        try {
            let columns = await this.getAllColumns();
            if (columns) {
                return columns;
            }
        } catch (error) {
            console.log(error);
            this.props.showNotification({
                type: "error",
                text: this.props.t('common.notification.status.errorGetColumns')
            });
        }
    };
    /**
     * Get the cards from the database with the given filters and appends to each column.
     * @param {*} data The old data in the board.
     * @param {*} filterValue The value of the filter input.
     */
    loadCardsInEachColumn = async (data, filterValue = "") => {
        this.setState({ loading: true })
        let promises = [];
        for (let i = 0; i < data.lanes.length; i++) {
            data.lanes[i].id = data.lanes[i]._id;
            promises.push(
                new Promise(async (resolve, reject) => {
                    try {
                        let response = await getCards(1, this.state.limitOfCards, data.lanes[i].id, filterValue);
                        data.lanes[i].cards = response.data.cards;
                        resolve(data.lanes[i]);
                    } catch (error) {
                        console.log(error);
                        reject(error);
                    }
                })
            );
        }
        try {
            let responses = await Promise.allSettled(promises);
            data.lanes = responses.filter(card => card.status === "fulfilled").map((card) => card.value);
            this.setState({
                data: data,
                lanesLength: data.lanes.length,
                loading: false,
            });
        } catch (error) {
            console.log(error);
            this.setState({ loading: false });
            this.props.showNotification({
                type: "error",
                text: this.props.t('common.notification.status.errorGetCards'),
            });
        }
    };

    /**
     * return the columns of board
     * @returns Array of columns
     */
    getAllColumns = async () => {
        let response = await getAllColumns();
        if (response) {
            return response.data;
        }
    };

    /**
    * Add a new card to the board 
    * @param {*} card that is going to be added to the board
    */
    addNewCard = (card) => {
        let data = cloneDeep(this.state.data);
        card.laneId = this.state.actualLane;
        let lane = data.lanes.find((l) => l.id === this.state.actualLane)
        if(lane){
            lane.cards.push(card);
            this.setState({ data: data });
            this.props.showNotification({
                type: 'success',
                text: this.props.t("common.notification.status.successToCreateCard")
            })
        }
    };

    /**
     * Open a modal and set the id of the Actual lane that has clicked
     * @param {*} id that is going to be hide
     * @param {*} lane that is going to be seted
     */
    openModal = (id, lane) => {
        document.getElementById(id).classList.toggle("hidden");
        this.setState({ actualLane: lane });
    };

    /**
     * Return a card that has the id selected
     * @param {*} id hat is going to be selected
     * @returns card that is being sought
     */
    searchCard = (id) => {
        let data = cloneDeep(this.state.data);
        let card = data.lanes
            .map((e) => e.cards)
            .flat()
            .find((e) => e.id === id);
        return card;
    };

    /**
     * Pass the object card to being edited and open the modal
     * @param {*} id 
     */
    editModal = (id) => {
        let card = this.searchCard(id);
        if (card) {
            card.lane_id = card.laneId
            this.setState({ cardBeingEdited: card }, () => {
                this.props.openModal("BoardCardModalEdit");
            });
        }
    };

    /**
     * Keeps the data updated at all times
     * @param {*} data  containing the information to be mapped to the board component
     */
    onDataChange = async (data) => {
        this.setState({ data: data });
    };

    /**
     * When a column name is changed save the changes in db
     * @param {*} laneId the id of the column
     * @param {*} data that contain all the info of the board
     */
    onLaneUpdate = (laneId, data) => {
        let inf = {
            laneId: laneId,
            title: data.title
        };
        try {
            editColumns(inf);
        } catch (error) {
            console.log(error);
        }
    };
    /**
     * update the position of the cards
     * @param {*} cardId the id of the card
     * @param {*} sourceLaneId id of column where the card was
     * @param {*} targetLaneId id of column where the card is positioned
     * @param {*} cardDetails atributes of the card
     */
    handleDragEnd = async (
        cardId,
        sourceLaneId,
        targetLaneId,
        position,
        cardDetails
    ) => {

        if (sourceLaneId !== targetLaneId) {
            let response = await changeProperty({
                id: cardDetails._id, properties: {
                    lane_id: targetLaneId
                }
            });
            if (response.status === 200) {
                let responseCard = await getCard(response.data.id)
                if (responseCard.status === 200) {
                    responseCard.data.laneId = responseCard.data.lane_id
                    let data = cloneDeep(this.state.data);
                    let columnIndex = data.lanes.findIndex(column => column.id === targetLaneId);
                    if (columnIndex !== -1) {
                        let cardIndex = data.lanes[columnIndex].cards.findIndex(card => card._id === cardId);
                        if (cardIndex !== -1) {
                            data.lanes[columnIndex].cards[cardIndex]._rev = responseCard.data._rev
                            this.setState({ data: data })
                        }

                    }
                }
            }
        }
    };

    /**
     * when the column is moved the order of the others is updated 
     * @param {*} removedIndex 
     * @param {*} addedIndex 
     * @param {*} payload 
     */
    handleLaneDragEnd = async (removedIndex, addedIndex, payload) => {
        let columns = cloneDeep(this.state.data.lanes);
        columns = this.updateOrderOfColumns(columns);
        try {
            await updateManyColumns(columns);
        } catch (error) {
            console.log(error);
        }
    };

    /**
     * update the order of the columns
     * @param {*} array 
     * @returns array of columns whit the new order
     */
    updateOrderOfColumns = (columns) => {
        columns.forEach((lane, index) => {
            lane.order = index;
        });
        return columns;
    };

    /**
     * Returns a component that replaces the original and that has new functions that have been introduced by props
     * @param {*} props properties inherited from react-trello library (title,onClick,issueID,content)
     * @returns component of a card
     */
    BoardCard = (props) => {
        return (
            <BoardCard
                {...props}
                content={this.props.contents.find(
                    (content) => content._id === props.contentId
                )}
                handleDragStart={this.state.handleDragStart}
            ></BoardCard>
        );
    };
    
    /**
     * Returns a component that replaces the original and that has new functions that have been introduced by props
     * @param {*} props properties inherited from react-trello library ( updateTitle, canAddLanes, onDelete, editLaneTitle, label, title, titleStyle, labelStyle, t, laneDraggable, openModal, onDoubleClick ,_id)
     * @returns upper column component
     */
    LaneHearder = (props) => {
        return (
            <LaneHearder {...props} setLaneId={this.setLaneId} traslate={this.props.t} setEditColumn={this.setEditColumn} openModal={this.props.openModal}></LaneHearder>
        );
    }

    /**
     * when a column is clicked saved the id in the state
     * @param {*} laneId the id of a column
     */
    onLaneClick = (laneId) => {
        this.setState({ actualLane: laneId })
    }

    /**
     * when scroll to bottom get more card of the column
     * @param {*} requestedPage the new page of cards
     * @param {*} laneId the id of the column
     * @returns array of cards
     */
    onLaneScroll = (requestedPage, laneId) => {
        return this.getCardsOnScroll(requestedPage, laneId);
    };
    /**
     * Gets the cards by page and by lane id when the scroll event is completed.
     * @param {Number} requestedPage The number of the page to scroll.
     * @param {String} laneId The id of the lane where scroll event is fired. 
     * @returns {Array} The new cards that will be added to the total cards.
     */
    getCardsOnScroll = (requestedPage, laneId) => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await getCards(requestedPage, this.state.limitOfCards, laneId, this.props.searchBarInputValue || "");
                resolve(response.data.cards);
            } catch (error) {
                resolve([]);
            }
        })
    }
    /**
     * When a new column is created update the lenght of  the columns
     * @param {*} value 
     */
    newLaneCreated = (value) => {
        this.setState({ lanesLength: value });
    };

    /**
     * add a new Column to the board
     * @param {*} value 
     */
    addColumnToBoard = (value) => {
        let data = cloneDeep(this.state.data)
        data.lanes.push(value)
        this.setState({ data: data })
        this.props.showNotification({
            type: 'success',
            text: this.props.t("common.notification.status.successToCreateColumn")
        })
    }

     /**
     * Returns a component that replaces the original and that has new functions that have been introduced by props
     * @param {*} props properties inherited from react-trello library ( openModal, laneId )
     * @returns lower column component
     */
    renderCard = (props) => {
        return (
            <AddCardLink {...props} openModal={this.openModal}></AddCardLink>
        );
    };

    /**
     * Get the contents to create or update a card
     */
    getContents = () => {
        if (this.state.cardBeingEdited?.contentType === "DOOH") {
            return this.props.contents.filter(c => !c.isOOH);
        } else {
            return this.props.contents.filter(c => c.isOOH);
        }
    }

    /**
     * update the lane id
     * @param {*} id 
     */
    setLaneId = (id) => {
        if (id) {
            this.setState({ laneId: id })
        }
    }

    /**
     * Delete a column from the dataBase
     */
    deleteColumn = async () => {
        let data = cloneDeep(this.state.data)
        let column = data.lanes.find(lane => lane.id === this.state.laneId)
        if (column.cards.length <= 0) {
            try {
                let response = await deleteOOH_Status(this.state.laneId);
                if (response.status === 200) {
                    let index = data.lanes.findIndex(lane => { return lane.id === this.state.laneId; })
                    data.lanes.splice(index, 1);
                    this.setState({ data: data })
                    this.props.showNotification({
                        type: "success",
                        text: this.props.t('common.notification.status.successToDeleteColumn'),
                    });
                } else {
                    this.props.showNotification({
                        type: "error",
                        text: this.props.t('common.notification.status.errorToDeleteColumn'),
                    });
                }
            } catch (error) {
                console.log(error);
                this.props.showNotification({
                    type: "error",
                    text: this.props.t('common.notification.status.errorToDeleteColumn'),
                });
            }
        } else {
            this.props.showNotification({
                type: "error",
                text: this.props.t('common.notification.status.errorToDeleteColumnWithCards'),
            });
        }
    }

    /**
     * replace in the data object the new card that has changed
     * @param {Object} newCard 
     */
    replaceCard = (newCard) => {
        let data = cloneDeep(this.state.data);
        let columnIndex = data.lanes.findIndex(column => column.id === newCard.lane_id);
        let cardIndex = data.lanes[columnIndex].cards.findIndex(card => card.id === newCard.id);
        data.lanes[columnIndex].cards[cardIndex] = newCard;
        this.setState({ data: data })
    }

    /**
     * Remove a card from the board
     * @param {*} newCard 
     */
    deleteCard = (newCard) => {
        let data = cloneDeep(this.state.data);
        let columnIndex = data.lanes.findIndex(column => column.id === newCard.lane_id);
        let cardIndex = data.lanes[columnIndex].cards.findIndex(card => card.id === newCard.id);
        data.lanes[columnIndex].cards.splice(cardIndex, 1);
        this.setState({ data: data })
    }

    /**
     * return the component board
     * @param {*} data 
     * @returns component
     */
    renderBoard = (data) => {
        return (
            <>
                <Board
                    /* Rendering the board. */
                    // , height: "99%" ,width: "100%"
                    style={{ backgroundColor: "white", height: "auto", minHeight: "600px" }}
                    data={data}
                    onDataChange={this.onDataChange}
                    editable={true}
                    laneSortFunction={this.laneSortFunction}
                    canAddLanes
                    draggable
                    handleDragEnd={this.handleDragEnd}
                    onLaneUpdate={this.onLaneUpdate}
                    onCardClick={this.editModal}
                    onLaneClick={this.onLaneClick}
                    onLaneScroll={this.onLaneScroll}
                    eventBusHandle={this.setEventBus}
                    handleLaneDragEnd={this.handleLaneDragEnd}
                    components={{
                        Card: this.BoardCard,
                        NewLaneSection: newLaneSection,
                        NewLaneForm: this.NewLaneForm,
                        AddCardLink: this.renderCard,
                        LaneHeader: this.LaneHearder,
                    }}
                />
            </>
        );
    };

    /**
     * Set the id of the column that was clicked.
     * @param {*} laneId the id of the column
     */
    setEditColumn = (laneId) => {
        let lanes = cloneDeep(this.state.data.lanes);
        let lane = lanes.find(lane => lane._id === laneId)
        this.setState({ columnToEdit: lane })
    }

    /**
     * update atributes of the column
     * @param {*} updatedColumn 
     */
    replaceColumnAtributes = (updatedColumn) => {
        let data = cloneDeep(this.state.data)
        let columnIndex = data.lanes.findIndex(column => column.id === updatedColumn._id);
        if(columnIndex !== -1){
            data.lanes[columnIndex].title = updatedColumn.title;
            data.lanes[columnIndex]._rev = updatedColumn._rev;
            data.lanes[columnIndex].titleStyle.border.color = updatedColumn.titleStyle.border.color;
            this.setState({ data: data });
        }
    }

    render() {
        return (
            <div>
                {/* the board */}
                {this.renderBoard(this.state.data)}
                {/* modals */}
                <BoardCardModalEdit
                    t={this.props.t}
                    contents={this.getContents()}
                    // saveChanges={this.saveChanges}
                    cardBeingEdited={this.state.cardBeingEdited}
                    showNotification={this.props.showNotification}
                    replaceCard={this.replaceCard}
                    actualLane={this.state.actualLane}
                    editContent={this.props.editContent}
                    deleteCard={this.deleteCard}
                    openModal={this.props.openModal}
                ></BoardCardModalEdit>
                <BoardCardModal
                    t = {this.props.t}
                    contents={this.props.contents}
                    customers={this.props.customers}
                    brands={this.props.brands}
                    actualLane={this.state.actualLane}
                    cardBeingEdited={this.state.cardBeingEdited}
                    showNotification={this.props.showNotification}
                    addNewCard={this.addNewCard}
                ></BoardCardModal>
                <ModalAddColumn
                    replaceColumnAtributes = {this.replaceColumnAtributes}
                    resetEditColumn = {() => this.setState({ columnToEdit: null })}
                    setEditColumn = {this.setEditColumn}
                    columnToEdit = {this.state.columnToEdit}
                    t = {this.props.t}
                    addColumnToBoard={this.addColumnToBoard}
                    showNotification={this.props.showNotification}
                    newLaneCreated={this.newLaneCreated}
                    lanesLength={this.state.lanesLength}
                    modalID={`ModalAddColumn`}
                    buttonText={this.props.t('sections.content.confirm')}
                    text={this.props.t('sections.modal.deleteModal.warning')}
                    callbackFunction={() => {
                    }}
                />
                <DecisionModal modalID="deleteLane" text={this.props.t('sections.modal.deleteModal.warning')} callbackFunction={this.deleteColumn} />

            </div>
        );
    }
}

export default withTranslation()(Trello);
