import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { addGroup } from './AJAXCalls';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


class GroupModal extends Component {
    addGroup = async (e) => {
        e.preventDefault();
        const btn = e.target;
        btn.disabled = true;
        const input = document.querySelector('#groupName');
        const groupName = input.value;
        if (groupName !== '') {
            const response = await addGroup({ name: groupName, type: "screenGroup" });

            if (response.status === 201) {
                this.props.getGroups().then(r => {
                    if (r)
                        this.props.setGroup(response.data[0].id);
                });
            }

            btn.disabled = false;
            input.value = '';
            this.props.toggleModal();
        }
        else {
            this.props.showNotification({
                type: "error",
                text: this.props.t('common.notification.groupModal.addName')
            });
            btn.disabled = false;
        }
    }

    render() {
        const toggleModal = this.props.toggleModal;

        return (
            <div>
                {/* <!--Modal--> */}
                <div className="modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex items-center justify-center">
                    <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>

                    <div className="bg-white mx-auto rounded shadow-lg z-50 overflow-y-auto">
                        <div className="modal-header relative font-extralight text-lg flex justify-between px-3 mt-1">
                            <div className="title text-gray-600 text-2xl">{this.props.t('sections.wizard.stepOne.groupModal.addGroup')}</div>
                            <div className="button cursor-pointer" onClick={toggleModal}><FontAwesomeIcon icon={["fal", "times"]} /></div>
                        </div>
                        <div className="modal-content py-4 text-left px-6">
                            <div>
                                <label className="block text-gray-700 text-sm font-bold mb-2">{this.props.t('sections.wizard.stepOne.groupModal.name')}</label>
                                <input id="groupName"
                                    type="text"
                                    className="shadow appearance-none border rounded w-full mb-4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring"
                                    placeholder={this.props.t('sections.wizard.stepOne.groupModal.namePlaceHolder')} />
                            </div>

                            {/* <!--Footer--> */}
                            <div className="clear-left flex justify-end pt-2">
                                <button className="buttonSecondary" onClick={toggleModal}>{this.props.t('common.buttons.close')}</button>
                                <button className="modal-close buttonPrimary" onClick={this.addGroup}>{this.props.t('common.buttons.accept')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(GroupModal);