import React from 'react'

const BulkImportHeader = (props) => {
  return (
    <div className="relative border-b-2 border-gray-200 py-2">
        <span className="font-extralight">{props.title}</span>
    </div>
  )
}

export default BulkImportHeader