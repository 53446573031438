import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation } from 'react-i18next';

const ExpandedCertificationRow = ({ data, cameraModels, handleEditCameraConfig, openLive, t }) => {
    return (
        <div className='divide-y divide-solid divide-gray-300 border border-gray-300 rounded-b'>
            <div className='flex p-2 items-center'>
                <div className='w-2/12 text-center'>
                    {t('sections.management.section.cameras.expandedRow.certifications')}
                </div>
                <div className='w-2/12 m-auto'>
                    {t('sections.management.section.cameras.expandedRow.focus')}
                </div>
                <div className='w-3/12 m-auto'>
                    {t('sections.management.section.cameras.expandedRow.description')}
                </div>
                <div className='w-3/12 m-auto'>
                    IP:{t('sections.management.section.cameras.modal.url')}
                </div>
                <div className='w-2/12'>

                </div>
            </div>
            {data.cameras.map((camera,i) => {
                return (
                    <div key={camera._id} className='flex justify-between p-2 items-center'>
                        <div className='flex w-2/12 justify-evenly'>
                            <div onClick={() => openLive(camera, camera.screen)}>
                                <FontAwesomeIcon icon={["fal", "cctv"]} fixedWidth className='text-red-400 cursor-pointer'/>
                            </div>
                            {/* <div className='hidden' onClick={() => console.log('Deberia abrir un modal donde ver screenshots')}>
                                <FontAwesomeIcon icon={["fal", "images"]} fixedWidth className='text-blue-400 cursor-pointer'/>
                            </div> */}
                        </div>
                        <div className='w-2/12'>
                            {camera.focus}
                        </div>
                        <div className='w-3/12 truncate'>
                            {camera.description}
                        </div>
                        <div className='w-3/12 truncate' title={camera.stream}>
                            {camera.stream}
                        </div>
                        <div className='w-2/12 flex flex-row-reverse'>
                            <button className={`buttonPrimary-sm`} raised="true" primary="true" onClick={(e) => {e.preventDefault(); handleEditCameraConfig('edit', camera)}}>{t('common.buttons.edit')}</button>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default withTranslation()(ExpandedCertificationRow)
