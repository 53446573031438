import React, { Component } from 'react'
import { Doughnut } from 'react-chartjs-2';
import { withTranslation } from 'react-i18next';

class KPI extends Component {
    render() {
        const { t } = this.props;
        const free = t('sections.dashboard.free');
        const occupied = t('sections.dashboard.occupied');
        const dataFree = 100-this.props.firstData;
        const dataOccupied = this.props.firstData;
        const dataPieChart = (canvas) => {
            return {
                labels: [occupied,free],
                datasets: [
                    {
                        data: [dataFree,dataOccupied],
                        backgroundColor: ['rgb(205, 180, 219)', 'rgb(250, 250, 250)'],
                        
                    }
                ]
            }
        }
        return (
            <div className="p-5 md:mr-4 rounded-lg transition duration-300 ease-in-out hover:bg-white hover:shadow-lg text-3xl md:text-center flex">
                <div>
                    <span className="font-strong">{this.props.firstData}</span><span className="font-extralight">{this.props.secondData}</span><br />
                    <span className="font-extralight text-sm">{this.props.thirdData}</span>
                </div>
                {this.props.hasGraphic&&
                <div className="ml-2 -mt-1 px-5">
                    <Doughnut
                        width={'80%'}
                        height={'80%'}
                        data={dataPieChart}
                        legend={false}
                        options={{
                            tooltips: { 
                                enabled: false
                            }
                        }}
                    />
                </div>
                }
            </div>
        )
    }
}
export default withTranslation()(KPI)
