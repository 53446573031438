/**
  * Give a number a specific language-sensitive format.
  *
  * Converts a number to a string in specific language-sensitive format.
  * Currently supports the following formats:
  * - properCurrency: format the number to a proper currency format with monetary symbol
  * - currency: format the number to a currency formats
  * - rounded: format the number to a rounded formats
  * - short: format the number to a short formats (e.g. 1.2M)
  * - nomarker: remove dots and commas from the number (ie. 1,234,567.89 => 123456789)
  * - withcommas: retro compatability with the old format function numberWithCommas
  * - cpi: shows the first 2 decimals after de 0 if the number is < 1
  * - ecpm: remove decimals if number > 1
  * - impacts: If number == '0.00' then number = '0'
  *
  * @param {Number} number The number to formats
  * @param {String} format Type of format to be used
  * @param {String} localeOverride Override the navigator language
  *
  * @return {String} The formatted number
  **/
export const formatNumber = (number, format, localeOverride, options) => {
    let toFormat = parseFloat(number);

    if (options?.dashIfZero && toFormat === 0) { return '-' }
    // Clean up commas
    if (number && typeof number === "string") {
        toFormat = number.replace(/,/g, "");
    }

    if ((format !== 'withcommas') && isNaN(toFormat)) { return "-"; }

    let locale = navigator.languages ? navigator.languages[0] : navigator.language;
    if (localeOverride) { locale = localeOverride }

    let formatedNumber;

    // NOTE: If options == 'dashIfZero' and number == 0 or == 0.00 returns '-'

    switch (format) {
        case "properCurrency":
            // This selects comma or dot as the decimal separator
            switch (options.currency) {
                case 'ARS': case 'MXN': case 'BRL': case 'EUR': case 'CLP': case 'SOL':
                    locale = 'es-AR';
                    break;
                default:
                    locale = 'en-US';
                    break;
            }
            formatedNumber = new Intl.NumberFormat(locale, {
                style: "currency",
                currencyDisplay: "narrowSymbol",
                currency: options.currency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(toFormat).toString().replace(/\xa0/g, '');
            break;

        case "currency":
            formatedNumber = new Intl.NumberFormat(locale, {
                style: "decimal",
                minimumFractionDigits: options?.decimals ? options?.decimals : 0,
                maximumFractionDigits: options?.decimals ? options?.decimals : 2,
            }).format(toFormat);
            break;
        case "rounded":
            formatedNumber = new Intl.NumberFormat(locale, {
                style: "decimal",
                minimumFractionDigits: 0,
            }).format(Math.round(toFormat));
            break;
        case "short":
            formatedNumber = new Intl.NumberFormat(locale, {
                style: "decimal",
                minimumFractionDigits: 0,
                maximumFractionDigits: 1,
                notation: "compact",
            }).format(toFormat);
            // In some languages, compact notation adds a non-breakable character after the number
            // SEE: https://stackoverflow.com/questions/54242039
            formatedNumber = formatedNumber.toString().replace(/\xa0/g, '')
            break;
        case "nomarker":
            // NOTE: This removes commas and dots from the number. Maybe we can
            // detect the decimal separator and remove only the dots or only the commas.
            formatedNumber = toFormat.toString().replace(/\./g, '').replace(/,/g, '');
            break;
        case "withcommas":
            // HACK: This is a hack to get retro compat with the number with commas
            formatedNumber = numberWithCommas(number);
            break;
        case "cpi":
            if (toFormat < 1) {
                formatedNumber = new Intl.NumberFormat(locale, {
                    style: "decimal",
                    minimumFractionDigits: 0,
                    maximumSignificantDigits: 2,
                }).format(toFormat).toString().replace(/\xa0/g, '');
            } else {
                formatedNumber = toFormat
            }
            break
        case "ecpm":
            if (toFormat > 1) {
                let truncated = Math.trunc(toFormat)

                formatedNumber = new Intl.NumberFormat(locale, {
                    style: "decimal",
                    maximumFractionDigits: 1
                }).format(truncated).toString().replace(/\xa0/g, '');
            } else if (toFormat > 0 && toFormat < 1) {
                let fixedNumber = Number(toFormat).toFixed(2)
                formatedNumber = fixedNumber
            }
            break;
        case "impacts":
            formatedNumber = toFormat === 0 ? toFormat = '0' : toFormat;
            break;
        default:
            formatedNumber = toFormat.toString();
    }

    return formatedNumber
}
/**
  * Converts a number to a float with 2 decimal places in english locale.
  *
  * Checks if the number passed is a number and if it is a number it converts it to a string float with
  * 2 decimal places. Otherwise it returns the string "0".
  *
  * @deprecated   22.04.1 Use formatNumber(number, format) instead
  */
 export const numberWithCommas = (number) => {
    // Return raw number allways
    if (number === 0 || number === "")
        return "0";
    else
        return parseFloat(number).toLocaleString("en");
};
