import React, { Component } from 'react';
import { WizardHeader } from './WizardHeader';
import { withTranslation } from 'react-i18next';
import NavigationWizard from './NavigationWizard';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import StepFive from './StepFive';
import ConfirmationStep from './ConfirmationStep';
import cloneDeep from 'lodash.clonedeep';
import { getGroups, getRestrictions, getScreenByID } from './AJAXCalls';

class Wizard extends Component {
    // state = { sin usar por ahora
    //     default_ads: false,
    //     restrictions: []
    // }

    constructor(props) {
        super(props);
        this.state = {
            currentStep: 1,
            action: '',
            title: '',
            screenData: {},
            stepsData: {
                stepOne: {
                    name: '',
                    groups: [],
                    group: '-1',
                    pixel_size_width: '',
                    pixel_size_height: '',
                    position_x_canvas: '',
                    position_y_canvas: '',
                    rotations:[],
                    rotation: 0
                },
                stepTwo: {
                    ad_duration: '',
                    total_spots: '',
                    location: {
                        coordinates: {
                            lat: '',
                            lng: ''
                        },
                        address: ''
                    },
                    schedule: [{
                            day: this.props.t('common.days.monday'),
                            start: '',
                            end: ''
                        },
                        {
                            day: this.props.t('common.days.tuesday'),
                            start: '',
                            end: ''
                        },
                        {
                            day: this.props.t('common.days.wednesday'),
                            start: '',
                            end: ''
                        },
                        {
                            day: this.props.t('common.days.thursday'),
                            start: '',
                            end: ''
                        },
                        {
                            day: this.props.t('common.days.friday'),
                            start: '',
                            end: ''
                        },
                        {
                            day: this.props.t('common.days.saturday'),
                            start: '',
                            end: ''
                        },
                        {
                            day: this.props.t('common.days.sunday'),
                            start: '',
                            end: ''
                        }
                    ],
                    auto_launch : false,
                    allDaysSwitchStatus: true,
                    brightness: []
                },
                stepThree: {
                    size_width_meters: '',
                    size_height_meters: '',
                    floor_distance: '',
                    orientation: '',
                    quality: '',
                    brand: ''
                },
                stepFour: {
                    description: '',
                    close_areas: '',
                    screen_type: '',
                    area_target: '',
                    images: []
                },
                stepFive: {
                    min_prints: '',
                    run_time: '',
                    aspectRatio: '',
                    programmatic_view: true
                }
            }
        }
    }

    componentDidMount = () => {
        this.getGroups();
        this.getRotations();
        // this.getRestrictions();
        let data = "";
        if (this.props.location.state) {
            data = this.props.location.state.data;
        }
        let title = "";
        if (data.action) {
            if (data.action === "clone") {
                title = this.props.t('sections.wizard.cloneScreenTitle');
            } else if (data.action === "edit") {
                title = this.props.t('sections.wizard.editScreenTitle');
            } else {
                title = this.props.t('sections.wizard.newScreenTitle');
            }
        }
        this.setState({title});
        this.setState({ action: data.action })

        if (data) {
            getScreenByID(data.screen).then(r => {
                    let screen_data = r.data;

                    if (data.action === 'clone') {
                        delete screen_data.images;
                        delete screen_data.electron_id;
                    }
                    
                    let count = 0;
                    let dayStart = screen_data.schedule[0].start;
                    let dayEnd = screen_data.schedule[0].end;
                    screen_data.schedule.forEach(day => {
                        if (day.start === dayStart && day.end === dayEnd) {
                            count++;
                        }
                    })
                    screen_data.allDaysSwitchStatus = (count === 7);
                    this.setCurrentStep(1);
                    this.setState({stepsData: this.screenDataToStepsData(screen_data)});
                    this.setState({screenData: {_id: screen_data._id, _rev: screen_data._rev, electron_id: screen_data.electron_id, ssp_id: screen_data.SSPID}});
                })
                .catch(err => console.log(err))
        } else {
            this.setState({ action: "create" })
        }
    }

    screenDataToStepsData = (data) => {
        const screen_data = cloneDeep(data);
        const schedule = screen_data.schedule;

        return {
            stepOne: {
                name: screen_data.name,
                groups: cloneDeep(this.state.stepsData.stepOne.groups),
                group: screen_data.group,
                pixel_size_width: screen_data.pixel_size_width,
                pixel_size_height: screen_data.pixel_size_height,
                position_x_canvas: screen_data.position_x_canvas,
                position_y_canvas: screen_data.position_y_canvas,
                rotations:this.state.stepsData.stepOne.rotations,
                rotation:screen_data.rotation || 0,
            },
            stepTwo: {
                ad_duration: screen_data.ad_duration,
                total_spots: screen_data.total_spots,
                location: {
                    coordinates: {
                        lat: screen_data.location.coordinates.lat,
                        lng: screen_data.location.coordinates.lng
                    },
                    address: screen_data.location.address
                },
                schedule: [{
                        day: this.props.t('common.days.monday'),
                        start: schedule[0].start,
                        end: schedule[0].end
                    },
                    {
                        day: this.props.t('common.days.tuesday'),
                        start: schedule[1].start,
                        end: schedule[1].end
                    },
                    {
                        day: this.props.t('common.days.wednesday'),
                        start: schedule[2].start,
                        end: schedule[2].end
                    },
                    {
                        day: this.props.t('common.days.thursday'),
                        start: schedule[3].start,
                        end: schedule[3].end
                    },
                    {
                        day: this.props.t('common.days.friday'),
                        start: schedule[4].start,
                        end: schedule[4].end
                    },
                    {
                        day: this.props.t('common.days.saturday'),
                        start: schedule[5].start,
                        end: schedule[5].end
                    },
                    {
                        day: this.props.t('common.days.sunday'),
                        start: schedule[6].start,
                        end: schedule[6].end
                    }
                ],
                auto_launch : screen_data.auto_launch,
                allDaysSwitchStatus: screen_data.allDaysSwitchStatus,
                brightness: screen_data.brightness
            },
            stepThree: {
                size_width_meters: screen_data.size_width_meters,
                size_height_meters: screen_data.size_height_meters,
                floor_distance: screen_data.floor_distance,
                orientation: screen_data.orientation,
                quality: screen_data.quality,
                brand: screen_data.brand
            },
            stepFour: {
                description: screen_data.description,
                close_areas: screen_data.close_areas,
                screen_type: screen_data.screen_type,
                area_target: screen_data.area_target,
                images: screen_data.images ? screen_data.images : []
            },
            stepFive: {
                min_prints: screen_data.min_prints,
                run_time: screen_data.run_time,
                aspectRatio: screen_data.aspectRatio,
                programmatic_view: screen_data.programmatic_view
            }
        }
    }

    startTimeLessEndTime = () => {
        let startTimeHigherEndTime = true;
        let i = 0;
        const {schedule} = this.state.stepsData.stepTwo;

        while (startTimeHigherEndTime && i <= 6) {
          const day = schedule[i];
    
          if (day.start !== '' && day.end !== '' && day.start >= day.end)
            startTimeHigherEndTime = false;
    
          i++;
        }
    
        return startTimeHigherEndTime;
      }

    setCurrentStep = (step) => this.setState({currentStep: step});

    stepOneIsCompleted = () => {
        const {name, pixel_size_width, pixel_size_height, position_x_canvas, position_y_canvas} = this.state.stepsData.stepOne;

        return (name !== '' && pixel_size_width !== '' && pixel_size_height !== '' && position_x_canvas !== '' && position_y_canvas !== '');
    }

    stepTwoIsCompleted = () => {
        const {total_spots, ad_duration, location} = this.state.stepsData.stepTwo;

        return (total_spots !== '' && ad_duration !== '' && location.address !== '' && this.atLeastOneDay() && this.daysAreComplete());
    }

    atLeastOneDay = () => {
        let thereIsOneDay = false;
    
        this.state.stepsData.stepTwo.schedule.forEach(schedule => {
          if (schedule.start !== '' && schedule.end !== '')
            thereIsOneDay = true;
        });
    
        return thereIsOneDay;
    }

    daysAreComplete = () => {
        let areComplete = true;
    
        this.state.stepsData.stepTwo.schedule.forEach(schedule => {
          if ((schedule.start !== '' && schedule.end === '') || (schedule.start === '' && schedule.end !== '')) {
            areComplete = false;
          }
        });
    
        return areComplete;
    }



    getGroups = () => {
        return new Promise(async(resolve, reject) => {
            const response = await getGroups();
            
            if (response.status !== 200) {
                reject();
                return;
            }

            const stepsData = cloneDeep(this.state.stepsData);
            stepsData.stepOne.groups = response.data;
            this.setState({ stepsData }, () => resolve(true));
        });
    }
    setGroup = (id) => {
        const stepsData = cloneDeep(this.state.stepsData);
        stepsData.stepOne.group = id;
        this.setState({stepsData});
    }
    getRotations = () => {
        let rotations = [0,90,-90,180];
        const stepsData = cloneDeep(this.state.stepsData);
        stepsData.stepOne.rotations = rotations;
        this.setState({stepsData}, () =>{
            //console.log(this.state.stepsData.stepOne.rotations);
        });
    }

    getRestrictions = async() => {
        const response = await getRestrictions();
        if (response.status === 200) 
            this.setState({ restrictions: response.data });
    }

    handleChange = (field, e) => {
        const stepsData = cloneDeep(this.state.stepsData);
        const stepName = this.getCurrentStepName();

        stepsData[stepName][field] = e.target.value;

        this.setState({stepsData});
    }

    getCurrentStepName = () => {
        const {currentStep} = this.state;
        
        let stepName = '';

        if (currentStep === 1) stepName = 'stepOne';
        if (currentStep === 2) stepName = 'stepTwo';
        if (currentStep === 3) stepName = 'stepThree';
        if (currentStep === 4) stepName = 'stepFour';
        if (currentStep === 5) stepName = 'stepFive';

        return stepName;
    }

    handleLocation = () => {
        const obj = localStorage.getItem('screen');

        if (obj) {
            const stepsData = cloneDeep(this.state.stepsData);
            stepsData.stepTwo.location = JSON.parse(obj);
            this.setState({stepsData});
        }
    }

    handleNumber = field => e => {
        const input = e.target;
        const lastChar = input.value.charAt(input.value.length - 1);
        const isNumber = /^[-+]?[0-9]+$/.test(lastChar);
        const stepName = this.getCurrentStepName();

        if (isNumber || input.value === '' || input.value === '-') {
            const stepsData = cloneDeep(this.state.stepsData);
            stepsData[stepName][field] = input.value;
            this.setState({stepsData});
        }
    }
    handleNumberWithDecimals = field => e => {
        const input = e.target;
        const ultimoCaracter = input.value;
        const esNumero = /^[0-9]{1,2}([,.][0-9]{0,2})?$/.test(ultimoCaracter);
        const stepName = this.getCurrentStepName();

        if (esNumero || input.value === '') {
            const stepsData = cloneDeep(this.state.stepsData);
            stepsData[stepName][field] = input.value;
            
            this.setState({stepsData});
        }
    }

    handleString = field => e => {
        const input = e.target;
        const ultimoCaracter = input.value.charAt(input.value.length - 1);
        const esNumero = /^\d+$/.test(ultimoCaracter);
        const stepName = this.getCurrentStepName();

        if (esNumero) {
            const stepsData = cloneDeep(this.state.stepsData);
            stepsData[stepName][field] = input.value;
            this.setState({stepsData});
        }
    }

    handleSchedule = (obj) => {
        const stepsData = cloneDeep(this.state.stepsData);
        stepsData.stepTwo.schedule = obj;
        this.setState({ stepsData });
    }

    clearSchedule = () => {
        const stepsData = cloneDeep(this.state.stepsData);
        stepsData.stepTwo.schedule.forEach(day => {
            day.start = '';
            day.end = '';
        });
        this.setState({ stepsData });
    }

    handleGroup = e => {
        const select = e.target;
        const stepsData = cloneDeep(this.state.stepsData);
        stepsData.stepOne.group = select.value;
        this.setState({ stepsData });
    }
    handleSimpleSelect = field => e => {
        const select = e.target;
        const value = select.options[select.selectedIndex].value;
        const stepsData = cloneDeep(this.state.stepsData);
        const stepName = this.getCurrentStepName();
        stepsData[stepName][field] = value;
        this.setState({stepsData});
    }

    handleCheckboxBoolean = e => {
        const boolean = e.target.checked;
        const stepsData = cloneDeep(this.state.stepsData);
        stepsData.stepFour.default_ads = boolean;
        this.setState({stepsData});
    }

    setAspectRatio = (e) => {
        const stepsData = cloneDeep(this.state.stepsData);

        if (e.getAttribute("id") === "imgRelation") {
            if (e.checked)
                stepsData.stepFive.aspectRatio = 'image';
        } else if (e.getAttribute("id") === "screenRelation") {
            if (e.checked)
                stepsData.stepFive.aspectRatio = 'screen';
        }

        this.setState({stepsData});
    }
    setAutoLaunch = () => {
        const stepsData = cloneDeep(this.state.stepsData);
        stepsData.stepTwo.auto_launch = !stepsData.stepTwo.auto_launch;
        this.setState({stepsData});
    }
    setProgrammaticVisibility = () => {
        const stepsData = cloneDeep(this.state.stepsData);
        stepsData.stepFive.programmatic_view = !stepsData.stepFive.programmatic_view;
        this.setState({stepsData});
    }

    handleDaysSwitch = () => {
        const stepsData = cloneDeep(this.state.stepsData);
        stepsData.stepTwo.allDaysSwitchStatus = !stepsData.stepTwo.allDaysSwitchStatus;
        this.setState({stepsData}, () => this.clearSchedule());
    }

    handleImages = (e) => {
        e.preventDefault();
        const input = e.target;
        const files = input.files;
        const stepsData = cloneDeep(this.state.stepsData);
        const images = stepsData.stepFour.images;
        const mergedFiles = Array.prototype.slice.call(files).concat(Array.prototype.slice.call(images));
        stepsData.stepFour.images = mergedFiles;
        this.setState({ stepsData });
    }

    deleteCurrentImage = (index) => {
        const stepsData = cloneDeep(this.state.stepsData);
        const newImages = stepsData.stepFour.images;
        newImages.splice(index, 1);
        this.setState({ stepsData });
    }

    getScreenData = () => {
        const {screenData} = this.state;

        let data = {};
        if (screenData._id) {
            data._id = screenData._id;
            data._rev = screenData._rev;
            data.electron_id = screenData.electron_id;
            data.ssp_id = screenData.ssp_id;
        }
        return data;
    }

    /**
     * It takes an object and an index, and if the index is not undefined, it sets the object at that
     * index in the array, otherwise it pushes the object to the array.
     * @param brightnessSettings - {}
     * @param [index] - the index of the array that you want to update.
     */
    handleBrightnessOption = (brightnessSettings, index = undefined) => {
        let stepsData = cloneDeep(this.state.stepsData);
        if(!stepsData.stepTwo.brightness){
            stepsData.stepTwo.brightness = [];
        }
        if(!this.checkTimeConflict(stepsData.stepTwo.brightness, {brightnessSettings, index})){
            if(index !== undefined){
                stepsData.stepTwo.brightness[index] = brightnessSettings;
            }else{
                if(stepsData.stepTwo.brightness.length < 12){
                    stepsData.stepTwo.brightness.push(brightnessSettings);
                }else{
                    this.props.showNotification({
                        type: "error",
                        text: this.props.t("common.notification.brightness.limitReach")
                    })
                }
            }
        }else{
            this.props.showNotification({
                type: "error",
                text: this.props.t("common.notification.brightness.timeError")
            })
        }
        this.setState({ stepsData });
    }

    checkTimeConflict = (array, optionToCheck) => {
        let valueToCheck = optionToCheck.brightnessSettings;
        let arrayItemWithConflict = array.find((value, index) => value.start <= valueToCheck.start && valueToCheck.start < value.end && index !== optionToCheck.index)
        let thereIsConflict = false;
        if(arrayItemWithConflict){
            thereIsConflict = true;
        }
        return thereIsConflict;
    }

    /**
     * Delete the brightness option at the given index from the stepsData object in state.
     * @param index - the index of the option to be deleted
     */
    deleteBrightnessOption = (index) => {
        let stepsData = cloneDeep(this.state.stepsData);
        stepsData.stepTwo.brightness.splice(index, 1);
        this.setState({ stepsData });
    }

    renderStep = (step) => {
        const {stepsData, currentStep} = this.state;

        const {
            name,
            pixel_size_width,
            pixel_size_height,
            position_x_canvas,
            position_y_canvas,
            group,
            groups,
            rotation,
            rotations
        } = stepsData.stepOne;
        const {
            ad_duration,
            total_spots,
            schedule,
            location,
            auto_launch,
            allDaysSwitchStatus,
            brightness
        } = stepsData.stepTwo;

        const {
            size_width_meters,
            size_height_meters,
            floor_distance,
            orientation,
            quality,
            brand
        } = stepsData.stepThree;

        const {
            description,
            area_target,
            close_areas,
            screen_type,
            images
        } = stepsData.stepFour;

        const {
            min_prints,
            run_time,
            aspectRatio,
            programmatic_view
        } = stepsData.stepFive;

        const values = {
            name,
            pixel_size_width,
            pixel_size_height,
            position_x_canvas,
            position_y_canvas,
            ad_duration,
            total_spots,
            group,
            schedule,
            groups,
            rotation,
            rotations,
            location,
            size_width_meters,
            size_height_meters,
            description,
            floor_distance,
            orientation,
            brand,
            close_areas,
            // default_ads,
            area_target,
            screen_type,
            quality,
            // restrictions,
            min_prints,
            run_time,
            images,
            allDaysSwitchStatus,
            aspectRatio,
            auto_launch,
            programmatic_view,
            brightness
        };
        switch (step) {
            case 1:
                return ( <
                    StepOne setCurrentStep = { this.setCurrentStep }
                    currentStep={currentStep}
                    stepOneIsCompleted = {this.stepOneIsCompleted}
                    handleChange = { this.handleChange }
                    handleNumber = { this.handleNumber }
                    handleGroup = { this.handleGroup }
                    handleSimpleSelect = { this.handleSimpleSelect }
                    setGroup = { this.setGroup }
                    getGroups = { this.getGroups }
                    showNotification = { this.props.showNotification }
                    values = { values }
                    />
                )

            case 2:
                return ( <
                    StepTwo setCurrentStep = { this.setCurrentStep }
                    stepTwoIsCompleted = {this.stepTwoIsCompleted}
                    currentStep={currentStep}
                    startTimeLessEndTime={this.startTimeLessEndTime}
                    handleNumber = { this.handleNumber }
                    handleChange = { this.handleChange }
                    handleLocation = { this.handleLocation }
                    handleSchedule = { this.handleSchedule }
                    clearSchedule = { this.clearSchedule }
                    handleDaysSwitch = { this.handleDaysSwitch }
                    showNotification = { this.props.showNotification }
                    setAutoLaunch = {this.setAutoLaunch }
                    handleBrightnessOption = {this.handleBrightnessOption}
                    deleteBrightnessOption = {this.deleteBrightnessOption}
                    values = { values }
                    />
                )

            case 3:
                return ( <
                    StepThree setCurrentStep = { this.setCurrentStep }
                    handleNumber = { this.handleNumber }
                    handleNumberWithDecimals = { this.handleNumberWithDecimals }
                    currentStep={currentStep}
                    handleChange = { this.handleChange }
                    handleSimpleSelect = { this.handleSimpleSelect }
                    values = { values }
                    />
                )

            case 4:
                return ( <
                    StepFour setCurrentStep = { this.setCurrentStep }
                    handleNumber = { this.handleNumber }
                    handleChange = { this.handleChange }
                    handleCheckboxBoolean = { this.handleCheckboxBoolean }
                    handleSimpleSelect = { this.handleSimpleSelect }
                    currentStep={currentStep}
                    handleImages = { this.handleImages }
                    deleteCurrentImage = { this.deleteCurrentImage }
                    values = { values }
                    />
                )

            case 5:
                return ( <
                    StepFive setCurrentStep = { this.setCurrentStep }
                    handleNumber = { this.handleNumber }
                    handleChange = { this.handleChange }
                    currentStep={currentStep}
                    handleCheckboxBoolean = { this.handleCheckboxBoolean }
                    handleSimpleSelect = { this.handleSimpleSelect }
                    setAspectRatio = { this.setAspectRatio }
                    setProgrammaticVisibility = {this.setProgrammaticVisibility}
                    values = { values }
                    />
                )

            case 6:
                return ( <
                    ConfirmationStep 
                    setCurrentStep = { this.setCurrentStep }
                    currentStep={currentStep}
                    values = { values }
                    action = { this.state.action }
                    getScreenData = { this.getScreenData }
                    showNotification = { this.props.showNotification }
                    setLoading = { this.props.setLoading }
                    />
                )

            default:
                break;
        }
    }

    render() {
        const {currentStep, title} = this.state;

        return (
            <div className="relative w-11/12 pr-6">
                <WizardHeader title={title} bulkButton={this.props.t('sections.wizard.sheetImport.bulkImportIntro')}/>
                <NavigationWizard startTimeLessEndTime={this.startTimeLessEndTime} stepOneIsCompleted={this.stepOneIsCompleted} stepTwoIsCompleted={this.stepTwoIsCompleted} setCurrentStep={this.setCurrentStep} currentStep={currentStep} showNotification={this.props.showNotification}/>
                {this.renderStep(currentStep)}
            </div>
        );
    }
}

export default withTranslation()(Wizard);