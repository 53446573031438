import React from 'react';

import CardOOH from './CardOOH'
import CardDOOH from './CardDOOH'


const BoardCard = (props) => {
    const { content } = props;
    return (
        <>
            {content &&
                <div style={{ maxWidth: "250px" }} className='bg-white rounded shadow p-2 m-2 cursor-pointer '  >
                    {props.contentType === "DOOH" ?
                        <CardDOOH  props={props} />
                        :
                        <CardOOH  props={props} />
                    }
                </div>
            }
        </>
    )
}
export default BoardCard;
