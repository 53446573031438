import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ManagementHeader from './ManagementHeader';
import * as dotenv from 'dotenv';
import EditComScreenModal from '../utils/EditComScreenModal';
import EditCustomerModal from '../utils/EditCustomerModal';

// import EditUserModal from '../utils/EditUserModal';
import { getScreens, getUser, addCustomer, getCustomers, getBrands, addBrand, setCurrencyOnUser, getUserData, removeItem, modifyCustomers, editTableManagement, verifyMail, modifyBrand, setProgrammatic, setPublisherID, getAllSSPID, setMailing, putObject, changeCommercializeString, addCameraConfig, getAllCameras, editCameraConfig, deleteCameraConfig, triggerCameraChanges } from './Management.Functions';
import { validateMail } from '../utils/auth';
import { register } from '../Log_Reg/UserFunctions'
import CreateModal from '../utils/CreateModal';
// import CreateUserModal from '../utils/CreateUserModal';
import DecisionModal from '../utils/DecisionModal';
import currency from '../../util_files/currency.json';
import iabCategoriesEN from '../../util_files/iab_categories_en.json';
import cloneDeep from 'lodash.clonedeep';
import ReactTooltip from 'react-tooltip';
import NavBar from './subcomponents/navbar/NavBar';
import GeneralLayout from './subcomponents/layouts/GeneralLayout';
import FinanceLayout from './subcomponents/layouts/FinanceLayout';
import CommercialLayout from './subcomponents/layouts/CommercialLayout';
import ProgrammaticLayout from './subcomponents/layouts/ProgrammaticLayout';
import PlayersLayout from './subcomponents/layouts/PlayersLayout';
import CertificationsLayout from './subcomponents/layouts/CertificationsLayout';
import MailingLayout from './subcomponents/layouts/MailingLayout';
import CameraABMModal from '../utils/Cameras/CameraABMModal';
import isEqual from 'lodash.isequal';
import CameraViewModal from '../utils/Cameras/CameraViewModal';
import EditBrandModal from '../utils/EditBrandModal';
// import UsersLayout from './subcomponents/layouts/UsersLayout';
import { getProgrammaticImpressionsRequests, getProgrammaticScreensRequest } from '../reports/reportRequests'
dotenv.config();

class Management extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActiveSection: "finance",
            isActiveCommercialMenu: "pricing",
            isActivePlayerMenu: "players",
            isActiveCameraConfiguration: "screen",
            isModalCommercialDealOpen: false,
            isActiveMailingMenu: "contactMail",
            editRow: [],
            user: {},
            customers: [],
            brands: [],
            screens: [],
            currency: currency,
            actualCurrency: "",
            actualRow: "",
            publisher_id: "",
            toggledClearRows: false,
            iab_categories: [],
            programmaticScreens: [],
            publisherScreens: [],
            emailsAdresses: [],
            periodicityMail: "",
            cameraToEdit: {
                type: 'camera',
                focus: 'screen',
                description: '',
                stream: '',
                sharable: false
            },
            cameraToDelete: null

        }
    }

    /**
     * Fields that will be displayed in "Create Customer" modal
     */
    customersFields = [
        { name: 'company', value: this.props.t('sections.management.section.commercial.customers.customerTable.company'), field_type: "input", value_type: "text" },
        { name: 'contact_name', value: this.props.t('sections.management.section.commercial.customers.customerTable.contact'), field_type: "input", value_type: "text" },
        { name: 'email', value: this.props.t('sections.management.section.commercial.customers.customerTable.mail'), field_type: "input", value_type: "text" },
        { name: 'phone', value: this.props.t('sections.management.section.commercial.customers.customerTable.phone'), field_type: "input", value_type: "text" },
        { name: 'discount', value: this.props.t('sections.management.section.commercial.customers.customerTable.discount'), field_type: "input", value_type: "number" }
    ];
    /**
     * Fields that will be displayed in "Create Brand" modal
     */
    brandValue = [
        { name: 'name', value: this.props.t('sections.management.section.commercial.brands.brandName'), field_type: "input", value_type: "text" },
        { name: 'categories', value: this.props.t('sections.management.section.commercial.brands.brandCategory'), field_type: 'select' }
    ];

    usersFields = ['name', 'rol', 'mail', 'phone'];
    /**
     * Brings the screens with a default pagination
     */
    getScreens = async () => {
        try {
            let response = await getScreens()
            if (response.status === 200) {
                let screens = response.data;
                this.setState({ screens: screens })
            }
        } catch (error) {
            console.log(error);
        }
    }

    componentDidMount = () => {
        this.getScreens()
        getUser().then(r => {
            if (r.status === 200) {
                this.setState({ user: r.data });
            }
        })
            .catch(err => {
                console.log(err);
                this.props.showNotification({
                    type: "error",
                    text: this.props.t('sections.management.users.errorGetUser')
                });
            });
        getCustomers().then(r => {
            if (r.status === 200) {
                let customers = r.data;
                this.setState({ customers });
            }
        })
            .catch(err => console.log(err));
        getBrands().then(r => {
            if (r.status === 200) {
                let brands = r.data;
                this.setState({ brands });
            }
        })
            .catch(err => console.log(err));
        getUserData().then(r => {
            if (r.status === 200) {
                this.setState({ emailsAdresses: r.data.mails });
                this.setState({ periodicityMail: r.data.periodicity });
                this.setState({ actualCurrency: r.data.currency }, () => {
                    this.setState({ publisher_id: r.data.publisher_id }, () => {
                        this.setProgrammaticCPM();
                        this.parseDataOfPublisherScreens();
                    });

                });
            }
        });
        this.getCameras();
        this.filterCategories();
        if (this.props.location.state) {
            this.setState({ isActiveSection: this.props.location.state.data.isActiveSection })
            this.props.closeNotification()
        }
    }
    /**
     * Get the cameras and set it to the state.
     * @return {void}
     */
    async getCameras() {
        try {
            let camerasResponse = await getAllCameras();
            if (camerasResponse.status === 200) {
                let cameras = camerasResponse.data;
                this.setState({ cameras });
            } else {
                this.props.showNotification({
                    type: "error",
                    text: this.props.t('sections.management.section.cameras.errorGetting')
                });
            }
        } catch (error) {
            this.props.showNotification({
                type: "error",
                text: this.props.t('sections.management.cameras.errorGetting')
            });
        }
    }
    /**
    * Return the status of switcher general
    * @param {*} allInTrue
    * @returns boolean
    */
    getComercializeStatus = (allInTrue) => {
        if (allInTrue) {
            if (this.state.user.commercialize_all_by === "seconds") {
                return true;
            }
        }
        return false;
    }
    /**
     * Return the status of switcher in the table
     * @param {*} value
     * @returns boolean
     */
    statusSwicher = (value) => {
        if (value === "seconds") {
            return true;
        }
        return false;
    }
    /**
     * Return a boolean that say if all the switchest in the tabla are in seconds
     * @returns  boolean
     */
    isAllSwitchInTrue = () => {
        let screens = cloneDeep(this.state.screens);
        let commercialize_by = screens.map(e => e.commercialize_by);
        if (!commercialize_by.includes("impressions")) {
            return true;
        }
        return false;
    }
    /**
     * set the switch general white the data that has pass for parameter
     * @param {*} user with the seasson open
     * @param {*} commercialize_all_by the value of the switch
     */
    setPriceGeneralOfUser = async (user, commercialize_all_by) => {
        try {
            user.commercialize_all_by = commercialize_all_by;
            let response = await putObject(user);
            if (response.status === 200) {
                user._rev = response.data.rev;
                this.setState({ user: user });
            }
        } catch (error) {
            console.log(error);
            this.props.showNotification({
                type: "error",
                text: this.props.t('common.notification.management.commercial.pricing.errorDisplayPrice')
            });
        }
    }
    /**
     * Set the switch general with the data that has pass for parameter
     * @param {*} id of the screen
     * @param {*} commercialize_by the value of the switch
     */
    setCommercializeScreenBy = async (id, commercialize_by) => {
        let screens = cloneDeep(this.state.screens);
        let screen = screens.find(screen => screen._id === id);
        let user = cloneDeep(this.state.user);

        if (commercialize_by === "impressions") {
            screen.commercialize_by = "seconds";
        } else {
            screen.commercialize_by = "impressions";
            this.setPriceGeneralOfUser(user, "impressions");
        }
        try {
            let response = await putObject(screen);
            if (response.status === 200) {
                screen._rev = response.data.rev;
                this.setState({ screens: screens });
                this.props.showNotification({
                    type: "success",
                    text: this.props.t('common.notification.management.commercial.pricing.ScreenPriceChanged')
                });
            }
            if (this.isAllSwitchInTrue()) {
                this.setPriceGeneralOfUser(user, "seconds");
            }
        } catch (error) {
            this.props.showNotification({
                type: "error",
                text: this.props.t('common.notification.management.commercial.pricing.errorDisplayPrice')
            });
        }
    }
    /**
     * Change the value of all Switches in the table with the value passed for parameter
     * @param {*} value
     */
    setCommercializeAllScreensBy = async (value) => {
        let screens = cloneDeep(this.state.screens);
        let user = cloneDeep(this.state.user);
        let screensIds = screens.map(screen => screen._id);

        try {
            let response = await changeCommercializeString(value, screensIds);
            if (response.status === 200) {
                user._rev = response.data.user.rev;
                screens.forEach(screen => {
                    let object = response.data.screens.find(dbScreen => dbScreen.id === screen._id);
                    screen._rev = object.rev;
                    if (value === "seconds") {
                        screen.commercialize_by = "seconds";
                        user.commercialize_all_by = "seconds";
                    } else {
                        screen.commercialize_by = "impressions";
                        user.commercialize_all_by = "impressions";
                    }
                })
                this.setState({ user: user, screens: screens });
                this.props.showNotification({
                    type: "success",
                    text: this.props.t('common.notification.management.commercial.pricing.ScreenPriceChanged')
                });
            }
        } catch (error) {
            console.log(error);
            this.props.showNotification({
                type: "error",
                text: this.props.t('common.notification.management.commercial.pricing.errorDisplayPrice')
            });
        }
    }
    /**
     * Returns the opposite value that the user has
     * @returns
     */
    getCommercializeString = () => {
        let user = cloneDeep(this.state.user);
        if (user.commercialize_all_by && user.commercialize_all_by === "impressions") {
            return "seconds";
        }
        return "impressions";
    }
    /**
     * Function that gets today Date in format:
     * YYMMDDHH
     * @returns
     */
    getToday = () => {
        let now = Date.now();
        let todayDate = new Date(now);
        let parsedToday = todayDate.getFullYear().toString().slice(2, 4) + ("0" + (todayDate.getMonth() + 1).toString()).slice(-2) + ("0" + todayDate.getDate().toString()).slice(-2) + ("0" + todayDate.getHours().toString()).slice(-2)
        return parsedToday;
    }
    /**
     * Checking if the width and height of the screen in the database is equal to the width and height of the screen in the state.
     * @param {Object} screen
     * @returns boolean that represents if param screen and programmatic screen have equal dimensions
     */
    itemAndProgrammaticHasEqualDimension = (screen) => {
        let publisherScreens = cloneDeep(this.state.publisherScreens);
        let test;
        publisherScreens.forEach(publisherScreen => {
            let progID = publisherScreen.id.split('_')[1];
            if (progID === screen.SSPID) {
                test = (Number(screen.pixel_size_width) === Number(publisherScreen.width) && Number(screen.pixel_size_height) === Number(publisherScreen.height));
            }
        });
        return test;
    }

    /**
     * Function that parse programmatic screens and set in state the array.
     */
    parseDataOfPublisherScreens = async () => {
        let publisherID = cloneDeep(this.state.publisher_id);
        let programmaticScreens = await this.getProgrammaticScreens(publisherID);
        let publisherScreens = [];
        if (programmaticScreens) {
            Object.keys(programmaticScreens.programmaticScreens).forEach(key => {
                let progScreen = programmaticScreens.programmaticScreens[key];
                if (Number(progScreen.publisher.id.split('_')[1]) === Number(publisherID)) {
                    let screen = {
                        id: progScreen.id,
                        width: progScreen.width,
                        height: progScreen.height,
                    }
                    publisherScreens.push(screen);
                }
            });
        }
        this.setState({ publisherScreens });
    };

    /**
     * Function that fetch preogrammatic screen from screens API
     * @param {String} publisherID SSP ID of Publisher
     * @returns If the response is successful, returns a programmaticScreens Object with an Array of Objects. If there is an error, it displays an error notification and returns null.
     * @example Successful Response
     * @returns {Object} {programmaticScreens:
     *      0: {id: 'tgx_5488', name: 'AR - Neuquen - METRO - Leloir y Avenida Argentina', …}
     *      1: {id: 'tgx_5524', name: 'AR - Neuquen - Av. Argentina y Roca', …}
     *  }
     * @example Error
     * @returns null
     */
    getProgrammaticScreens = async (publisherID) => {
        try {
            let screenResponse = (await getProgrammaticScreensRequest(publisherID)).data;

            return screenResponse

        } catch (error) {
            this.props.showNotification({
                type: "error",
                text: this.props.t('common.notification.reports.getProgScreenError') //Error al traer pantallas programaticas
            });
            return null;
        }
    }

    /**
     * Asynchronous function that retrieves programmatic impression statistics for a given publisher and currency, calculates the CPM
    */
    setProgrammaticCPM = async () => {
        let actualDate = this.getToday();
        let publisher_id = this.state.publisher_id;
        let currency = this.state.actualCurrency;
        let programmaticImpressionResponse = {}
        let allSSPIDResponse = {}

        if (!publisher_id || !currency) {
            this.props.showNotification({
                type: "error",
                text: this.props.t('common.notification.management.programmatic.screenWithProgrammaticwarning')
            });
            return;
        }

        this.setState({ isLoading: true })

        try {
            programmaticImpressionResponse = await getProgrammaticImpressionsRequests('01010100', actualDate, 'tag', publisher_id, currency);
            allSSPIDResponse = await getAllSSPID()
        } catch (error) {
            this.props.showNotification({
                type: 'error',
                text: this.props.t('sections.management.serverErr')
            });
        }

        if (programmaticImpressionResponse.status === 200 && allSSPIDResponse.status === 200) {
            let programmaticStats = programmaticImpressionResponse.data.records.filter(record => allSSPIDResponse.data.includes(record.id)).map(record => {
                let impresion = record.values[7];
                let revenue = record.values[16];
                let cpm = ((revenue / impresion) * 1000);
                cpm = cpm ? "$ " + (cpm.toFixed(2)) : cpm = "-";
                return {
                    id: record.id,
                    cpm: cpm
                }
            });
            let screens = cloneDeep(this.state.screens);
            let screensToShow = screens.filter(screen => {
                let screenProgrammaticStat = programmaticStats.filter(stat => stat.id === screen.SSPID);
                if (screenProgrammaticStat.length > 0) {
                    screen.cpm = screenProgrammaticStat[0].cpm;
                    return screen;
                } else
                    return null;
            })
            this.setState({ programmaticScreens: screensToShow }, () => {
                this.setState({ isLoading: false })
            });
        } else {
            this.props.showNotification({
                type: 'error',
                text: this.props.t('sections.management.serverErr')
            });
        }
    }

    /**
     * Function that create an object of type "customer" and fill it with the values given by props.
     * Then post it to DB
     * @param {Array} customerValues
     */
    addCustomer = (customerValues = []) => {
        customerValues.forEach(v => {
            v.name = v.name.toLowerCase();
            v.name = v.name.replace(' ', '_');
            v.name = v.name.replace(' ', '');
            v.name = v.name.replace('%', '');
        })
        let customer = {
            type: "customer"
        };
        customerValues.forEach(customerValue => {
            customer[customerValue.name] = customerValue.value;
        });
        customer.brands = [];
        addCustomer(customer)
            .then(r => {
                if (r.status === 201) {
                    this.props.showNotification({
                        type: 'success',
                        text: this.props.t('sections.management.customerCreated')
                    });
                    getCustomers().then(r => {
                        if (r.status === 200) {
                            let customers = r.data;
                            this.setState({ customers });
                        }
                    })
                }
            })
            .catch(err => {
                this.props.showNotification({
                    type: 'error',
                    text: this.props.t('sections.management.serverErr')
                });
            })
    }
    /**
     * Function that create an object of type "brand" and fill it with the values given by props.
     * Then post it to DB
     * @param {Array} customerValues
     */
    addBrand = (brandValue = []) => {
        brandValue.forEach(b => {
            b.name = b.name.toLowerCase();
            b.name = b.name.replace(' ', '_');
            b.name = b.name.replace(' ', '');
        })
        let brand = {
            type: "brand"
        };
        brandValue.forEach(value => {
            brand[value.name] = value.value;
        });
        addBrand(brand).then(r => {
            if (r.status === 201) {
                this.props.showNotification({
                    type: 'success',
                    text: this.props.t('sections.management.brandCreated')
                });
                getBrands().then(r => {
                    if (r.status === 200) {
                        let brands = r.data;
                        this.setState({ brands });
                    }
                })
            } else {
                this.props.showNotification({
                    type: 'error',
                    text: this.props.t('sections.management.brandCreateErr')
                });
            }
        }).catch(err => {
            this.props.showNotification({
                type: 'error',
                text: this.props.t('sections.management.serverErr')
            });
            console.log(err);
        })
    }
    /**
     * Function that set the property "type" of param object with value "user".
     * Then if there is no problem with mail format, post the user into DB.
     * @param {Object} user
     */
    // addUser = (user) => {
    //     user.type = "user";
    //     user.complete_name = user.first_name + " " + user.last_name;
    //     if(!validateMail(user.email)) {
    //         this.props.showNotification({
    //           text: this.props.t('sections.register.mailFormat'),
    //           type: "warning",
    //           duration: 3000
    //         });
    //         return;
    //     }
    //     addNewUser(user).then(r => {
    //         if (r.status === 201) {
    //             this.props.showNotification({
    //                 type: 'success',
    //                 text: this.props.t('common.notification.management.users.userCreated')
    //             });
    //             getUsers().then(r => {
    //                 if(r.status === 200){
    //                     let users = r.data;
    //                     this.setState({users});
    //                 }
    //             })
    //         } else if(r.status === 409){
    //             this.props.showNotification({
    //                 type: 'warning',
    //                 text: this.props.t('common.notification.management.users.existingUser')
    //             });
    //         }
    //         else {
    //             this.props.showNotification({
    //                 type: 'error',
    //                 text: this.props.t('common.notification.management.users.userCreateErr')
    //             });
    //         }
    //     }).catch(err=> {
    //         this.props.showNotification({
    //             type: 'error',
    //             text: this.props.t('sections.management.serverErr')
    //         });
    //         console.log(err);
    //     })

    // }
    /**
     * When the publisher_id changes, set the state of the publisher_id to the value of the target.
     * @param e - the event object
     */
    onChangePublisherId = (e) => {
        this.setState({ publisher_id: e.target.value })
    }
    /**
     * Change the value of "emailsAdresses" in state
     * @param {Event} e
     */
    onChangeEmailAdresses = (e) => {
        let mails = e.target.value.replace(" ", "").split(',');
        this.setState({ emailsAdresses: mails })
    }
    /**
     * The function takes in a value and sets the state of the component to the value.
     * @param value - The value of the section that was clicked.
     */
    handleSections(value) {
        this.setState({ isActiveSection: value });
    }
    /**
     * When the user clicks on the Commercial Menu, the state of the Commercial Menu is set to true.
     * @param value - true or false
     */
    handleCommercialMenu(value) {
        this.setState({ isActiveCommercialMenu: value });
    }
    /**
     * Change the value of "isActiveMailingMenu" in state
     * @param {String} value
     */
    handleMailingMenu(value) {
        this.setState({ isActiveMailingMenu: value });
    }
    /**
     * This function sets the state of the modal to true or false depending on the value passed to it.
     * @param e - is the row number
     * @param value - true/false
     */
    toggleCommercialCustomersModal = (e, value) => {
        this.setState({ editRow: e });
        this.setState({ isModalCommercialDealOpen: value });

    }
    /**
     * This function is called when a user clicks on a button to edit a row in a table. The function
     * sets the state of the component to the row that was clicked on, and then opens a modal window.
     * @param e - is the object that is being passed to the function
     * @param value - true/false
     */
    toggleCommercialScreen = (e, value) => {
        let filteredObj = {
            id: e._id,
            rev: e._rev,
            name: e.name,
            ad_value: e.adValue,
            currency: e.currency
        };
        this.setState({ editRow: filteredObj });
        this.setState({ isModalCommercialDealOpen: value });
    }
    /**
     * It takes an event and a value, and sets the state of the component to the value of the event.
     * @param e - is the object that I'm passing to the function
     * @param value - true/false
     */
    toggleUserModal = (e, value) => {
        let filteredArr = {
            id: e._id,
            rev: e._rev,
            name: e.complete_name,
            email: e.email,
            phone: e.phone_number,
            role: e.userRol
        };
        this.setState({ editRow: filteredArr })
        this.setState({ isModalCommercialDealOpen: value });
    }

    /**
     * The function takes a value as an argument and sets the state of the component to the value
     * passed in.
     * @param value - The value of the section that was clicked.
     */
    handleVisibility = (value) => {
        this.setState({ isActiveSection: value });
    }
    /**
     * "handleOnChangeCurrency" is a function that is called when a user selects a currency from a
     * dropdown menu.
     *
     * The function then calls another function called "setCurrencyOnUser" which is an API call that
     * sets the currency on the user.
     *
     * If the API call is successful, the user's currency is set.
     *
     * If the API call is unsuccessful, the user is notified.
     * @param e - the event object
     */
    handleOnChangeCurrency = (e) => {
        let currency = e.target.value;
        if (currency !== 0) {
            setCurrencyOnUser(currency).then(r => {
                if (r.status === 200) {
                    this.props.showNotification({
                        type: 'success',
                        text: this.props.t('common.notification.management.currencySetted'),
                        duration: 3000
                    });
                } else if (r.status === 409) {
                    this.props.showNotification({
                        type: 'error',
                        text: this.props.t('common.notification.management.currencyConflict'),
                        duration: 3000
                    });
                } else {
                    this.props.showNotification({
                        type: 'error',
                        text: this.props.t('common.notification.serverError'),
                        duration: 3000
                    });
                }
            })
        }
    }
    /**
     * Function that update a customer document in a database, and then update the state of the
     * component with the new data.
     * @param e - the event object
     * @param brandId - the id of the brand
     */
    handleOnChangeBrands = (e, brandId) => {
        let customerID = e.value;
        let customers = [...this.state.customers];
        let customerToModify = customers.find(customer => customer._id === customerID);
        let customersToModify = [];
        if (customerID !== "-1") {
            let previousCustomer = customers.find(customer => {
                if (customer.brands && customer.brands.includes(brandId)) {
                    return customer;
                } else {
                    return undefined;
                }
            });
            if (previousCustomer) {
                previousCustomer.brands.forEach((brand, i) => {
                    if (brand === brandId) {
                        previousCustomer.brands.splice(i, 1);
                    }
                });
                customersToModify.push(previousCustomer);
            }
            if (customerToModify.brands) {
                if (customerToModify.brands instanceof Array && !customerToModify.brands.includes(brandId)) {
                    customerToModify.brands.push(brandId);
                }
            } else {
                let brandArr = [brandId];
                customerToModify.brands = brandArr;
            }
            if (customerToModify !== previousCustomer) {
                customersToModify.push(customerToModify);
            }
            modifyCustomers(customersToModify).then(r => {
                if (r.status === 201) {
                    customers.forEach(customer => {
                        r.data.forEach(doc => {
                            if (customer._id === doc.id) {
                                customer._rev = doc.rev;
                            }
                        })
                    });
                } else {
                    console.log('err in .then', r);
                }
            })
                .catch(err => {
                    console.log(err);
                })
        } else {
            let customerWithBrand = customers.filter(customer => {
                if (customer.brands && customer.brands.includes(brandId)) {
                    return customer;
                } else {
                    return undefined;
                }
            });
            if (customerWithBrand) {
                customerWithBrand[0].brands.forEach((brand, i) => {
                    if (brand === brandId) {
                        customerWithBrand[0].brands.splice(i, 1);
                    }
                });
            }
            modifyCustomers(customerWithBrand).then(r => {
                if (r.status === 201) {
                    customers.forEach(customer => {
                        r.data.forEach(doc => {
                            if (customer._id === doc.id) {
                                customer._rev = doc.rev;
                            }
                        })
                    });
                }
            })
                .catch(err => {
                    this.props.notifications({
                        type: 'error',
                        text: this.props.t('common.notification.management.customer.customerError'),
                        duration: 3000
                    })
                })
        }
        getBrands().then(r => {
            this.setState({ brands: [] });
            if (r.status === 200) {
                let brands = r.data;
                this.setState({ brands });
            }
        })
    }
    /**
     * It takes an array of categories and a brand ID, then it loops through the brands in state, finds
     * the brand with the matching ID, and sets the categories of that brand to the categories passed
     * in.
     * @param e - the selected categories
     * @param id - the id of the brand
     */
    handleChangeBrand = (brand) => {
        let data = [];
        modifyBrand(brand).then(r => {
            if (r.data.ok) {
                getBrands().then(r => {
                    if (r.status === 200) {
                        data = r.data;
                    }
                    this.setState({
                        brands: data
                    }, () => {
                        this.props.showNotification({
                            type: 'success',
                            text: this.props.t('common.notification.management.commercial.brands.editedBrand'),
                            duration: 3000
                        });
                    })
                })
                    .catch(err => console.log(err));
            }
        }).catch(err => console.log(err));
    }
    /**
     * Function that remove an item from a table, and then delete the item from DB.
     */
    removeItemFromTable = () => {
        let row = this.state.actualRow.row;
        removeItem(row._id).then(r => {
            if (r.status === 200) {
                let type = this.state.actualRow.type, data = [];
                switch (type) {
                    case "customers":
                        getCustomers().then(r => {
                            if (r.status === 200) {
                                data = r.data;
                            }
                        })
                            .catch(err => console.log(err));
                    case "brands":
                        getBrands().then(r => {
                            if (r.status === 200) {
                                data = r.data;
                            }
                        })
                            .catch(err => console.log(err));
                }
                this.setState({
                    [type]: data
                }, () => {
                    this.props.showNotification({
                        type: 'success',
                        text: this.props.t('common.notification.management.removeItem.success'),
                        duration: 3000
                    });
                })
            } else {
                this.props.showNotification({
                    type: 'error',
                    text: this.props.t('common.notification.management.removeItem.error'),
                    duration: 3000
                });
            }
        })
            .catch(err => console.log(err))
    }

    /**
     * It deletes a user from the database and then updates the state of the component with the new
     * list of users.
     * @param id - the id of the user to be deleted
     */
    // deleteUser = (id) => {
    //     deleteUserWithRole(id).then(r => {
    //         if(r.status === 200){
    //             this.setState({users: []});
    //             getUsers().then(r => {
    //                 if(r.status === 200){
    //                     let users = r.data;
    //                     users.forEach(user => {
    //                         user.complete_name = user.first_name + " " + user.last_name;
    //                     });
    //                     this.setState({users});
    //                     this.props.showNotification({
    //                         type: 'error',
    //                         text: this.props.t('common.notification.management.removeItem.success'),
    //                         duration : 3000
    //                     });
    //                  } else {
    //                     this.props.showNotification({
    //                         type: 'error',
    //                         text: this.props.t('common.notification.management.removeItem.error'),
    //                         duration : 3000
    //                     });
    //                  }
    //             }).catch(err => console.log(err));
    //         }
    //     })
    // }

    /**
     * It deletes a brand from the database and then updates the state of the component with the new
     * list of brands.
     * @param id - the id of the item to be deleted
     */
    /* deleteBrand = (id) => {
        deleteBrand(id).then(r => {
            if (r.status === 200) {
                this.setState({ brands: [] });
                getBrands().then(r => {
                    if (r.status === 200) {
                        let brands = r.data;
                        this.setState({ brands });
                        this.props.showNotification({
                            type: 'success',
                            text: this.props.t('common.notification.management.removeItem.success'),
                            duration: 3000
                        });
                    } else {
                        this.props.showNotification({
                            type: 'error',
                            text: this.props.t('common.notification.management.removeItem.error'),
                            duration: 3000
                        });
                    }
                }).catch(err => console.log(err));
            }
        }).catch(err => console.log(err));
    } */

    /**
     * It takes a screen object, finds the screen in the state, updates the screen object with the new
     * values, and then updates the state with the new screen object.
     * @param editedScreen - {
     */
    editComScreen = (editedScreen) => {
        let screens = [...this.state.screens];
        let edScreen = screens.find(screen => screen._id === editedScreen._id);
        edScreen.adValue = editedScreen.ad_value;
        edScreen.currency = editedScreen.currency;
        editTableManagement(edScreen)
            .then(r => {
                if (r.status === 200) {
                    edScreen._rev = r.data.rev;
                    this.setState({ screens: [] }, () => {
                        this.setState({ screens });
                    });
                    this.props.showNotification({
                        type: 'success',
                        text: this.props.t('common.notification.management.commercial.pricing.screenEditSuccess'),
                        duration: 3000
                    });
                } else {
                    this.props.showNotification({
                        type: 'error',
                        text: this.props.t('common.notification.management.editError'),
                        duration: 3000
                    });
                }
            })
            .catch(
                err => console.log(err)
            )
    }
    /**
     * It takes an object as a parameter, finds the object in the state array, and updates the object
     * in the state array with the new values.
     * @param editedCustomer - {
     */
    editCustomer = (editedCustomer) => {
        let customers = [...this.state.customers];
        let edCustomer = customers.find(customer => customer._id === editedCustomer._id);
        if (editedCustomer.company)
            edCustomer.company = editedCustomer.company;
        if (editedCustomer.email)
            edCustomer.email = editedCustomer.email;
        if (editedCustomer.phone)
            edCustomer.phone = editedCustomer.phone;
        if (editedCustomer.discount)
            edCustomer.discount = editedCustomer.discount;
        editTableManagement(edCustomer)
            .then(r => {
                if (r.status === 200) {
                    edCustomer._rev = r.data.rev;

                    this.setState({ customers: [] }, () => {
                        this.setState({ customers });
                    });
                    this.props.showNotification({
                        type: 'success',
                        text: this.props.t('common.notification.management.commercial.customers.customerEditSuccess'),
                        duration: 3000
                    });
                } else {
                    this.props.showNotification({
                        type: 'error',
                        text: this.props.t('common.notification.management.editError'),
                        duration: 3000
                    });
                }
            })
            .catch(
                err => console.log(err)
            )
    }
    /**
     * Function to edit a user in a table, but before I do that, I want to check if the email is
     * already in use. If it is, I want to show a notification. If it isn't, I want to edit the user.
     *
     * @param editedUser - An object that contains the values for the user to update
     */
    editUser = (editedUser) => {
        let users = [...this.state.users];
        let edUser = users.find(user => user._id === editedUser._id);
        verifyMail(editedUser.email).then(r => {
            if (r.status === 200) {
                this.props.showNotification({
                    type: 'warning',
                    text: this.props.t('common.notification.management.users.existingUser'),
                    duration: 3000
                });
                return;
            }
        }).catch(err => console.log(err))
        if (editedUser.email)
            edUser.email = editedUser.email;
        if (editedUser.phone_number)
            edUser.phone_number = editedUser.phone_number;
        edUser.userRol = editedUser.user_rol;
        edUser.company = editedUser.company;
        editTableManagement(edUser)
            .then(r => {
                if (r.status === 200) {
                    edUser._rev = r.data.rev;

                    this.setState({ users: [] }, () => {
                        this.setState({ users });
                    });
                    this.props.showNotification({
                        type: 'success',
                        text: this.props.t('common.notification.management.users.userEditSuccess'),
                        duration: 3000
                    });
                } else {
                    this.props.showNotification({
                        type: 'error',
                        text: this.props.t('common.notification.management.editError'),
                        duration: 3000
                    });
                }
            })
            .catch(
                err => console.log(err)
            )
    }
    /**
     * It takes an array of objects, and creates a new array of objects with only two properties from
     * the original array.
     */
    filterCategories = () => {
        let filtered_categories = [];
        iabCategoriesEN.forEach(cat => {
            let filt_cat = {};
            filt_cat.label = cat.Name;
            filt_cat.value = cat.UniqueID;
            filtered_categories.push(filt_cat);
        })
        this.setState({ iab_categories: filtered_categories });
    }
    /**
     * It takes an array of objects, and returns an array of objects with the same keys, but with
     * different values.
     * @returns An array of objects.
     */
    filteredCustomers = () => {
        let filtered_customers = [];
        this.state.customers.forEach(customer => {
            let filt_customer = {};
            filt_customer.label = `${customer.company} - ${customer.contact_name}`;
            filt_customer.value = customer._id;
            filtered_customers.push(filt_customer);
        })
        return filtered_customers;
    }
    /**
     * It takes an id, finds the screen with that id, toggles the programmatic_view property, and then
     * updates the database with the new value.
     * @param id - the id of the screen
     */
    handleProgrammatic = (id) => {
        let screens = cloneDeep(this.state.screens);
        let screen = screens.find(screen => screen._id === id);
        screen.programmatic_view = !screen.programmatic_view;
        setProgrammatic(screen).then(r => {
            if (r.status === 200) {
                screen._rev = r.data.rev;
                this.setState({ screens: [] }, () => {
                    this.setState({ screens }, () => {
                        this.props.showNotification({
                            type: 'success',
                            text: this.props.t('common.notification.management.switchChanged'),
                            duration: 3000
                        });
                    });
                });
            } else {
                this.props.showNotification({
                    type: 'error',
                    text: this.props.t('common.notification.management.editError'),
                    duration: 3000
                })
            }
        })
            .catch(err => console.log(err));
    }
    /**
     * Function that update de Publisher ID for the logged user.
     */
    setPublisherID = () => {
        let publisher_id = this.state.publisher_id;
        if (publisher_id !== "") {
            setPublisherID(publisher_id).then(r => {
                if (r.status === 200) {
                    this.props.showNotification({
                        type: 'success',
                        text: this.props.t('common.notification.management.programmatic.setedPublisherID'),
                        duration: 3000
                    });
                } else {
                    this.props.showNotification({
                        type: 'error',
                        text: this.props.t('common.notification.serverError'),
                        duration: 3000
                    });
                }
            }).catch(err => console.log(err));
        }
    }
    /**
     * Changes the periodicity that the mails are sended to the user
     */
    setPeriodicityMail = async () => {
        let response = {};
        let error = null;
        try {
            response = await setMailing({ mails: this.state.emailsAdresses, periodicity: this.state.periodicityMail });
        } catch (err) {
            error = err;
            this.props.showNotification({
                type: 'error',
                text: this.props.t('common.notification.serverError'),
                duration: 3000
            });
        } finally {
            if (error === null) {
                if (response) {
                    this.props.showNotification({
                        type: 'success',
                        text: this.props.t('common.notification.management.mailing.mailSeted'),
                        duration: 3000
                    });
                }
            }
        }
    }
    /**
     * When the button is clicked, toggle the state of toggleClearRows to the opposite of what it
     * currently is.
     */
    toggleClearRows = () => {
        this.setState({ toggleClearRows: !this.state.toggleClearRows })
    }
    /**
     * It takes a brandID as a parameter, and returns the customerID of the customer that has that
     * brandID in their brands array.
     * @param brandID - the brand ID that we want to find the customer for
     * @returns The customer ID of the customer that has the brand ID.
     */
    getCustomerWithBrand = (brandID) => {
        let customers = [...this.state.customers];
        let customerToReturn = customers.find(customer => customer.brands.includes(brandID));
        if (customerToReturn) {
            return customerToReturn._id;
        }
        return -1;
    }
    /**
     * It takes a JSON object as a parameter, and if the email is valid, it sends a POST request to the
     * server, and if the server returns a 201 status code, it shows a success notification, if it
     * returns a 409 status code, it shows a warning notification, and if it returns any other status
     * code, it shows an error notification.
     * @param values - JSON object with values for the user fields.
     */
    registerOtherRol = (values) => {
        let userData = {
            type: "user",
            first_name: values.first_name,
            last_name: values.last_name,
            passsword: values.passsword,
            email: values.email,
            country: values.country,
            phone_number: values.phone_number,
            company: values.company,
            user_rol: values.user_rol,
        };
        if (!validateMail(userData.email)) {
            this.props.showNotification({
                text: this.props.t('sections.register.mailFormat'),
                type: "warning",
                duration: 3000
            });
            return;
        }
        register(userData).then(res => {
            if (res.status === 201) {
                this.props.showNotification({
                    text: this.props.t('sections.register.success'),
                    type: "success",
                    duration: 3000
                })
            }
            else if (res.status === 409) {
                this.props.showNotification({
                    text: this.props.t('sections.register.userAlreadyExists'),
                    type: "warning",
                    duration: 3000
                })
            }
            else {
                this.props.showNotification({
                    text: this.props.t('common.notification.serverError'),
                    type: "error",
                    duration: 3000
                })
            }
        })
            .catch(err => {
                console.log('catch del register', err);
                this.props.showNotification({
                    text: this.props.t('common.notification.serverError'),
                    type: "error",
                    duration: 3000
                })
            })
    }
    /**
     * It returns a span with a tooltip.
     * @param badge - The text to display in the badge
     * @param tooltip - The text that will be displayed in the tooltip
     * @param id - The id of the item
     * @returns A JSX component.
     */
    itemNeedTooltip = (badge, tooltip, id) => {
        return (
            <>
                <ReactTooltip effect="solid" className="p-2" id={"contentOutOfDateTooltip" + id} aria-haspopup='true'>
                    <p className="text-center max-w-sm">
                        {tooltip}
                    </p>
                </ReactTooltip>
                <span data-tip data-for={"contentOutOfDateTooltip" + id} className="ml-2 p-1 rounded font-semibold">{badge}</span>
            </>
        );
    }
    /**
     * Creates an array that has the options of the periodicity select.
     * @returns an array that contains objects with label, value.
     */
    getPeriodicityOptions = () => {
        let options = [10, 15, 20, 30, 60, 120];
        return options.map(option => {
            return {
                label: option,
                value: option
            };
        });
    }
    /**
     * Handle the state change periodicityMail.
     * @param {*} e event prop.
     */
    handlePeriodicityOnMailContact = (e) => {
        this.setState({ periodicityMail: e.value });
    }
    /** Get the emails from contact on the state and accumulate them into a string.
     *
     * @returns all the mails accumulated.
     */
    parseEmailsAdresses = () => {
        let emailsAdresses = cloneDeep(this.state.emailsAdresses);
        if (emailsAdresses.length === 0) {
            return "";
        };
        return emailsAdresses.reduce((acc, val) => {
            acc += "," + val;
            return acc;
        });
    }
    /**
     * Change the value of "actualRow" in state, then open a modal
     * @param {String} type
     * @param {Object} row
     */
    removeRow = (type, row) => {
        this.setState({
            actualRow: {
                type,
                row
            }
        }, () => {
            this.props.openModal("removeRow");
        })
    }
    /**
     * @ignore Not in use
     * Change de value of "rowToDelete" in state, then open a modal
     * @param {Object} row
     */
    handleRemoveUser = (row) => {
        this.setState({
            rowToDelete: row
        }, () => {
            this.props.openModal('removeUser')
        })
    }
    /**
     * Change the values of "cameraModalAction" and "cameraToEdit" on state, then open a modal
     * @param {String} action
     * @param {Object} camera
     */
    handleEditCameraConfig = (action, camera = {}) => {
        this.setState({
            cameraModalAction: action,
            cameraToEdit: camera
        }, () => {
            this.props.openModal('CameraConfigModal');
        })
    }
    /**
     * Send camera object depending on the option param value
     * @param {Object} camera
     * @param {Integer} option
     * @returns {Object} response
     */
    sendCameraConfig = async (camera, option = null) => {
        let response = null;
        if (!this.cameraConfigHaveSomeFieldEmpty(camera) && !isEqual(camera, {})) {
            try {
                if (!option) {
                    response = await addCameraConfig(camera);
                } else {
                    response = await editCameraConfig(camera);
                }
                await triggerCameraChanges(camera.screen);
            } catch (error) {
                response = error;
                console.log(error);
            } finally {
                if (response.status === 200 || response.status === 201) {
                    this.props.showNotification({
                        text: option ? this.props.t('common.notification.management.certifications.edited') : this.props.t('common.notification.management.certifications.created'),
                        type: 'success'
                    })
                } else {
                    this.props.showNotification({
                        text: this.props.t('common.notification.serverError'),
                        type: 'error'
                    });
                }
                return response;
            }
        } else {
            return response;
        }
    }
    /**
     * Checks if camera object has some empty field
     * @param {Object} cameraConfig
     * @returns
     */
    cameraConfigHaveSomeFieldEmpty = (cameraConfig) => {
        let fieldEmpty = false;
        Object.keys(cameraConfig).forEach(field => {
            if (cameraConfig[field] === '') {
                fieldEmpty = true;
            }
        });
        return fieldEmpty;
    }
    /**
     * Deletes a camera from database
     * @param {object} camera camera document
     */
    deleteCameraConfig = (camera) => {
        deleteCameraConfig(camera._id).then(async res => {
            if (res.status === 200) {
                this.props.showNotification({
                    text: this.props.t('common.notification.management.certifications.deleted'),
                    type: 'success'
                })
                await triggerCameraChanges(camera.screen);
                this.refreshCamerasData();
                this.closeModal('CameraConfigModal');
            }
        }).catch(err => {
            this.props.showNotification({
                text: this.props.t('common.notification.serverError'),
                type: 'error'
            })
            console.log(err)
        })
    }
    /**
     * Handle changes on a camera object and calls for a function depending on "option" param value
     * @param {Object} camera
     * @param {Integer} option
     */
    handleCameraConfigChange = async (camera, option) => {
        let { cameraModalAction } = this.state;
        let response = null;

        try {
            if (option === 1) {
                if (cameraModalAction === 'edit') {
                    response = await this.sendCameraConfig(camera, option);
                } else {
                    response = await this.sendCameraConfig(camera);
                }
            } else if (option === 2) {
                this.setState({ cameraToDelete: camera }, () => {
                    this.props.openModal('deleteCamera')
                })
            }
        } catch (error) {
            response = error;
        }
        finally {
            if (response) {
                this.closeModal('CameraConfigModal');
                this.refreshCamerasData();
            }
        }
    }



    /**
     * Re-fetch for cameras
     */
    refreshCamerasData = () => {
        this.setState({
            cameras: []
        }, () => {
            getAllCameras().then(r => {
                if (r.status === 200) {
                    let cameras = r.data;
                    this.setState({
                        cameras
                    });
                }
            })
                .catch(err => console.log(err));
        })
    }
    /**
     * Set the value of "cameraLive" on state and then open a modal
     * @param {Object} camera
     * @param {String} screenID
     */
    openLive = (camera, screenID) => {
        let screenName = this.state.screens.find(screen => screen._id === screenID)?.name;
        this.setState({
            cameraLive: {
                camera: [camera],
                screenName
            }
        }, () => this.props.openModal('CameraLiveModal'));
    }

    closeModal = (modalID) => {
        this.setState({
            cameraToEdit: {
                type: 'camera',
                focus: 'screen',
                description: '',
                screen: '',
                stream: '',
                sharable: false
            }
        }, () => {
            document.getElementById(modalID).classList.add('hidden');
        })
    }

    getParsedScreen = (screens, field = null) => {
        return screens.map(screen => {
            let parsedScreen = {
                _id: screen._id
            };
            if (field) {
                parsedScreen[field] = screen[field];
            }
            return parsedScreen;
        })
    }
    /**
     * Subrouting for management section
     * @param {String} activeSection
     * @returns
     */
    getActiveSection = (activeSection) => {
        const { currency, actualCurrency } = this.state;
        const { screens, customers, brands, iab_categories, user } = this.state;
        const { publisher_id, programmaticScreens } = this.state;
        const defaultOptionSelectPeriodicity = this.getPeriodicityOptions().find(option => option.value === this.state.periodicityMail);
        const { isActiveMailingMenu } = this.state;
        const { isActiveCameraConfiguration, cameras } = this.state;
        // const { users } = this.state;
        switch (activeSection) {
            case 'commercial':
                return <CommercialLayout
                    screens={screens} customers={customers} brands={brands} iab_categories={iab_categories} user={user}
                    statusSwicher={(value) => this.statusSwicher(value)}
                    filteredCustomers={this.filteredCustomers()}
                    getComercializeStatus={this.getComercializeStatus}
                    setCommercializeAllScreensBy={(value) => this.setCommercializeAllScreensBy(value)}
                    getCommercializeString={this.getCommercializeString}
                    setCommercializeScreenBy={(id, commercialize_by) => this.setCommercializeScreenBy(id, commercialize_by)}
                    toggleCommercialScreen={(e, value) => this.toggleCommercialScreen(e, value)}
                    toggleCommercialCustomersModal={(e, value) => this.toggleCommercialCustomersModal(e, value)}
                    getCustomerWithBrand={(id) => this.getCustomerWithBrand(id)}
                    handleOnChangeBrands={(e, id) => this.handleOnChangeBrands(e, id)}
                    handleChangeBrandCategories={(e, id) => this.handleChangeBrandCategories(e, id)}
                    removeRow={(type, row) => this.removeRow(type, row)}
                    openModal={(id) => this.props.openModal(id)} />
            case 'programmatic':
                return <ProgrammaticLayout
                    publisher_id={publisher_id} programmaticScreens={programmaticScreens}
                    onChangePublisherId={(e) => this.onChangePublisherId(e)}
                    setPublisherID={() => this.setPublisherID()}
                    itemNeedTooltip={(badge, tooltip, id) => this.itemNeedTooltip(badge, tooltip, id)}
                    itemAndProgrammaticHasEqualDimension={(screen) => this.itemAndProgrammaticHasEqualDimension(screen)}
                    handleProgrammatic={(screen_id) => this.handleProgrammatic(screen_id)} />
            case 'players':
                return <PlayersLayout showNotification={this.props.showNotification}/>
            case 'certifications':
                return <CertificationsLayout
                    isActiveCameraConfiguration={isActiveCameraConfiguration} screens={this.getParsedScreen(screens, "name")} cameras={cameras}
                    handleEditCameraConfig={(action, camera) => this.handleEditCameraConfig(action, camera)}
                    sendCameraConfig={() => this.sendCameraConfig()}
                    openLive={(camera, screenID) => this.openLive(camera, screenID)}
                />
            /* case 'users':
                return <UsersLayout
                users={users || []}
                openModal={(id) => this.props.openModal(id)}
                toggleUserModal={this.toggleUserModal}
                handleRemoveUser={this.handleRemoveUser}/> */
            case 'mailing':
                return <MailingLayout
                    isActiveMailingMenu={isActiveMailingMenu} defaultOptionSelectPeriodicity={defaultOptionSelectPeriodicity}
                    handleMailingMenu={this.handleMailingMenu}
                    parseEmailsAdresses={this.parseEmailsAdresses}
                    onChangeEmailAdresses={this.onChangeEmailAdresses}
                    handlePeriodicityOnMailContact={this.handlePeriodicityOnMailContact}
                    getPeriodicityOptions={this.getPeriodicityOptions}
                    setPeriodicityMail={this.setPeriodicityMail} />
            default:
                return <FinanceLayout currency={currency} actualCurrency={actualCurrency} handleOnChangeCurrency={(e) => this.handleOnChangeCurrency(e)} />

        }
    }
    /**
     * Handles the close of camera stream modal
     */
    onCloseViewModal = () => {
        this.setState({
            cameraLive: {}
        })
    }
    render() {

        const isActiveSection = this.state.isActiveSection;
        return (
            <>
                <ManagementHeader />
                <div className="relative w-full h-screen flex py-2">
                    <div className="w-full bg-opacity-30 bg-white p-2">
                        <NavBar activeSection={this.state.isActiveSection} handleSections={(value) => this.handleSections(value)} />
                        <GeneralLayout>
                            {this.getActiveSection(isActiveSection)}
                        </GeneralLayout>
                    </div>
                </div>
                <DecisionModal modalID="removeRow" callbackFunction={this.removeItemFromTable} buttonText="Aceptar" text="Desea borrar?"></DecisionModal>
                {/* <DecisionModal modalID="removeUser" callbackFunction={this.deleteUser} idParameter={this.state.rowToDelete?._id} buttonText="Aceptar" text={`Desea borrar la marca ${this.state.rowToDelete?.complete_name}?`}></DecisionModal> */}
                <EditComScreenModal modalID="editComScreenModal" values={this.state.editRow} callbackFunction={this.editComScreen}></EditComScreenModal>
                <EditCustomerModal modalID="editCustomerModal" values={this.state.editRow} callbackFunction={this.editCustomer}></EditCustomerModal>
                <EditBrandModal modalID="editBrandModal" values={this.state.editRow} callbackFunction={this.handleChangeBrand} customers={this.state.customers} categories={this.state.iab_categories} getCustomerWithBrand={this.getCustomerWithBrand} />
                {/* <EditUserModal modalID="editUserModal" values={this.state.editRow} callbackFunction={this.editUser} type="EditUser"></EditUserModal> */}
                <CreateModal modalID="createCustomerModal" type="Customer" values={this.customersFields} callbackFunction={this.addCustomer} showNotification={this.props.showNotification}></CreateModal>
                <CreateModal modalID="createBrandModal" type="Brand" values={this.brandValue} callbackFunction={this.addBrand} showNotification={this.props.showNotification} select_values={this.state.iab_categories}></CreateModal>
                {/* <CreateUserModal modalID="createUserModal" type="User" values={this.usersFields} callbackFunction={this.addUser} showNotification={this.props.showNotification}></CreateUserModal> */}
                <CameraABMModal
                    title={this.props.t('sections.management.section.cameras.modal.title')}
                    modalID={"CameraConfigModal"}
                    returnValue={(camera, callback) => this.handleCameraConfigChange(camera, callback)}
                    closeModal={(modalID) => this.closeModal(modalID)}
                    camera={this.state.cameraToEdit}
                    action={this.state.cameraModalAction}
                    screens={this.state.screens}
                    handleSetCameraConfigField={(camera, field, e) => this.handleSetCameraConfigField(camera, field, e)}
                    handleCameraConfigFocus={(camera) => this.handleCameraConfigFocus(camera)}
                    itemNeedTooltip={(badge, tooltip, id) => this.itemNeedTooltip(badge, tooltip, id)}
                />
                <DecisionModal modalID="deleteCamera" callbackFunction={() => this.deleteCameraConfig(this.state.cameraToDelete)} text={this.props.t('sections.modal.deleteModal.warning')}></DecisionModal>
                <CameraViewModal showNotification={this.props.showNotification} t={this.props.t} cameras={this.state.cameraLive?.camera} screenName={this.state.cameraLive?.screenName} modalID={"CameraLiveModal"} onCloseViewModal={this.onCloseViewModal} />
            </>
        )
    }
}

export default withTranslation()(Management);
