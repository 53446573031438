import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEqual from 'lodash.isequal';
import React, { Component, createRef } from 'react'
import ErrorMessage from '../common/ErrorMessage';
// import Loading from '../common/Loading';
import { withTranslation } from 'react-i18next';
import { isTunnelUp } from '../../screens/Screen.functions';
import i18n from 'i18next';


class CameraDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            camera: {},
            copied: false,
            loading: true,
            error: null
        }
        this.iframeRef = createRef(null);
    }

    componentDidMount = () => {
        this.setState({
            camera: this.props.camera
        }, () => {
            this.getHTMLFromCamera(this.state.camera, this.props.needScreenshot);
        })
    }
    componentDidUpdate = (prevProps) => {
        if (!isEqual(prevProps.camera, this.props.camera)) {
            this.setState({
                camera: this.props.camera,
                error: null
            }, () => {
                this.getHTMLFromCamera(this.state.camera, this.props.needScreenshot);
            })
        }
    }
    /**
     * Gets the camera stream.
     * @summary If the camera stream is available, appends the response of the camara into the iframe, if not,
     * shows an error message.
     * @param {Object} camera The entire camera object.
     * @param {Boolean} needScreenshot if the stream can capture screenshots and videos.
     */
    getHTMLFromCamera = async (camera, needScreenshot = true) => {
        let wssURL = `${camera.currentURL}/stream/${camera.electron_id}/${camera.order}/${needScreenshot ? 1 : 0}` + `?language=${i18n.language}`;
        if (camera.currentURL && camera.electron_id) {
            try {
                let response = await isTunnelUp(`${camera.currentURL}/screen/info`);
                response = response.data;
                if (response.status === 404) {
                    this.setState({
                        error: {
                            message: this.props.t('sections.management.section.cameras.view.noStream')
                        }
                    })
                } else if (response.status === 200 || response.status === 511) {
                    let iframe = this.iframeRef.current;
                    iframe.src = wssURL;
                }
            } catch (error) {
                this.setState({
                    error: {
                        message: this.props.t('sections.management.section.cameras.view.noStream')
                    }
                })
            }
        } 
        else {
            this.setState({
                error: {
                    message: this.props.t('sections.management.section.cameras.view.noStream')
                }
            })
        }
        this.setState({ loading: false })
    }

    render() {
        let { camera, copied, loading, error } = this.state;
        const sharableUrl = camera?.sharable ? `${window.location.origin}/shared/${camera.electron_id}/${camera.order}` : '';
        return (
            <div className='snap-children min-w-full flex flex-col mt-2 pb-2 h-full justify-center '>
                {!isEqual(camera, {}) &&
                    <>
                        {
                            error ?
                                <div className=' messageErrorBody'>
                                    <ErrorMessage message={error.message} />
                                </div>
                                :
                                <div className='messageErrorBody flex flex-col justify-center align-middle'>
                                    <p className='text-center uppercase tracking-wide text-gray-600 text-xs font-bold'>{camera.description}</p>
                                    <iframe ref={this.iframeRef} title={camera.description} allow="clipboard-read; clipboard-write" className={'overflow-hidden w-5/6 rounded custom-h-m  self-center flex justify-center items-center'}></iframe>

                                    {/* <div className={`mt-5 w-2/6 cursor-pointer border-b border-gray-500 flex items-center ${copied ? 'text-green-400' : ''} ${this.props.needLink && camera.sharable ? '' : 'hidden'}`} onClick={() => { navigator.clipboard.writeText(sharableUrl); this.setState({ copied: true }) }}>
                                        <input disabled className={`w-full text-center truncate ${camera.sharable ? '' : 'hidden'}`} value={sharableUrl} />
                                        <FontAwesomeIcon className='pl-1' icon={["fal", "copy"]} />
                                    </div> */}
                                </div>
                        }
                    </>
                }
            </div>
        )
    }
}
export default withTranslation()(CameraDisplay);
