import React, { Component } from 'react';
import { addScreen, editScreen } from './AJAXCalls';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { uploadFiles } from '../utils/StorageFunctions';

class ConfirmationStep extends Component {
    back = (e) => {
        e.preventDefault();
        const { setCurrentStep, currentStep} = this.props;
        setCurrentStep(currentStep - 1);
    }

    sumbitScreen = async(e) => {
        const submitBtn = e.target;
        submitBtn.disabled = true;
        this.props.setLoading(true);
        const values = this.props.values;
        let defAspectRatio = "";

        const location = {
            type: "Point",
            coordinates: values.location.coordinates,
            address: values.location.address
        }

        if(values.aspectRatio === ""){
            defAspectRatio = "screen"
        }else{
            defAspectRatio = values.aspectRatio
        }
        let data = {
            type: "screen",
            name: values.name,
            pixel_size_width: values.pixel_size_width,
            pixel_size_height: values.pixel_size_height,
            position_x_canvas: values.position_x_canvas,
            position_y_canvas: values.position_y_canvas,
            location: location,
            rotation : values.rotation,
            ad_duration: values.ad_duration,
            total_spots: values.total_spots,
            schedule: values.schedule,
            size_height_meters: values.size_height_meters,
            size_width_meters: values.size_width_meters,
            description: values.description,
            quality: values.quality,
            screen_type: values.screen_type,
            orientation: values.orientation,
            floor_distance: values.floor_distan
            ,
            area_target: values.area_target,
            run_time: values.run_time,
            brand: values.brand,
            close_areas: values.close_areas,
            default_ads: values.default_ads,
            min_prints: values.min_prints,
            images: values.images,
            aspectRatio: defAspectRatio,
            electron_id: values.electron_id,
            auto_launch: values.auto_launch,
            programmatic_view: values.programmatic_view,
            brightness: values.brightness
        }
        const groupID = values.group;

        if (groupID !== '-1')
            data.group = groupID;

        const images = values.images;
        const imagesToUpload = this.getImagesToUpload(images);

        if (imagesToUpload.length > 0) {
            const response = await uploadFiles(imagesToUpload);

            if (response.status === 201 && !response.data.error) {
                const createdFiles = response.data.filesArray;
                const imagesPath = [];

                createdFiles.forEach(created => {
                    const split = created.location.split('/');
                    imagesPath.push(split[split.length - 1]);
                });

                data.images = this.getStringImages(images).concat(imagesPath);
            } else {
                this.props.showNotification({
                    type: "error",
                    text: this.props.t('sections.wizard.confirmationStep.uploadImagesError'),
                    duration: 3000
                });
                this.props.setLoading(false);
                return;
            }
        }

        if (this.props.action === "create") {
            try {
                const response = await addScreen(data);

                if (response.status === 201) {
                    this.props.showNotification({
                        type: "success",
                        text: this.props.t('common.notification.confirmationStep.screenCreated'),
                        duration: 3000
                    });
                    this.props.setLoading(false);
                    setTimeout(() => {
                        this.props.history.push('/screens');
                    }, 3000);
                } else {
                    this.props.showNotification({
                        type: "error",
                        text: this.props.t('common.notification.confirmationStep.createScreenError')
                    });
                    submitBtn.disabled = false;
                    this.props.setLoading(false);
                }
            } catch (error) {
                this.props.showNotification({
                    type: "error",
                    text: this.props.t('common.notification.serverError')
                });
                submitBtn.disabled = false;
                this.props.setLoading(false);
            }
        } else if (this.props.action === "edit") {
            let new_data = data;
            new_data._id = this.props.getScreenData()._id;
            new_data._rev = this.props.getScreenData()._rev;
            new_data.electron_id = this.props.getScreenData().electron_id;
            new_data.SSPID = this.props.getScreenData().ssp_id;
            try {
                const response = await editScreen(new_data);

                if (response.status === 200) {
                    this.props.showNotification({
                        type: "success",
                        text: this.props.t('common.notification.confirmationStep.screenEdited'),
                        duration: 3000
                    });
                    this.props.setLoading(false);
                    setTimeout(() => {
                        this.props.history.push('/screens');
                    }, 3000);
                } else {
                    this.props.showNotification({
                        type: "error",
                        text: this.props.t('common.notification.confirmationStep.editScreenError')
                    });
                    submitBtn.disabled = false;
                    this.props.setLoading(false);
                }
            } catch (error) {
                this.props.showNotification({
                    type: "error",
                    text: this.props.t('common.notification.serverError')
                });
                submitBtn.disabled = false;
                this.props.setLoading(false);
            }
        } else if (this.props.action === "clone") {
            try {
                const response = await addScreen(data);

                if (response.status === 201) {
                    this.props.showNotification({
                        type: "success",
                        text: this.props.t('common.notification.confirmationStep.screenCloned'),
                        duration: 3000
                    });
                    this.props.setLoading(false);
                    setTimeout(() => {
                        this.props.history.push('/screens');
                    }, 3000);
                } else {
                    this.props.showNotification({
                        type: "error",
                        text: this.props.t('common.notification.confirmationStep.cloneScreenError')
                    });
                    submitBtn.disabled = false;
                    this.props.setLoading(false);
                }
            } catch (error) {
                this.props.showNotification({
                    type: "error",
                    text: this.props.t('common.notification.serverError')
                });
                submitBtn.disabled = false;
                this.props.setLoading(false);
            }
        }
    }

    getImagesToUpload = (images) => {
        return images.filter(image => (typeof image !== 'string' || !image instanceof String));
    }

    getStringImages = (images) => {
        return images.filter(image => (typeof image === 'string' || image instanceof String));
    }

    render() {
        return ( <div className = "w-full md:w-6/12 xl:w-4/12 p-5">
            <h1 className = "text-gray-800 text-xl font-bold" > { this.props.t('sections.wizard.confirmationStep.notificationTitle') } </h1>

            <p> { this.props.t('sections.wizard.confirmationStep.notificationBody') } </p>

            <button className = "buttonSecondary"
            onClick = { this.back } > { this.props.t('common.buttons.back') } </button> 
            <button className = "buttonPrimary"
            onClick = { this.sumbitScreen } > { this.props.t('common.buttons.submit') } </button>
            </div>
        );
    }
}

export default withRouter(withTranslation()(ConfirmationStep));