import React from 'react'

const Title = ({ children }) => {
    return (
        <label className="block text-gray-700 text-sm font-bold mb-2">
            {children}
        </label>
    )
}

export default Title