import React, { Component } from 'react';
import {withTranslation} from 'react-i18next';
import CarouselModal from './CarouselModal';

class StepFour extends Component {
    back = (e) => {
        e.preventDefault();
        const { setCurrentStep, currentStep} = this.props;
        setCurrentStep(currentStep - 1);
    }
    
    continue = (e) => {
        e.preventDefault();
        const { setCurrentStep, currentStep} = this.props;
        setCurrentStep(currentStep + 1);
    }

    toggleCarouselModal() { //Modal
        const body = document.querySelector('body')
        const modal = document.querySelector('.modal')
        modal.classList.toggle('opacity-0')
        modal.classList.toggle('pointer-events-none')
        body.classList.toggle('modal-active')
    }

    carouselEventAdd() { //Modal
        var openmodal = document.querySelectorAll('.modal-open')
        for (var i = 0; i < openmodal.length; i++) {
            openmodal[i].addEventListener('click', function (event) {
                event.preventDefault()
                this.toggleModal();
            })
        }

        const overlay = document.querySelector('.modal-overlay')
        overlay.addEventListener('click', this.toggleModal)

        var closemodal = document.querySelectorAll('.modal-close')
        for (let i = 0; i < closemodal.length; i++) {
            closemodal[i].addEventListener('click', this.toggleModal)
        }

        document.onkeydown = function (evt) {
            evt = evt || window.event
            var isEscape = false
            if ("key" in evt) {
                isEscape = (evt.key === "Escape" || evt.key === "Esc")
            } else {
                isEscape = (evt.keyCode === 27)
            }
            if (isEscape && document.body.classList.contains('modal-active')) {
                const body = document.querySelector('body')
                const modal = document.querySelector('.modal')
                modal.classList.toggle('opacity-0')
                modal.classList.toggle('pointer-events-none')
                body.classList.toggle('modal-active')
            }
        };
    }

    render() {
        const handleChange = this.props.handleChange;
        const handleSimpleSelect = this.props.handleSimpleSelect;
        const handleImages = this.props.handleImages;
        const deleteCurrentImage = this.props.deleteCurrentImage;
        const {close_areas, area_target, screen_type, description, images} = this.props.values;
        const areas = [
            { name: this.props.t('sections.wizard.stepFour.low'), value: 'low'},
            { name: this.props.t('sections.wizard.stepFour.middleLow'), value:'middleLow'},
            { name: this.props.t('sections.wizard.stepFour.middle'), value:'middle'},
            { name: this.props.t('sections.wizard.stepFour.middleHigh'), value: 'middleHigh'},
            { name: this.props.t('sections.wizard.stepFour.high'), value:'high'}
        ];
        const types = [
            {name: this.props.t('sections.wizard.stepFour.outdoor'), value: 'outdoor'}, 
            { name: this.props.t('sections.wizard.stepFour.indoor'), value: 'indoor'},
            { name: this.props.t('sections.wizard.stepFour.inMovement'), value: 'inMovement'}
        ];

        return (
            <div className="w-full p-5">
                <h1 className="text-gray-800 text-xl font-bold">{this.props.t('sections.wizard.stepFour.stepTitle')} <span className="font-light text-sm text-gray-700"> - {this.props.t('sections.wizard.stepFour.stepTitleExtra')}</span></h1>
                <hr/>

                <div className="my-2">
                    <label className="block text-gray-700 text-sm font-bold mb-2">{this.props.t('sections.wizard.stepThree.description')}</label>
                    <input type="text"
                        className="shadow appearance-none border rounded w-full mb-4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring"
                        onChange={(e) => handleChange('description', e)}
                        value={description}
                        placeholder={this.props.t('sections.wizard.stepThree.description')} />
                </div>

                <div className="my-2">
                    <label className="block text-gray-700 text-sm font-bold mb-2">{this.props.t('sections.wizard.stepFour.type')}</label>
                    <div className="flex">
                        <div className="relative flex-grow mr-5">
                            <select onChange={handleSimpleSelect('screen_type')} defaultValue="-1" value={screen_type} className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:focus:ring">
                                <option value="-1">{this.props.t('sections.wizard.stepFour.unspecified')}</option>
                                {types.map((type, index) => (
                                    <option key={index} value={type.value}>{type.name}</option>
                                ))}
                            </select>
                            <div className="pointer-events-none absolute vertical-center right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="my-2">
                    <label className="block text-gray-700 text-sm font-bold mb-2">{this.props.t('sections.wizard.stepFour.nearbyAreas')}</label>
                    <input type="text"
                        className="shadow appearance-none border rounded w-full mb-4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:focus:ring"
                        onChange={(e) => handleChange('close_areas', e)}
                        value={close_areas}
                        placeholder={this.props.t('sections.wizard.stepFour.nearbyAreas')} />
                </div>

                <div className="my-2">
                    <label className="block text-gray-700 text-sm font-bold mb-2">{this.props.t('sections.wizard.stepFour.targetArea')}</label>
                    <div className="flex">
                        <div className="relative flex-grow mr-5">
                            <select onChange={handleSimpleSelect('area_target')} defaultValue="-1" value={area_target} className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:focus:ring">
                                <option value="-1">{this.props.t('sections.wizard.stepFour.unspecified')}</option>
                                {areas.map((area, index) => (
                                    <option key={index} value={area.value}>{area.name}</option>
                                ))}
                            </select>
                            <div className="pointer-events-none absolute vertical-center right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="my-2">
                    <label className="block text-gray-700 text-sm font-bold mb-2">{this.props.t('sections.wizard.stepFive.addPhotos')}</label>
                    <button className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                        onClick={() => { this.toggleCarouselModal(); this.carouselEventAdd(); }}>
                        <span className="mr-2">{this.props.t('sections.wizard.stepFive.openCarousel')}</span>
                    </button>
                </div>

                <CarouselModal
                    toggleCarouselModal={this.toggleCarouselModal}
                    carouselImages={images}
                    deleteCurrentImage={deleteCurrentImage}
                    handleImages={handleImages}
                />

                {/* <div className="my-2">
                    <label className="block text-gray-700 text-sm font-bold mb-2">{this.props.t('sections.wizard.stepFour.defaultAd')}</label>
                    
                    <label className="flex justify-start items-start">
                        <div className="bg-white border-2 rounded border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500">
                            <input type="checkbox" className="opacity-0 absolute screenCheck" onChange={handleCheckboxBoolean}/>
                            <svg className="fill-current hidden w-4 h-4 text-green-500 pointer-events-none" viewBox="0 0 20 20"><path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/></svg>
                        </div>
                        <div className="select-none">{this.props.t('sections.wizard.stepFour.acceptAd')}</div>
                    </label>
                </div> */}

                <div className="mt-8">
                    <button className="buttonSecondary"
                            onClick={this.back}>{this.props.t('common.buttons.back')}</button>
                    <button className="buttonPrimary"
                            onClick={this.continue}>{this.props.t('common.buttons.next')}</button>
                </div>
            </div>
        );
    }
}

export default withTranslation()(StepFour);