import isEqual from 'lodash.isequal';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cloneDeep from 'lodash.clonedeep';

class EditRow extends Component {
    constructor(){
        super();
        this.state = {
            row: {}
        }
    }

    componentDidUpdate = (prevProps) => {
        if(!isEqual(prevProps.rowToEdit, this.props.rowToEdit)){
            this.setState({
                row: this.props.rowToEdit
            })
        }
    }

    closeModal = () => {
        document.getElementById(this.props.modalID).classList.add('hidden');
    }

    handleSendForm = () => {
        this.props.handleEditRow(this.state.row);
        this.closeModal();
    }

   /**
    * It takes a field name and an eventTarget, and then it sets the value of the field in the state to
    * the value of the eventTarget.
    * @param field - the name of the field in the row object
    * @param eventTarget - the input element that was changed
    */
    handleChangeInput = (field, eventTarget) => {
        let rowData = cloneDeep(this.state.row);
        if(field.includes('.')){
            rowData = this.setRecursiveValue(rowData, field, this.parseValue(eventTarget.type, eventTarget.value));
        }else{
            rowData[field] = this.parseValue(eventTarget.type, eventTarget.value);
        }
        this.setState({
            row: rowData
        })
    }

    /**
     * It takes a field name, an array of field names, an index, and a value, and returns the field
     * with the value set.
     * @param fieldToChange - The field you want to change.
     * @param fieldArr - This is the array of fields that you want to change.
     * @param index - the index of the field in the array
     * @param value - The value to be set
     * @returns The fieldToChange object is being returned.
     */
    returnRecursiveValue = (fieldToChange, fieldArr, index, value) => {
        if(fieldArr.length-1 === index){
            if(fieldToChange[0]){
                fieldToChange[0][fieldArr[index]] = value;
            }else{
                fieldToChange[fieldArr[index]] = value;
            }
            return fieldToChange;
        }else{
            if(fieldToChange[fieldArr[index]]){
                this.returnRecursiveValue(fieldToChange[fieldArr[index]], fieldArr, index+1, value);
            }else if(fieldToChange[0]){
                this.returnRecursiveValue(fieldToChange[0][fieldArr[index]], fieldArr, index+1, value);
            }
        }
        return fieldToChange;
    }
    
    /**
     * It takes an object, a field, and a value, and returns the object with the field set to the
     * value.
     * @param obj - The object you want to set the value of
     * @param field - The field you want to set the value of.
     * @param value - The value to set the field to
     * @returns The value of the field.
     */
    setRecursiveValue = (obj, field, value) => {
        let fieldArr = field.split('.');
        return this.returnRecursiveValue(obj, fieldArr, 0, value);
    }

    /**
     * It takes a value and a type, and returns the value as the type.
     * @param type - The type of the value.
     * @param value - The value to be parsed.
     * @returns The value of the input field.
     */
    parseValue = (type, value) => {
        switch (type) {
            case 'number':
                return Number(value);
            default:
                return value;
        }
    }

    render() {
        const { modalID, title } = this.props;
        /* A function that returns the value of a field. */
        const getValue = (field) => {
            if(!isEqual(this.state.row, {})){
                if(field.includes(".")){
                    let arrayOfFields = field.split('.');
                    let actualValue = this.state.row;
                    arrayOfFields.forEach(subField => {
                        if(actualValue[0]){
                            actualValue = actualValue[0][subField];
                        }else{
                            actualValue = actualValue[subField];
                        }
                    });
                    return actualValue;
                }
                return this.state.row[field];
            }
        }
        return (
            <div id={modalID} className="modal fixed z-40 w-screen h-screen top-0 left-0 flex items-center justify-center hidden">
                <div className="modalContent modal-sm bg-white rounded-md p-5 flex flex-col h-auto ">
                    <div className='flex'>
                        <span className="font-semibold relative left-1">{title}:</span>
                        <div className="button cursor-pointer ml-auto relative right-1" onClick={this.closeModal}><FontAwesomeIcon icon={["fal", "times"]} /></div>
                    </div>
                    <div className='flex flex-wrap'>
                        {this.props.rowFieldsDataType.map(field => {
                            return (
                                <div className={`flex flex-col p-1 w-${field.type === 'text' ? 'full' : '1/2'}`}>
                                    <label>{field.name}</label>
                                    {field.type === 'checkbox' ? 
                                        <input type={field.type} className={"bg-gray-200 hover:bg-gray-300 cursor-pointer focus:outline-none rounded-sm"} checked={getValue(field.id)} onChange={(e) => this.handleChangeInput(field.id, e.target.checked)} ></input>
                                    :
                                        <input type={field.type} className={"appearance-none text-gray-600 border border-gray-200  rounded mr-1  leading-tight focus:outline-none focus:bg-white"} value={getValue(field.id)} onChange={(e) => this.handleChangeInput(field.id, e.target)} ></input>
                                    }
                                </div>
                            )
                        })}
                    </div>
                    <div className='w-full flex flex-row-reverse my-1'>
                        <button className='buttonPrimary' onClick={() => this.handleSendForm()}>{this.props.t('common.buttons.submit')}</button>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation()(EditRow);