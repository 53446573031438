import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { uploadFiles } from './StorageFunctions';
import { addCard, getCard } from '../content/boardComponent/BoardFunctions'
import { components } from 'react-select';

import GroupedSelect from './GroupedSelect/GroupedSelect';
import CustomContentOption from './GroupedSelect/CustomComponents/CustomContentOption';
import ButtonSwitcher from '../buttons/ButtonSwitcher'

class BoardCardModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            card: {},
            cardFields: [
                "title",
                "description"
            ],
            cardBeingEdited: {},
            path: "",
            img: undefined,
            file: undefined,
            brands: [],
            customers: [],
            selectedContent: null,
            content: null,
            contentType: "DOOH"
        }
    }

    /**
     * Close modal and reset the state
     */
    closeModal = () => {
        document.getElementById("BoardCardModal").classList.add('hidden');
        this.setState({
            card: {},
            selectedContent: null,
            content: null
        });
    }

    /**
     * verify that two arrays have the same elements
     */
    array_equal = (arr1, arr2) => {
        if ((Array.isArray(arr1) && Array.isArray(arr2)) === false) return false;
        return JSON.stringify([...new Set(arr1.flat().sort())]) === JSON.stringify([...new Set(arr2.flat().sort())]);
    }

    /**
     * Check that the card has the valid attributes and upload the card
     */
    createCard = async () => {
        let { card } = this.state;
        if (this.array_equal(Object.keys(card), this.state.cardFields)) {
            let response = Object.values(card).filter(e => e === "")
            if (response.length === 0 && this.state.content) {
                this.uploadCard(card)
                this.closeModal()
            } else {
                this.props.showNotification({
                    type: 'warning',
                    text: this.props.t('common.notification.completeFields')
                })
            }
        } else {
            this.props.showNotification({
                type: 'warning',
                text: this.props.t('common.notification.completeFields')
            })
        }
    }

    /**
     * Upload the card to DB
     * @param {*} card The card that will be uploaded.
     */
    uploadCard = async (card) => {
        card.contentId = this.state.content._id;
        card.lane_id = this.props.actualLane;
        card.type = "card";
        card.contentType = this.state.contentType;
        try {
            let responseAdd = await addCard(card);
            if (responseAdd.status === 201) {
                let responseGetCard = await getCard(responseAdd.data.id)
                responseGetCard = responseGetCard.data
                responseGetCard.id = responseGetCard._id
                this.props.addNewCard(responseGetCard)
            }
        } catch (error) {
            console.log(error);
            this.props.showNotification({
                type: 'warning',
                text: this.props.t("common.notification.status.errorToCreateCard")
            })
        }
    }
    
    /**
     * change the value of the imput selected
     * @param {*} e the new value
     */
    changeInput = (e) => {
        let field = e.target.name;
        let value = e.target.value;
        let { card } = this.state;
        card[field] = value;
        this.setState({ card })
    }

    /**
     * Return values ​​from select in the correct format
     * @returns array of object for the select
     */
    selectContentOptions = () => this.props.contents?.filter(content => {
        if (this.state.contentType === "OOH") {
            if (content.isOOH) {
                return content;
            } else {
                return null;
            }
        } else {
            if (!content.isOOH) {
                return content;
            } else {
                return null;
            }
        }
    }).map(c => {
        return {
            label: c.name,
            value: c._id
        }
    })


    /**
     * Handles the changes of selected content and saves it in state
     * @param {Array} selected the option selected in the selecet
     */
    handleSelectContent = (selected) => {
        let content;
        if (selected === null) {
            selected = []
        } else {
            content = this.props.contents.find(content => content._id === selected.value)
        }
        this.setState({
            selectedContent: selected,
            content: content
        })
    }

    /**
     * Swicht the content between OOH and DOOH
     */
    handleContentType = () => {
        let value = this.state.contentType;
        if (value === "DOOH") {
            this.setState({ contentType: "OOH", content: null, selectedContent: null });
        } else {
            this.setState({ contentType: "DOOH", content: null, selectedContent: null });
        }
    }
    
    render() {

        const CustomOption = props => {
            let filteredOption = this.props.contents.filter(c => c._id === props.value);
            return (
                <components.Option {...props}>
                    <CustomContentOption content={filteredOption} props={props} />
                </components.Option>
            );
        };

        return (
            <div id={"BoardCardModal"} className="modal fixed w-screen h-screen top-0 left-0 flex items-center justify-center hidden">
                <div className={`modalContent   ${!this.state.step === 1 ? "sm:w-auto" : ""} w-auto md:w-4/6 lg:w-6/12 xl:w-5/12 min-w-96 bg-white rounded-md p-5 flex flex-col `}>
                    <div className="modal-header relative font-extralight text-lg flex justify-end px-3">
                        <div className="button cursor-pointer absolute" onClick={() => this.closeModal()}><FontAwesomeIcon icon={["fal", "times"]} /></div>
                    </div>
                    <div>
                        <div className="flex flex-col">
                            <p className="  uppercase tracking-wide text-gray-600 text-xs font-bold mb-2 mt-3">{this.props.t("sections.status.title")}:</p>
                            <input id="inputTitleCard" type="text" onChange={this.changeInput} name="title" value={this.state.card.title === undefined ? "" : this.state.card.title} className="appearance-none block w-full text-gray-600 border border-gray-200 rounded py-2 px-2  leading-tight focus:outline-none focus:bg-white"></input>
                        </div>
                        <div className="flex flex-col">
                            <p className="uppercase tracking-wide text-gray-600 text-xs font-bold mb-2 mt-3">{this.props.t('sections.wizard.stepThree.description')}:</p>
                            <input type="text" onChange={this.changeInput} name="description" value={this.state.card.description === undefined ? "" : this.state.card.description} className="appearance-none block w-full text-gray-600 border border-gray-200 rounded py-2 px-2  leading-tight focus:outline-none focus:bg-white"></input>
                        </div>
                        <div>
                            <div className="flex justify-between">
                                <label className="  uppercase tracking-wide text-gray-600 text-xs font-bold mb-2 mt-3">
                                    {this.props.t('sections.calendar.wizard.step2.content')}:
                                </label>
                                <p className="w-20 uppercase tracking-wide text-gray-600 text-xs font-bold mb-2 mt-3">{this.props.t('sections.status.cardType')}</p>
                            </div>
                            <div className='flex justify-between'>
                                <div className='w-full mr-4'>
                                    {this.state.contentType === "DOOH" ?
                                        <GroupedSelect
                                            getGroupedOptions={this.selectContentOptions}
                                            selected={this.state.selectedContent}
                                            customComponents={{ Option: CustomOption }}
                                            changeCallback={this.handleSelectContent}
                                            extraProps={{ closeMenuOnSelect: "false", maxMenuHeight: 200 }}
                                        ></GroupedSelect>
                                        :
                                        <GroupedSelect
                                            getGroupedOptions={this.selectContentOptions}
                                            selected={this.state.selectedContent}
                                            customComponents={{ Option: CustomOption }}
                                            changeCallback={this.handleSelectContent}
                                            extraProps={{ closeMenuOnSelect: "false", maxMenuHeight: 200 }}
                                        ></GroupedSelect>
                                    }
                                </div>
                                <div className="flex  w-20">
                                    <ButtonSwitcher className="my-2 " handleVisibility={() => this.handleContentType()} status={""} labelOn="OOH" labelOff="DOOH" revertLabel={true}></ButtonSwitcher>
                                </div>

                            </div>
                        </div>

                        <div className='flex justify-between'>
                            <div className="buttonSecondary cursor-pointer" onClick={() => this.closeModal()}>{this.props.t("common.buttons.cancel")}</div>
                            <div className='buttonPrimary cursor-pointer' onClick={() => this.createCard()}>{this.props.t("sections.status.createCard")}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation()(BoardCardModal);
