import Slider from 'rc-slider'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../modals.css';
import isEqual from 'lodash.isequal';
class BrightnessControlModal extends Component {
    constructor(){
        super();
        this.state = {
            start: 0,
            end: 24,
            opacity: 100,
        }
    }
    componentDidMount = () => {
        if(!isEqual(this.props.selectedOption, {})){
            let selected = this.props.selectedOption;
            if(selected){
                this.setState({
                    start: selected.start,
                    end: selected.end,
                    opacity: selected.opacity
                })
            }
        }
    }
    componentDidUpdate = (prevProps, prevState) => {
        if(!isEqual(prevProps, this.props)){
            let selected = this.props.selectedOption.option;
            if(selected){
                this.setState({
                    start: selected.start,
                    end: selected.end,
                    opacity: selected.opacity
                })
            }
        }
    }
    returnValue = () => {
        this.props.callbackFunction(this.state)
        this.closeModal()
    }
    /**
     * It sets the state of the component to the default values, and then adds the class 'hidden' to
     * the modal.
     */
    closeModal = () => {
        this.setState({
            start: 0,
            end: 24,
            opacity:100
        }, () => {
            document.getElementById(this.props.modalID).classList.add('hidden');
        })
    }
    /**
     * OnSliderChangeHour is a function that takes in a value and sets the state of the start and end
     * values to the value of the slider.
     * @param value - [0, 24]
     */
    onSliderChangeHour = (value) => {
        this.setState({
            start: value[0],
            end: value[1]
        })
    }
    /**
     * The function onSliderChangeOpacity takes in a value and sets the state of the opacity to the
     * value of the slider.
     * @param value - The current value of the slider.
     */
    onSliderChangeOpacity = (value) => {
        this.setState({
            opacity: value[0]
        })
    }
    render() {
        const filterCSS = { filter: `brightness(${this.state.opacity/100})`}
        let {start, end, opacity} = this.state;
        return (
            <div id={this.props.modalID} className="modal fixed z-40 w-screen h-screen top-0 left-0 flex items-center justify-center backdrop-filter backdrop-brightness-75 hidden">
                <div className="modalContent bg-white rounded-md p-5 flex flex-col h-auto w-1/3">
                    <div className="modal-header relative font-extralight text-lg flex justify-between px-3 mt-1">
                        <div className="title text-gray-600 text-2xl">{this.props.title}</div>
                        <div className="button cursor-pointer" onClick={this.closeModal}><FontAwesomeIcon icon={["fal", "times"]} /></div>
                    </div>
                    <div className="flex h-auto py-10 px-2 justify-around items-center bg-gray-50 rounded text-center text-gray-500 text-sm font-bold">
                        <div className='flex flex-col w-2/4'>
                            <div className='flex justify-between'>
                                <label>{this.props.t('sections.wizard.stepFive.brightnessStart')}: {start}</label>
                                <label>{this.props.t('sections.wizard.stepFive.brightnessEnd')}: {end}</label>
                            </div>
                            <Slider
                                range
                                value={[start, end]}
                                min={0}
                                max={24}
                                onChange={this.onSliderChangeHour}
                                allowCross={false}
                            />
                            <label className='self-start'>{this.props.t('sections.wizard.stepFive.brightness')}: {opacity}</label>
                            <Slider
                                range
                                value={opacity}
                                max={100}
                                onChange={this.onSliderChangeOpacity}
                            />
                        </div>
                        <div className='w-24 h-24 ml-3 flex items-center rounded-full bg-black'>
                            <img className='p-0 w-3/4 m-auto' src={require(`../../../images/${window.Config.REACT_APP_WL_NAME}/iso-blue.svg`)} style={filterCSS} alt={window.Config.REACT_APP_WL_NAME} />
                        </div>
                    </div>
                    <button className="buttonPrimary" onClick={this.returnValue}>{this.props.buttonText}</button>
                </div>
            </div>
        )
    }
}
export default withTranslation()(BrightnessControlModal);