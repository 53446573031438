import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';

const ModalLayout = ({ children, title, modalID, returnValue, deleteValue, closeModal, action, t}) => {
    
    return (
        <div id={modalID} className="modal fixed z-40 w-screen h-screen top-0 left-0 flex items-center justify-center hidden">
            <div className="modalContent modal-lg bg-white w-80 rounded-md p-5 flex flex-col h-auto">
                <div className="modal-header relative font-extralight text-lg flex justify-between px-3 mt-1">
                    <div className="title text-gray-600 text-2xl">{title}</div>
                    <div className="button cursor-pointer" onClick={() => closeModal(modalID)}><FontAwesomeIcon icon={["fal", "times"]} /></div>
                </div>
                <div className="modal-content mt-4 md:px-4">
                    {children}
                </div>
                <div className={`flex justify-${action === "edit" ? "between" : "end"}`}>
                    {action === "edit" && 
                        <button className={`buttonSecondary ${deleteValue ? '' : 'hidden'}`} onClick={() => {deleteValue()}}>{t('common.buttons.delete')}</button>
                    }
                    <button className="buttonPrimary" onClick={() => {returnValue()}}>{t('common.buttons.submit')}</button>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(ModalLayout);