import React from 'react';
// import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import {getTimeThatContentFitsInTimeLeftOnScreens} from '../content/Content.utils';
import {formatNumber} from '../../util_files/formatNumber'
import { Link } from 'react-router-dom';
import  DailyLimitBar  from '../utils/dailyLimitBar/DailyLimitBar';
import GridItemDetails from './GridItemDetails'
import GridItemDetailsCarrusel from './GridItemDetailsCarrusel'



const GridItem = (props) => {
    const {  selectedContentId, contentMenuIsVisible,contentMenuDetailThumbnail } = props;
    const item = props.item;
    const index = props.getIndexOfplaylist(item)
    const screen = props.screen;
    const imageExtensions = ['png', 'jpg', 'jpeg', 'gif'];
    const videoExtensions = ['mp4', 'ogg', 'webm'];
    const htmlExtensions = ['zip','html'];
    const countClonedContentWithID= props.countClonedContentWithID(item._id)

    return(
        <div >
            {/* head */}
            <div className="flex w-full justify-between px-1">
                <div className="w-5 h-5 flex justify-center align-middle pb-2 rounded-full bg-gray-300"><span className="text-gray-500 text-sm mb-2">{index}</span> </div>
                <span className="absolute ml-7 mt-1 font-bold italic text-xs text-gray-400">{item.original_content ? props.t('sections.screens.screenPlaylist.cloned') : ""}</span>
                <div className="flex">
                    <div data-type="content" data-target={item._id} data-screen={screen._id} className=" cursor-pointer text-center"><FontAwesomeIcon icon={["fal", "cog"]} className="text-blue-600 text-sm" /></div>
                    <div id={"content" + item._id}>
                        {props.renderPlaylistContentSwitch(index, item)}
                    </div>
                </div>
            </div>
            {/* options */}
            <div className=" h-40 mx-auto overflow-hidden rounded">
                <div className={`absolute z-20 ml-20  p-2 px-6 text-xs bg-white rounded-md shadow divide-y divide-blue-200 ${selectedContentId === item._id && contentMenuIsVisible ? "z-10" : " hidden"}`}>
                    {item.original_content
                        ? <div onClick={() => props.openModal(`deleteRepeatedContent${screen._id}`)} className="select-none text-left py-2 text-red-600 hover:text-black">{props.t('common.buttons.delete')}</div>
                        : <div onClick={() => props.openModal(`repeatContent${screen._id}`)} className="select-none text-left py-2 text-gray-600 hover:text-black">{props.t('sections.screens.screenPlaylist.repeatContent')}</div>
                    }
                    {!item.original_content &&
                        <div onClick={() => props.openModal(`deLinkContent${screen._id}`)} className="select-none text-left py-2 text-gray-600 hover:text-black">{props.t('sections.screens.screenPlaylist.deLinkContent')}</div>
                    }
                    <div onClick={() => props.openModal(`unLinkAllContent${screen._id}`)} className="select-none text-left py-2 text-gray-600 hover:text-black">{props.t('sections.screens.screenPlaylist.deLinkAllButThis')}</div>
                    {props.contentHasBeenRepeated(item._id) &&
                        <div onClick={() => props.openModal(`subtractRepeatedContent${screen._id}`)} className="select-none text-left py-2 text-red-600 hover:text-black">{props.t('sections.screens.screenPlaylist.subtractRepeatedContent')}</div>
                    }
                </div>
                    {item.daily_limit  &&
                        <>
                            <ReactTooltip type="info" className="p-2" id={"DailyLimit"+item._id} aria-haspopup='true'>
                                <p className="text-center max-w-sm">
                                {props.t('sections.screens.screenPlaylist.dailyLimit')}
                                </p>
                            </ReactTooltip>
                            <div data-tip data-for={"DailyLimit"+item._id} className='absolute flex justify-center  w-5 h-5 bg-white rounded-full ml-2 mt-3 cursor-pointer '>
                                <FontAwesomeIcon  icon={["fal", "tasks-alt"]} className="relative text-xs top-1 opacity-100"/>
                            </div>
                            <ReactTooltip type="error"   className="p-2" id={"DailyLimitExceeded"+item._id} aria-haspopup='true'>
                                <p className="text-center max-w-sm">
                                {props.t('sections.screens.screenPlaylist.dailyLimitExceeded')}
                                </p>
                            </ReactTooltip>
                            {Number(item.daily_limit) === item.impressions_in_day &&
                                <div data-tip data-for={"DailyLimitExceeded"+item._id} className='absolute flex justify-center w-5 h-5 bg-white rounded-full ml-44 mt-3 cursor-pointer '>
                                    <FontAwesomeIcon  icon={["fa", "pause"]} className="relative text-xs top-1 text-red-500"/>
                                </div>
                            }
                            {item.daily_limit > 0 &&
                                <DailyLimitBar item={item} width={"w-44"} ></DailyLimitBar>
                            }
                        </>
                    }
                    <div  id={`detailThumbnail${item._id}`} className={`absolute z-20 w-26 ml-2  mt-3 h-30  justify-center p-2 px-6 text-xs bg-white rounded-md shadow divide-y  divide-blue-200 ${selectedContentId === `detailThumbnail${item._id}` && contentMenuDetailThumbnail  && !item.original_content ? "" : "hidden"}`}>
                        {!item.original_content &&
                            <>
                            {!item.isCreativeCarrousel &&
                            <div onClick={()=>{props.handleModalOfContent("DetailContentModal",item)}} className="py-2 w-4/5 px-3 mb-2  text-left cursor-pointer">{props.t('sections.screens.screenPlaylist.details')}</div>
                            }
                            <Link  className="py-2 w-4/5 px-3 cursor-pointer " to={{
                                        pathname: '/content',
                                        state: {
                                            data: {
                                                contentBeingEdited:item._id,
                                            }
                                        }
                                    }}>
                                <button className="">{props.t("common.buttons.edit")}</button>
                            </Link>
                            </>
                        }

                    </div>
                    <div className="bg-white ">
                        <div onContextMenu={(e)=>props.openOptionsOfDetailContent(e,item)} className="bg-white w-full h-full">
                            <div className={`${item.original_content&& "opacity-50 "}`}>
                                {imageExtensions.includes(item.ext) &&
                                    <><img data-tip data-for={"tooltip"+item._id} className="object-cover " draggable='false' src={`${window.Config.REACT_APP_FILESERVER_URL}/${item.path}`} alt={item.name}/></>
                                }
                                {videoExtensions.includes(item.ext) &&
                                    <><video data-tip data-for={"tooltip"+item._id} draggable='false' src={`${window.Config.REACT_APP_FILESERVER_URL}/${item.path}`} alt={item.name}></video></>
                                }
                                {htmlExtensions.includes(item.ext)&&
                                    <><img data-tip data-for={"tooltip"+item._id} draggable='false' src={require('../../images/common/html.png')} alt={item.name}></img></>
                                }
                            </div>
                        </div>
                    </div>
            </div>
            {/* foothead */}
            <div className="flex">
                <div className="w-2/3  font-bold">
                    <p className="px-1 text-sm truncate">{item.name}</p>
                </div>
                <div className="w-1/2 flex justify-end" >
                    {item.isCreativeCarrousel &&
                        <div onClick={() => props.handleModalOfContent("CarrouselCreativeModal",item)} className="items-center cursor-pointer flex  mr-3 font-bold hover:text-black"><FontAwesomeIcon icon={["fal", "sliders-h"]} /></div>
                    }
                    {
                    countClonedContentWithID>0 &&
                        <div className="flex items-center mr-1">
                            <FontAwesomeIcon onClick={()=> {props.handleContentCloned(item._id)}} icon={["fas", item.hideCloned ? "eye-slash":"eye"]} className="text-blue-400 ml-1 mr-1 cursor-pointer"  />
                            <label className=''>{countClonedContentWithID}x</label>

                        </div>
                    }
                </div>
            </div>
            {/* detailItems */}
            <div className={`text-xs `}>
                    {!item.isCreativeCarrousel ?
                        <GridItemDetails item={item} screen={screen}></GridItemDetails>
                        :
                        <GridItemDetailsCarrusel item={item}></GridItemDetailsCarrusel>
                    }
                    <div>
                        {item.daily_limit &&
                                <>
                                    <div>
                                        <div className="flex">
                                            <span className="playlist-item-duration font-extralight text-gray-600 mr-3"><FontAwesomeIcon icon={["fas", "tasks-alt"]} fixedWidth className="text-blue-300 mr-1 ml-1 text-sm" />
                                            {item.daily_limit && getTimeThatContentFitsInTimeLeftOnScreens(item,{items: [item],screens: [props.screen]})[props.screen._id] < item.daily_limit &&
                                                <>
                                                <span className="cursor-pointer mr-1" data-tip data-for={`dailyLimitExceeded${item._id}`}><FontAwesomeIcon icon={["fas", "exclamation-triangle"]} className="text-yellow-400 text-sm" /></span>
                                                    <ReactTooltip effect="solid" className="p-2" id={`dailyLimitExceeded${item._id}`} aria-haspopup='true'>
                                                        <p className="text-center max-w-sm overflow-ellipsis">
                                                            {props.t('sections.screens.screenPlaylist.dailyImpressionsExceededTooltip').replace('_impressions_',getTimeThatContentFitsInTimeLeftOnScreens(item,{items: [item],screens: [props.screen]})[props.screen._id])}
                                                        </p>
                                                    </ReactTooltip>
                                                    <span className="playlist-item-duration font-extralight text-gray-600 ml-2 mr-1">{props.t('sections.screens.screenPlaylist.limit')}:</span>
                                                </>
                                            }
                                                <span className="bold">{formatNumber(item.impressions_in_day, "currency", null, {decimals:0})}/{formatNumber(item.daily_limit, "currency", null, {decimals:0})} {props.t('sections.screens.screenPlaylist.dailyImpressions')}</span>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            }
                    </div>
                    <div >
                        <span className="font-light text-xs text-gray-600 ">
                            <FontAwesomeIcon icon={item.downloaded.includes(screen._id) ? ["fad", "check-double"] : ["fad", "hourglass-half"]} fixedWidth className="text-blue-300 ml-1 mr-1.5"/>
                            {item.downloaded.includes(screen._id) ? props.t('sections.screens.screenPlaylist.downloaded') : props.t('sections.screens.screenPlaylist.toSync')}
                         </span>
                    </div>
            </div>
        </div>
    )
}
export  {GridItem}
